@use "sass:math";
.row {
    display: flex;
    flex-wrap: wrap;
}

$columns: 12;

@for $i from 1 through $columns {
    .col-#{$i} {
        flex: 0 0 #{math.div(100%, $columns) * $i};
    }
    .col-offset-#{$i} {
        margin-left: #{math.div(100%, $columns) * $i};
    }
}

$space-gap-1: 4px;
$space-gap-2: 6px;
$space-gap-3: 8px;
$space-gap-4: 12px;
$space-gap-5: 16px;
$space-gap-6: 20px;
$space-gap-7: 24px;
$space-gap-8: 32px;

$brakepoints: (
    xs: 768px,
    sm: 1024px,
    md: 1280px,
    lg: 1440px
);
