@import '../variables';
@mixin horizontal-rate-scale {
    .mat-mdc-radio-button {
        padding: 0 8px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label {
        align-items: center;
        flex-direction: column;
        text-align: center;
        height: 100%;
        justify-content: space-between;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label-content{
        padding: 0;
    }
    .has-divider{
        .mat-mdc-radio-button:not(.other-button){
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #c8c8c8;
                @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    display: none;
                }
            }
            &:first-child:before {
                left: 50%
            }
            &:last-child:before {
                right: 50%
            }
        }

        &.with-other-button .mat-mdc-radio-button:nth-last-child(2):before{
            right: 50%
        }
    }
    .cb-scale-rating-text{
        display: flex;
        width: 100%;
        justify-content: space-between;
        text-align: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-button-group{
        display: -ms-grid;
        display: grid;
        align-items: normal;
        margin-left: -8px;
        margin-right: -8px;
    }
    &.rating-scale-matrix{
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-button-group{
            justify-content: space-between;
            margin: 0;
            padding-top: 5px;
            padding-bottom: 5px;

            .mat-mdc-radio-button {
                margin-bottom: 0;
            }
        }
    }
}
@mixin vertical-rate-scale {
    .cb-question-item-content{
        display: flex;
    }
    .cb-scale-rating-text{
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        padding: 2px 8px 9px 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label-content{
        display: flex;
    }
    .points {
        display: inline-block;
        position: relative;
        &:not(.onlyPoints){
            &:before{
                content: '(';
                padding-left: 5px;
            }
            &:after{
                content: ')';
            }
        }

    }
    .with-other-button{
        .last-text-item {
            padding-bottom: 34px;
        }
    }
    .has-divider{
        .mat-mdc-radio-button {
            position: relative;
            margin: 0;
            padding-bottom: 10px;
        }
        .mat-mdc-radio-button:not(.other-button){

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 9px;
                bottom: 0;
                width: 2px;
                background-color: #c8c8c8;
                @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    display: none;
                }
            }
            &:first-child:before {
                top: 50%
            }
            &:last-child:before {
                bottom: 50%
            }
        }

        &.with-other-button .mat-mdc-radio-button:nth-last-child(2):before{
            bottom: 50%
        }
    }
    &.rating-scale-matrix{
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-label{
            justify-content: left;
        }
    }
}

.cb-rating-scale-radio-container {

    .cb-question-item-caption-position--top.cb-question-item-position--left.cb-item-layout--horizontal:not(.rating-scale-matrix){
        max-width: 100%;
        align-items: flex-start;

        .cb-rating-scale-question-header {
            width: 100%;
        }
    }

    .cb-rating-scale-question-header {
        max-width: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-outer-circle{
        background-color: #fff;
    }
    .cb-item-layout--horizontal {
        @include horizontal-rate-scale;
    }
    .cb-item-layout--vertical {
        @include vertical-rate-scale ;
    }
}
