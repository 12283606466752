@import '../variables';

$scoreRadioWidth: 40px;
$scoreRadioHeight: 40px;

@mixin cb-score-inline-content {
    display: inline-flex;
    position: relative;
    padding-bottom: 20px;
}

@mixin vertical-score-content {
    padding: 25px 0;
    .cb-score-likely-text {
        &.cb-score-least-likely-text {
            left: 0;
            right: auto;
            top: 0;
            bottom: auto;
        }
        &.cb-score-most-likely-text {
            left: 0;
            right: auto;
            bottom: 0;
            top: auto;
        }
    }
}

@mixin vertical-items-group {
    /*for radio buttons and checkbox groups*/
    display: block;

    .mat-mdc-radio-button.square-radio-button {
        margin-bottom: 0 !important;
        padding-bottom: 15px !important;
        padding-right: 0;
        &:last-child {
            padding-bottom: 0;
        }
    }
}

@mixin horizontal-items-group {
    /*for radio buttons and checkbox groups*/
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    @media #{$tablet-down} {
        pointer-events: none;
    }
}

.cb-promoter-score-rating {
    padding-top: 5px;
    .cb-score-inline-content {
        @include cb-score-inline-content;
        @media #{$mobile} {
            @include vertical-score-content;
        }
        &.vertical-score-content {
            @include vertical-score-content;
        }
    }

    .cb-score-likely-text {
        position: absolute;
        color: #758ea1;
        font-size: 14px;
        line-height: 1.14;
        white-space: nowrap;
        top: 0;
        &.cb-score-least-likely-text {
            left: 0;
            right: auto;
        }
        &.cb-score-most-likely-text {
            right: 0;
            left: auto;
            padding-right: 20px;
        }
    }
}

.square-radio-button {
    color: red !important;
}

.score-radio-group.mat-mdc-radio-group {
    padding-top: 20px;
    @include horizontal-items-group;
    @media #{$mobile} {
        @include vertical-items-group;
    }
    &.vertical-items-group {
        @include vertical-items-group;
    }

    .mat-mdc-radio-button.square-radio-button {
        padding-right: 20px;
        display: block;
        margin-bottom: 10px;
        pointer-events: auto;
        @media #{$mobile} {
            padding-right: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        &.hidden {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            display: none;
            pointer-events: none;
        }

        &:hover {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-inner-circle {
                opacity: 1;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
                color: #fff;
            }

            & ~ .mat-mdc-radio-button {
                /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                .mat-radio-inner-circle {
                    opacity: 0;
                }
                /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                .mat-radio-label-content {
                    color: #758ea1;
                }
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-checked {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle {
                border-color: #2567c2;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-inner-circle {
                opacity: 1;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-container {
            width: $scoreRadioWidth;
            height: $scoreRadioHeight;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-inner-circle {
            transform: scale(1);
            opacity: 1;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-outer-circle,
        .mat-radio-inner-circle {
            border-radius: 4px;
            width: $scoreRadioWidth;
            height: $scoreRadioHeight;
        }
    }

    &:not(:hover) {
        .mat-mdc-radio-button.square-radio-button {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-radio-checked {
                & ~ .mat-mdc-radio-button {
                    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                    .mat-radio-inner-circle {
                        opacity: 0;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                    .mat-radio-label-content {
                        color: #758ea1;
                    }
                }
            }
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
        position: relative;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label-content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        width: $scoreRadioWidth;
        height: $scoreRadioHeight;
        line-height: $scoreRadioHeight;
        font-size: 18px;
        color: #fff;
        text-align: center;
    }
}


    .mat-mdc-button-ripple,
    .mdc-button__ripple {
        display: none !important;
    }
