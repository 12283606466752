@import '../variables';

.rich-editor-container {
    &:focus {
        outline: none!important;
    }
}

.cb-rich-editor {
    max-width: 100%;
    position: relative;
    display: block;
    flex: 1;

    .placeholder {
        color: #576a78;
    }

    .ql-toolbar {
        margin-bottom: 10px;
        border-radius: 4px;
        border: 0;
        background-color: #f1f4f7;
        box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17),
        0 0 2px 0 rgba(0, 8, 20, 0.1);
        color: rgba(0, 0, 0, 0.87);

        opacity: 1;
        pointer-events: auto;

        .ql-formats {
            margin: 5px 5px 5px 0;
            padding-right: 5px;
            border-right: 2px solid rgba(117, 142, 161, 0.35);
        }

        svg {
            color: #758ea1;
        }
    }

    .ql-snow .ql-stroke {
        stroke: currentColor;
    }

    .ql-snow .ql-fill {
        fill: currentColor;
    }

    .ql-container {
        border: 0 !important;
        padding: 0;
        flex: 1;
        height: auto;
        overflow-y: auto;

        .ql-editor {
            position: relative;
            font-size: 16px;
            padding: 0 0 4px;
            resize: none;
            width: 100%;
            min-height: 28px;
            border-bottom: 2px solid #758ea1;

            &.ql-blank::before {
                color: #576a78;
                font-style: normal;
            }
        }
    }
}

.reach-editor-section-overlay {
    display: none;
    pointer-events: none;
}

.form-editors-group {
    display: flex;
    margin-bottom: 30px;
    padding-top: 100px;

    .editable-section {
        flex: 1;
        display: flex;
    }

    &.toggle-editor-toolbar {
        padding-top: 0;

        .cb-rich-editor {
            .ql-toolbar {
                opacity: 0;
                pointer-events: none;

                position: absolute;
                bottom: 100%;
                left: 0;
                right: -40px;
            }
        }

        &.active {
            .reach-editor-section-overlay {
                display: block;
                pointer-events: auto;
                position: fixed;
                z-index: 1000;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;

                &:after {
                    display: none !important;
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 1px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: none;
                    box-shadow: none;
                }

                & ~ * {
                    z-index: 1000;
                    position: relative;
                }
            }

            .cb-rich-editor {
                background: #fff;

                .ql-toolbar {
                    opacity: 1;
                    pointer-events: auto;
                    width: 1290px;
                    max-width: 100%;
                    // max-width: calc(100vw - 180px);  /*temporary */
                    z-index: 10;
                }

                .ql-container {
                    .ql-editor {
                        border-color: #2567c2;
                    }
                }
            }
        }
    }

    cb-merge-text-list {
        display: inline-block;
        margin-left: 10px;
        height: 28px;
    }
}

cb-multilines-item-preview {
    .cb-rich-editor {
        .ql-editor {
            background-color: #ffffff;
            z-index: 100;
        }
    }

    textarea {
        max-width: 90%;
    }
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-right {
    text-align: right;
}

.ql-font-arial,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
    font-family: 'Arial';
}

.ql-font-courier,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before {
    font-family: 'Courier';
}

.ql-font-tahoma,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before {
    font-family: 'Tahoma';
}

.ql-font-times-new-roman,
.ql-snow
.ql-picker.ql-font
.ql-picker-item[data-value='times-new-roman']::before {
    font-family: 'Times New Roman';
}

.ql-font-trebuchet-MS,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet-MS']::before {
    font-family: 'Trebuchet MS';
}

.ql-font-verdana,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
    font-family: 'Verdana';
}

.ql-font-comic-sans-MS,
.ql-snow
.ql-picker.ql-font
.ql-picker-item[data-value='comic-sans-MS']::before {
    font-family: 'Comic Sans MS';
}

.ql-font-calibri,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='calibri']::before {
    font-family: 'Calibri';
}

.ql-font-lato,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lato']::before {
    font-family: 'Lato';
}

.ql-font-merriweather-sans,
.ql-snow
.ql-picker.ql-font
.ql-picker-item[data-value='merriweather-sans']::before {
    font-family: 'Merriweather Sans';
}

.ql-font-helveticaNeue,
.ql-snow
.ql-picker.ql-font
.ql-picker-item[data-value='helveticaNeue']::before {
    font-family: 'Helvetica Neue';
}

.ql-font-rubik,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='rubik']::before {
    font-family: 'Rubik';
}

.ql-font-dosis,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='dosis']::before {
    font-family: 'Dosis';
}

.ql-font-raleway,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='raleway']::before {
    font-family: 'Raleway';
}

.ql-snow .ql-picker.ql-font {
    width: 150px;
}

.ql-snow .ql-picker-options {
    max-height: 390px;
    overflow: auto;
}
