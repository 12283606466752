@import '../variables';
@import '../partials/max-diff-preview';
@import '../partials/form';
@import '../partials/rank-order-preview';
@import '../partials/rating-item';
@import '../partials/rating-scale-preview';
@import '../partials/slider-preview';
@import '../partials/matrix-preview';
@import '../partials/contact-form-preview';
@import '../partials/score-radio-group';
@import '../partials/image-choice-preview';

@mixin vertical-items-group {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    align-items: flex-start;
    .control-item,
    .square-radio-button {
        display: block;
        margin-bottom: 10px;
    }
}

@mixin horizontal-items-group {
    display: flex;
    align-items: center;
    margin: 0 -6px;
    .control-item {
        display: inline-block;
        margin: 0 6px;
    }
}

.cb-take-survey-section {
    position: relative;
    padding: 0 50px 50px;
    color: #212121;
    font-size: 16px;
    line-height: 1.25;

    @media #{$mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }

    .cb-survey-header {
        min-height: 264px;
        margin: 0 -50px -152px;
        padding-left: 45px;
        padding-right: 45px;
        box-sizing: border-box;
        background-color: $surveyHeaderBgColor;
        color: #fff;

        @media #{$mobile} {
            margin-left: -20px;
            margin-right: -20px;
        }

        & > * {
            max-width: 1096px;
            margin: 0 auto;
        }

        &.survey-header-above {
            margin-bottom: 50px;
            padding-bottom: 40px;
        }

        .cb-take-survey-main-title {
            font-size: 1.5em; // 24px , 16px=1em
            line-height: 1.33;
            color: rgba(255, 255, 255, 0.87);
        }
    }

    &.cb-style-template-survey {
        min-height: 100vh;
        background-color: $surveyBoardBgColor;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
        0 0 2px 0 rgba(0, 8, 20, 0.12);

        .cb-survey-content {
            padding-top: 69px;

            .survey-question-title {
                margin-bottom: 0;
            }

            .question-description {
                font-size: 1em; // 16px , 16px=1em
                margin-bottom: 6px;
            }

            .question-answer {
                font-size: 0.875em; // 14px , 16px=1em
                p {
                    margin: 0 0 7px;
                }
            }

            .cb-take-survey-divider {
                margin: 35px -36px 36px;
            }
        }

        table.cb-matrix-table .question-cell {
            width: 222px;
        }
    }

    .cb-take-survey-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        max-width: 1096px;
        min-height: 280px;
        z-index: 5;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17),
        0 0 2px 0 rgba(0, 8, 20, 0.1);

        .cb-take-survey-title {
            font-size: 16px;
            margin: 1em 12px 0;
        }

        img {
            max-width: 100%;
        }

    }
    .take-survey-error {
        width: 100%;
        padding: 20px;
        text-align: center;
        font-size: 20px;
        color: red;
        background: #fff;
    }

    .cb-survey-content {
        padding: 44px 48px;

        @media #{$mobile} {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .cb-checkboxes-container {
        @media #{$mobile} {
            column-count: unset !important;
        }
    }

    .cb-take-survey-title,
    .survey-question-title {
        margin: 0 0 20px;
        font-size: 1.25em; // 20px , 16px=1em
        font-weight: bold;
        line-height: 1.2;
        color: inherit;
        overflow: hidden;
    }

    .vertical-items-group {
        @include vertical-items-group;
    }

    .horizontal-items-group {
        @include horizontal-items-group;
        @media #{$mobile} {
            @include vertical-items-group;
        }
    }

    .column-items-group {
        column-width: auto;
        column-count: 2;
        display: block;

        .control-item {
            display: flex;
            margin-bottom: 5px;
        }

        @media #{$mobile} {
            column-count: unset;
            column-width: unset;
        }
    }

    .cb-checkbox-inline-group {
        display: flex;
        align-items: center;

        .hidden-form-field {
            display: none;
            margin-left: 10px;
            height: 24px;
            align-items: center;
            overflow: hidden;
        }

        .mat-mdc-checkbox {
            margin: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked + .hidden-form-field {
                display: flex;
            }
        }
    }

    .cb-rich-editor {
        max-width: 100%;

        .ql-container .ql-editor {
            max-width: 100%;
        }

        .ql-toolbar {
            @media #{$mobile} {
                display: none;
            }
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
        margin: 1px 10px 1px 2px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        align-items: center;
    }

    .error-msg {
        margin: -10px 0 20px;
        color: #f44336;
        font-size: 12px;
    }

    .mat-mdc-radio-button,
    .mat-mdc-checkbox {
        margin-bottom: 10px;
        display: inline-block;
        @media #{$mobile} {
            display: block;
            margin-bottom: 20px;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-mdc-radio-button.cdk-focused .mat-radio-outer-circle,
    .mat-radio-button .mat-radio-outer-circle:hover,
    .mat-mdc-checkbox.cdk-focused .mat-checkbox-frame,
    .mat-checkbox-inner-container:hover .mat-checkbox-frame{
        box-shadow: 0 0 7px #4a4a4a;
    }

    .mat-mdc-button.cdk-focused,
    .mat-mdc-icon-button.cdk-focused {
        box-shadow: 0px 0px 3px orange;
    }

    .table-matrix-group {
        .mat-mdc-radio-button,
        .checkbox-container .mat-mdc-checkbox {
            margin-bottom: 0;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label,
    .mat-checkbox-layout {
        white-space: normal;
        align-items: flex-start;
    }

    .form-fields-group {
        display: block;
        margin: 0;

        .mat-mdc-form-field {
            display: block;
            margin: 0;
        }
    }
}

.cb-responses-summary {
    .cb-responses-summary-header {
        display: flex;
        align-items: center;
        margin: 0 0 15px;
    }

    .cb-responses-summary-title {
        flex: 1;
        font-size: 20px;
        margin: 0;
        font-weight: normal;
    }

    .response-summary-section {
        .response-list {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -10px;

            .response-summary-item {
                flex: 0 0 33.333333%;
                padding: 0 10px;
                margin-bottom: 15px;
                box-sizing: border-box;
                @media #{$desktop-down} {
                    flex: 0 0 50%;
                }
                @media #{$mobile} {
                    flex: 0 0 100%;
                }
            }

            .response-item-label {
                color: #758ea1;
                font-size: 12px;
                margin-bottom: 5px;
            }
        }

        .response-summary-title {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 15px;
        }
    }
}

.cb-take-survey-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0 15px;
    font-size: 16px;

    &.center {
        justify-content: center;
        padding: 0;
        background: #fff;
        height: 50px;
        margin-bottom: 20px;
    }

    @media #{$mobile} {
        padding-left: 19px;
        padding-right: 19px;
    }

    @media #{$mobile-xs} {
        height: auto;
        padding: 19px;
        flex-direction: column;
    }

    height: 128px;
    border-radius: 2px;
    background-color: $surveyFooterBgColor;

    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        line-height: 1.14;
        letter-spacing: 0.4px;
        font-size: 0.875em; // 14px , 16px=1em
        margin: 0 6px;
        height: 48px;
        font-weight: bold;
        @media #{$mobile-xs} {
            margin: 6px;
            width: 100%;
        }
    }

    .btn-prev {
        min-width: 121px;

        &,
        &[disabled] {
            color: #758ea1 !important;
        }

        &[disabled] {
            opacity: 0.85;
            cursor: default;
        }
    }

    .btn-next {
        min-width: 131px;

        &,
        &[disabled] {
            color: #fff !important;
            background: $surveyButtonsBgColor !important;
        }

        &[disabled] {
            opacity: 0.85;
            cursor: default;
        }
    }
}

.cb-take-survey-divider {
    margin: 40px -20px 36px;
    height: 2px;
    //background-color: rgba(117, 142, 161, 0.35);

    @media #{$mobile} {
        padding-left: -10px;
        padding-right: -10px;
    }
}

.cb-take-survey-progress {
    position: relative;
    top: 12px;
    margin: 0 12px;

    .progress-bar-text {
        padding-bottom: 12px;
        margin-top: 3px;
        line-height: 1.33;
        font-size: 0.75em; // 12x , 16px=1em
        color: #576a78;
    }
}

.cb-survey-progress-bar {
    display: block;
    height: 6px;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 0;

    &--fake {
        background-color: #ffffff;
        border: solid 1px $surveyButtonsBgColor;
    }

    .progress-bg {
        display: block;
        height: 100%;
        background-color: $surveyButtonsBgColor;
    }
}

.cb-question-item-wrapper-inner {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    page-break-inside: avoid;
    page-break-before: auto;

    .matrix-group-cell & {
        width: 100%;
    }

    &.cb-question-item-caption-position {
        &--top {
            flex-direction: column;

            &.cb-question-item-position {
                &--right {
                    align-items: flex-end;
                }
            }
        }

        &--bottom {
            flex-direction: column-reverse;
        }

        &--top,
        &--bottom {
            &.cb-question-item-position {
                &--right {
                    align-items: flex-end;
                }

                &--center {
                    align-items: center;
                }
            }
        }

        &--left {
            flex-direction: row;
            align-items: baseline;

            &.cb-question-item-position {
                &--right {
                    justify-content: flex-end;
                }
            }
        }

        &--right {
            flex-direction: row-reverse;
            align-items: baseline;

            &.cb-question-item-position {
                &--left {
                    justify-content: flex-end;
                }
            }
        }
    }

    &.cb-question-item-position {
        &--right {
            margin-left: auto;
        }

        &--left {
            margin-right: auto;
        }

        &--center {
            justify-content: center;
        }
    }
}

.cb-question-item-content,
.cb-question-item-caption {
    margin: 0 0.5em;
    max-width: 100%;
    &:focus {
        outline: none!important;
    }
}

.cb-item-layout--horizontal {
    .cb-question-item-content {
        &--max-width {
            max-width: 100%;
            overflow: auto;
        }
    }
}

.cb-question-item-caption {
    font-weight: bold;

    .cb-question-item-title p {
        margin: 0 0 10px;
    }

    .cb-question-item-subtitle p {
        font-weight: normal;
        margin: 0 0 10px;
    }
}

.datepicker-form {
    mat-form-field {
        width: fit-content;
    }
}

.cb-item-layout--vertical {

    .cb-promoter-score-rating {
        min-width: 120px;

        .cb-score-inline-content {
            @include vertical-score-content;
        }
    }

    .score-radio-group.mat-mdc-radio-group {
        @include vertical-items-group;
    }

    .cb-image-choice-container .image-choices-group {
        flex-direction: column;
        align-items: flex-start;
    }
}

.cb-save-survey__link {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
}
