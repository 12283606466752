@use '@angular/material' as mat;
@import '../../shared/sass/variables';
@import './typography';
/*
//Midnight variables
$surveyHeaderBgColor: #751ca4;
$surveyBoardBgColor: #212121;
$surveyFooterBgColor: #f1f4f7;
$surveyButtonsBgColor: #751ca4;
*/

//Asphalt variables
/*$surveyHeaderBgColor: #576a78;
$surveyBoardBgColor: #ffffff;
$surveyFooterBgColor: rgba(87, 106, 120, 0.15);
$surveyButtonsBgColor: #576a78;*/

//Forest variables
/*$surveyHeaderBgColor: #4baf50;
$surveyBoardBgColor: #5a5a5a;
$surveyFooterBgColor: rgba(166, 166, 166, 0.3);
$surveyButtonsBgColor: #4baf50;*/

/*
//Chocolate milk variables
$surveyHeaderBgColor: #846052;
$surveyBoardBgColor: #f1f4f7;
$surveyFooterBgColor: #212121;
$surveyButtonsBgColor: #846052;
*/

/*
//Soft variables
$surveyHeaderBgColor: #bbe8fc;
$surveyBoardBgColor: #fafcfc;
$surveyFooterBgColor: rgba(187, 232, 252, 0.15);
$surveyButtonsBgColor: #f9c3d6;*/

/*

//Autumn variables
$surveyHeaderBgColor: #ffa302;
$surveyBoardBgColor: #fafcfc;
$surveyFooterBgColor: rgba(255, 221, 162, 0.3);
$surveyButtonsBgColor: #583b34;
*/

$brand-primary: mat.m2-define-palette($brand-primary-palette);
$brand-accent: mat.m2-define-palette($brand-secondary-palette);
$brand-warn: mat.m2-define-palette($system-danger-palette);

$brand-theme: mat.m2-define-light-theme((
  color: (
    primary: $brand-primary,
    accent: $brand-accent,
    warn: $brand-warn
  ),
    typography: $checkbox-typography-config
));