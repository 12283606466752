.cb-html-editor-dialog {
    .mat-mdc-dialog-actions {
        justify-content: flex-end;
    }
}
.cb-css-editor-dialog {
    .css-editor {
        height: 454px;
    }
    .mat-mdc-dialog-actions {
        justify-content: flex-end;
    }
}
