.contact-form-content {
    max-width: 100%;
    overflow: auto;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
    width: 100%;
}

table.cb-contact-form-table {
    font-size: 14px;
    line-height: 1.14;
    width: 100%;
    border-spacing: 0;

    &:focus {
        outline: none!important;
    }

    .contact-form-table-cell {
        .invalid {
            display: block;
            width: auto;
            text-align: left;
        }

        .required:before {
            left: -8px;
        }

        p {
            margin: 0;
        }
    }

    & > tbody {
        & > tr {
            &:nth-child(2n) {
                background: #f1f4f7;
            }
        }

        th {
            height: 24px;
            vertical-align: middle;
            font-weight: normal;
            padding: 5px 12px;
            text-align: left;
        }

        td {
            text-align: left;
            height: 20px;
            padding: 5px 12px;
            vertical-align: middle;

            & > div {
                max-width: 100%;
            }
        }
    }

    cb-questions-header {
        display: none;
    }

    .form-single-line-text-preview {
        text-align: left;
        display: flex;
        flex-direction: column;

        .invalid {
            width: 180px;
            font-size: 12px;
        }

        .mat-mdc-form-field {
            max-width: 100%;
        }
    }
}

@media #{$mobile} {
    table.cb-contact-form-table {
        display: block;

        & > tbody {
            display: block;

            & > tr {
                display: block;
                padding: 0 15px;
                border: 0;

                &:nth-child(2n) {
                    background: none;
                }

                th, td {
                    padding: 5px;
                    height: auto;
                    vertical-align: top;
                    border: 0;
                }

                th {
                    display: block;
                }

                td {
                    display: flex;
                    width: 100%;
                    box-sizing: border-box;
                    align-items: center;

                    &.contact-form-table-cell {
                        & > div {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}


