.cb-template-edit-sidebar {
    box-sizing: border-box;
    padding: 27px 24px 27px;
    width: 320px;
    background-color: #f1f4f7;
    box-shadow: 0 1px 2px 0 rgba(0, 8, 20, 0.24), 0 0 1px 0 rgba(0, 8, 20, 0.12);
    min-height: calc(100vh - 66px);

    .cb-section-divider {
        margin: 15px -24px;
    }

    .sub-section-title {
        font-weight: bold;
        margin-bottom: 15px;
    }

    .template-name-control {
        font-size: 20px;
        color: #000;
        font-weight: bold;
        .mat-mdc-form-field {
            color: inherit;
            font: inherit;
        }
    }

    .cb-edit-link {
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    .cb-colors-control-group {
        display: flex;
        align-items: center;
        .control-label {
            flex: 1;
        }
        .menu-control-choose-color {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            .mat-mdc-icon-button.is-menu-button {
                width: 100%;
                /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
                .mat-button-ripple {
                    opacity: 0;
                }
                .icon {
                    display: none;
                }
            }
            &.small-control {
                .colors-menu-preview-control {
                    width: 70px;
                }
            }

            .colors-menu-preview-control {
                display: block;
                width: 112px;
                height: 24px;
                border-radius: 2px;
                background-color: #2567c2;
            }
        }
    }
    .buttons-container {
        .mat-mdc-button {
            width: auto;
            min-width: 60px;
            padding: 0 12px;
        }
    }
}

.cb-color-palette-container {
    box-sizing: border-box;
    width: 292px;
    padding: 16px 24px 24px;
    background: #fff;

    .color-palette-radio-group {
        margin: 0 -8px;
        display: flex;
        flex-flow: row wrap;
        .mat-mdc-radio-button {
            &.cb-button-color-radio {
                width: auto;
                height: auto;
                border-radius: 36px;
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                &.mat-radio-checked {
                    &,
                    &:hover,
                    &:focus {
                        background: transparent !important;

                        .radio-color-preview {
                            box-shadow: 0 0 0 2px rgba(117, 142, 161, 0.35),
                                0 2px 8px 0 rgba(0, 8, 20, 0.17),
                                0 0 2px 4px rgba(0, 8, 20, 0.1) !important;
                        }
                    }
                }
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                .mat-radio-label {
                }
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                .mat-radio-container {
                    display: none;
                }

                .radio-color-preview {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    border-radius: 36px;
                    &.has-border {
                        box-shadow: 0 0 0 1px rgba(117, 142, 161, 0.35);
                    }
                    &.transparent {
                        box-shadow: 0 0 0 1px rgba(117, 142, 161, 0.35);
                        position: relative;
                        &:before {
                            content: '';
                            height: 36px;
                            width: 2px;
                            background-color: rgba(117, 142, 161, 0.35);
                            display: block;
                            transform: rotate(45deg);
                            position: absolute;
                            left: 17px;
                        }
                    }
                }
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                .mat-radio-label-content {
                    padding: 0;
                }
            }
        }
        .mat-mdc-button.cb-button-default.cb-icon-button-round {
            min-width: 0;
            width: 36px;
            height: 36px;
            padding: 0;
            line-height: 24px;
            border-radius: 36px;
            box-shadow: 0 0 0 2px rgba(117, 142, 161, 0.35);
            &.active {
                background-color: rgba(117, 142, 161, 0.35) !important;
                border: solid 1px #758ea1 !important;
            }
            .mat-ripple {
                border-radius: 36px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            .mat-button-wrapper {
                line-height: inherit;
            }
            .icon {
                margin: 0;
            }
        }

        .cb-button-color-radio,
        .cb-icon-button-round {
            margin: 11px 8px;
        }
    }

    .mat-mdc-form-field {
        height: 30px;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .colors-controls-group {
        display: flex;
        align-items: center;
        margin: 15px 0 0;
        .chosen-color-preview {
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            margin-right: 15px;
            border-radius: 2px;
            background-color: #ffffff;
            border: solid 1px #758ea1;
        }

        .chosen-color-control {
            width: 120px;
            font-size: 20px;
            .mat-mdc-input-element {
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
    .load-image-controls-group {
    }

    .load-image-tab-file-name {
        display: block;
    }

    .upload-button-control {
        .hide {
            display: none;
        }
    }

    .load-image-tabs-group.cb-tabs-group {
        .load-image-tab-btn {
            margin: 10px 0;
            text-transform: uppercase;
            font-weight: bold;
        }

        .mat-mdc-tab-header {
            margin: 0 -24px;
            padding: 0 24px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-labels {
            display: flex;
            justify-content: space-between;
            padding: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-label {
                min-width: 0;
                padding: 0;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-body-content {
            padding: 10px 0;
            min-height: 100px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .mat-mdc-form-field {
                & + .mat-mdc-button {
                    margin-top: 15px;
                }
            }
        }
    }

    .repeat-image-control {
        margin: 0;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-inner-container {
            &,
            & svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.form-group {
    margin-bottom: 20px;
    &.form-group-inline {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .control-label {
            margin-right: 10px;
        }
        .controls-group {
            flex: 1;
        }
    }
    .control {
        height: 30px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .mat-mdc-form-field {
            width: 100%;
        }
    }
}

.mat-mdc-menu-panel {
    &.menu-colors-choice-panel {
        max-width: 292px;
        .mat-mdc-menu-content {
            .cb-color-palette-container {
                margin: -15px 0 -15px;
            }
        }
    }
}
