@use 'sass:map';
@import 'buttons';
@import '../variables';
@import "../mixins";

.mat-mdc-menu-panel {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17), 0 0 2px 0 rgba(0, 8, 20, 0.1);
    &.action-button-menu-panel {
        display: flex;
        background-color: #f1f4f7;
        box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17),
            0 0 2px 0 rgba(0, 8, 20, 0.1);
        color: #576a78;
        max-width: 640px;
        width: auto;
        font-size: 16px;
        color: #576a78;
        /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
        &.mat-menu-below {
            margin-top: 10px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
        &.mat-menu-above {
            margin-bottom: 10px;
        }

        .mat-mdc-menu-content {
            padding: 10px 0 ;
            min-width: 150px;
        }
        .menu-inner-content {
            &.wide-menu {
                display: flex;
            }
        }

        .menu-group-list {
            width: 180px;
            max-width: 100%;
            display: inline-block;
        }

        .mat-mdc-button {
            width: 100%;
            justify-content: flex-start;
            height: auto;
            display: block;
            white-space: normal;
            min-height: 32px;
            line-height: 1.3;
            text-align: left;
            margin: 2px 0;
            padding: 8px 8px;
            &:hover {
                background-color: rgba(157, 181, 199, 0.15);
            }

            &:focus {
                background-color: rgba(157, 181, 199, 0.3);
            }
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            .mat-button-wrapper {
                display: flex;
            }

            .icon {
                display: inline-block;
                margin-right: 10px;
            }

            .menu-item-text {
                flex: 1;
            }
        }

        .mat-divider {
            margin: 10px 8px;
            border: 0;
            height: 2px;
            display: block;
            background: #758ea1;
        }
    }

    .mat-mdc-menu-content {
        padding: 6px 0px !important;
        min-width: 195px;
        .mat-mdc-menu-item {
            display: flex;
            align-items: center;
            height: 40px;
            padding-left: 12px;
            padding-right: 12px;
            .svg-inline--fa {
                font-size: 24px;
                margin-right: 10px;
            }
            .icon {
                margin-right: 10px;
            }
        }
        .apply-button {
            display: block;
            text-align: right;
        }

        .mat-icon {
            color: $text-color-grey;
        }

        .icon-wrap {
            display: inline-block;
            width: 24px;
            text-align: left;
            line-height: 0;
            margin-right: 10px;
        }
        .svg-icon {
            display: inline-block;
        }
    }
}

.cb-dialog-breadcrumbs {
    display: flex;
    flex-direction: row;
    margin: 0 0 30px;
    padding: 0;
    overflow: hidden;
    .cb-dialog-breadcrumbs-item {
        &:first-child .cb-dialog-breadcrumbs-link {
            padding-left: 20px;
        }

        &.active,
        &.passed {
            .cb-dialog-breadcrumbs-link {
                &,
                &:hover {
                    background: #ff5336;
                    color: #fff;
                    &:after {
                        border-left-color: #ff5336;
                    }
                }
            }
        }

        &.passed {
            .cb-dialog-breadcrumbs-link {
                &,
                &:hover {
                    background: rgba(255, 83, 54, 0.7);
                    color: rgba(255, 255, 255, 0.85);
                    &:after {
                        border-left-color: rgba(255, 83, 54, 0.7);
                    }
                }
            }
        }

        .cb-dialog-breadcrumbs-link {
            display: block;
            position: relative;
            padding: 10px 0 10px 55px;
            color: #576a78;
            text-decoration: none;
            background: #f1f4f7;
            cursor: pointer;

            &:before,
            &:after {
                content: ' ';
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                left: 100%;
                margin-top: -50px;
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                border-left: 30px solid #fff;
            }
            &:before {
                border-left-color: #fff;
                border-left-width: 33px;
                z-index: 1;
            }

            &:after {
                border-left-color: #f1f4f7;
                z-index: 2;
            }

            &:hover {
                background: #e4eaef;
                &:after {
                    border-left-color: #e4eaef;
                }
            }
        }
    }
}

.response-filter-menu {
    width: 230px;
    .mat-mdc-form-field{
        padding: 10px 10px 0;
        box-sizing: border-box;
    }
}

.invitations-filter-menu {
    width: 150px;
    height: 230px;
    .mat-mdc-menu-content {
        min-width: 80px;
    }
}
button[mat-menu-item],
a[mat-menu-item]{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    mat-icon{
        margin: 0 !important;
        height: 18px;
        width: 18px;
        font-size: 18px;
        color: $text-color-light-grey;
    }
    span{
        @include typo-body-2-regular;
        color: $backgrounds-dark-100;
    }
}
span[mat-menu-item].status-item {
    display: flex;
    padding: 8px 16px!important;
    align-items: center;
    gap: 10px;
    height: unset !important;
    line-height: unset !important;
    &:hover{
        background: rgba(map-get($brand-primary-palette, 500), 0.08);
    }
    .mat-icon{
        margin: 0 !important;
    }
}
.mat-mdc-menu-item{
    &.mat-primary{
        &:hover{
            background: rgba(map-get($brand-primary-palette, 500), 0.08) !important;
        }
    }
    &.mat-accent{
        &:hover{
            background: rgba(map-get($brand-secondary-palette, 500), 0.08) !important;
        }
    }
    &.mat-warn{
        &:hover{
            background: rgba(map-get($system-danger-palette, 500), 0.08) !important;
        }
    }
    &:not(.mat-primary , .mat-accent, .mat-warn){
        &:hover{
            background: $backgrounds-light-300;
        }
    }
}
.mat-mdc-menu-panel.status-menu{
    .mat-mdc-menu-content{
        width: 160px;
    }
}
