@import '../variables';
.cb-surveys-list-content {
    .cb-surveys-list-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .title-group {
            flex: 1;
        }

        h2 {
            display: inline-block;
            margin: 0;
            font-size: 24px;
            line-height: 1.35;
        }
        button {
            display: inline-block;
        }
    }

    .cb-surveys-list-header-buttons {
        display: inline-block;
        padding: 0 20px;
        .create-survey-wrapper{
            .btn-new-survey-from-file{
                display: none;
            }
            &:hover {
                .btn-new-survey-from-file{
                    display: block;
                    position: absolute;
                }
            }
        }
        button {
            font-size: 14px;
            font-weight: bold;
            min-width: 136px;
            color: #758ea1;

            & + button {
                margin-left: 20px;
            }

            &.btn-new-survey {
                background-color: #e9461b;
                font-weight: bold;
                color: #fff;
            }
        }
    }

    .options-block {
        &.contacts-list-filter {           
            mat-form-field {
                margin-bottom: 0;
            }
        }
        float: right;
        display: flex;
        align-items: center;
        button {
            padding: 0;
            margin: 0;
            cursor: pointer;
            width: 40px;
            font-size: 16px;
            color: #758ea1;
            line-height: 16px;
        }
        .toggle-view-buttons {
            margin-left: 15px;
            .toggle-icon {
                padding: 0;
                cursor: pointer;
                color: #758ea1;
                height: 32px;
                width: 44px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                line-height: 0;

                &.selectedIcon {
                    border-radius: 2px;
                    background-color: rgba(117, 142, 161, 0.35);
                    color: #212121;
                }

                .icon {
                    color: inherit;
                }
            }
        }
    }

    .load-more {
        width: 100%;
        text-align: center;
        margin-top: 20px;

        p {
            margin-top: 5px;
        }
    }
}

.survey-list {
    &.survey-row-list {
        &:not(.survey-list-inside-folder) {
            margin-top: 16px;
            margin-bottom: 15px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
                0 0 2px 0 rgba(0, 8, 20, 0.12);
            >:first-child{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            >:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
        &:not(.survey-list-inside-folder)
        &.survey-list-inside-folder {
            margin: 0 -15px;
        }
    }
    &.border {
        cb-survey-list-item-header.cb-survey-list-item {
            display: block;
            border-bottom: 2px solid $basic-alpha-8;
            background: $backgrounds-light-100;
        }
        cb-survey-list-item.cb-survey-list-item {
            display: block;
            border-bottom: 1px solid $basic-alpha-8;
            background: $backgrounds-light-100;
        }

    }
    &.stripped {
        .cb-survey-list-item {
            display: block;
            background: #fff;
            &:nth-child(2n-1) {
                background: #f1f4f7;
            }
        }
    }
    
    &.survey-cards-list {
        margin: 0 -12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .cb-survey-list-item {
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            padding: 0 12px;
            margin: 8px 0 14px;
            width: calc(33.333333333% - 24px);
            min-height: 180px;
        }
        &.survey-list-inside-folder {
            .cb-survey-list-item {
                margin-top: 0;
                margin-bottom: 22px;
            }
        }
    }
}
.survey-folders-list {
    margin-top: 20px;
    .cb-survey-folder {
        display: block;
        margin-bottom: 24px;
        min-height: 30px;
    }
}
mat-expansion-panel.cb-folder-panel{
    div.mat-expansion-panel-body{
        padding: 0;
        table{
            border-radius: 0;
            border: 0;
        }
    }
}
.survey-names {
    flex: 2;
    word-break: break-all;
}

.cb-surveys-list-content {
    .search-group {
        width: 250px;
    }
    .searchInput {
        float: right;
        margin-top: 32px;
    }

    .selectedFilters {
        color: #ff6a14;
    }
}

.cdk-global-overlay-wrapper .mat-mdc-dialog-container {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
}
.cb-survey-main {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix{
        padding-right: 55px;
    }
    .title-group {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-infix{
            padding-right: 0;
        }
    }
}

.cb-add-recipient {
    form {
        &.active-directory-inputs {
            .cb-surveys-list-content {
                width: 100%;

                .contacts-list-filter {
                    display: flex;
                    float: right;
                }
            } 
            
        }
        
        .cb-surveys-list-content {
            text-align: right;

            .contacts-list-filter {
                float: none;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }  
}
