$ratingPreviewIconColor: #2567c2; //rgb(37, 103, 194)
.cb-question-item-cb-question-item-content {
    &:focus {
        outline: none!important;
    }
}
.rating {
    &.horizontal-rating {
        width: 112px;
        .rating-item {
            display: block;
            padding-right: 0;
            &:not(:last-child) {
                padding-right: 0;
                padding-bottom: 10px;
            }
        }
    }
    &.star-text-align {
        display: flex;
        .rating-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-selected-value {
        width: 100%;
        text-align: center;
        display: block;
    }
    .rating-item {
        display: inline-block;
        @media #{$mobile} {
            display: block;
        }
        &:not(:last-child) {
            padding-right: 10px;
        }
    }

    &.pill-rating {
        .rating-item {
            padding-right: 0;
        }
    }

    .horizontal-bar-rate {
        cursor: pointer;
        padding: 0 3px;
        margin: 0;
        height: 12px;
        display: block;
        border: 2px solid rgba(37, 103, 194, 0);
        &.item-default {
            border: 2px solid $ratingPreviewIconColor;
        }
        &.item-selected {
            background-color: $ratingPreviewIconColor;
        }
    }

    .pill-rate {
        cursor: pointer;
        width: 64px;
        height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        padding: 0;
        display: inline-block;
        border: 2px solid rgba(37, 103, 194, 0);
        text-align: center;
        line-height: 24px;
        &.item-default {
            border: 2px solid $ratingPreviewIconColor;
        }
        &.item-selected {
            background-color: $ratingPreviewIconColor;
        }
    }

    .reversed-box-rate {
        width: 36px;
        height: 24px;
        cursor: pointer;
        padding: 0 3px;
        margin: 0;
        height: 2em;
        display: inline-block;
        border: 2px solid rgba(37, 103, 194, 0);
        &.item-default {
            border: 2px solid $ratingPreviewIconColor;
        }
        &.item-selected {
            background-color: $ratingPreviewIconColor;
        }
    }

    .vertical-bar-rate {
        cursor: pointer;
        margin-bottom: 0.1em;
        height: 36px;
        width: 16px;
        display: inline-block;
        border: 2px solid rgba(37, 103, 194, 0);
        &.item-default {
            border: 2px solid $ratingPreviewIconColor;
        }
        &.item-selected {
            background-color: $ratingPreviewIconColor;
        }
    }

    .line-rate {
        cursor: pointer;
        padding: 0 3px;
        margin: 0;
        height: 0.5em;
        width: 2.5em;
        display: inline-block;
        border: 2px solid rgba(37, 103, 194, 0);
        &.item-default {
            border: 2px solid $ratingPreviewIconColor;
        }
        &.item-selected {
            background-color: $ratingPreviewIconColor;
        }
    }
    .star-rate {
        position: relative;
        width: 36px;
        height: 36px;
        cursor: pointer;
        padding: 0 3px;
        margin: 0;
        font-size: 2em;
        display: inline-block;
        text-align: center;
        color: $ratingPreviewIconColor !important;
        .icon {
            width: 100%;
            height: 100%;
            color: inherit;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .selected-icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
            z-index: 5;
        }
        .default-icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
        &.star-default {
        }
        &.star-selected {
            .selected-icon {
                opacity: 1;
            }
        }
    }
    .box-rate {
        cursor: pointer;
        padding: 0;
        margin: 0;
        height: 36px;
        width: 36px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        border-radius: 8px;
        text-align: center;
        line-height: 36px;
        &.box-default {
            border: 2px solid rgba(37, 103, 194, 0.35);
        }
        &.box-selected {
            border: 2px solid rgb(37, 103, 194);
        }
    }
}
