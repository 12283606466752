.cb-survey-set-section {
    .cb-survey-set-main-title {
        margin: 0 0 15px;
        font-size: 24px;
        line-height: 1.33;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
    }
    .cb-survey-set-sub-title,
    .dialog-content .cb-permissions-main-title,
    .dialog-content .cb-permissions-title {
        font-size: 18px;
        font-weight: bold;
    }
    .cb-survey-set-sub-title {
        margin-bottom: 16px;
    }
}
