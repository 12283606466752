 .cb-image-choice-container.cb-question-item-content {
    .image-choices-group {
        display: flex;
        align-items: flex-end;
    }


    .choice-item-container {
        &:hover {
            .image-wrapper {
                box-shadow: 0 0 1px blue;
            }
        }
        &:focus {
            outline: none!important;
            .image-wrapper {
                box-shadow: 0 0 3px grey;
            }
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-checked {
        .choice-item-container{
            .image-wrapper {
                box-shadow: 0 0 5px blue;
            }
        }
        &:hover {
            .image-wrapper {
                box-shadow: 0 0 4px blue;
            }
        }
        &:focus {
            outline: none!important;
            .image-wrapper {
                box-shadow: 0 0 6px darkblue;
            }
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin: 5px;
        img {
            max-width: inherit;
            max-height: 100%;
        }
    }

    .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

