@mixin cb-outline-search-field() {
    .outline-search-field {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-outline {
            top: 0 !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-flex{
            padding-left: 12px !important;
            padding-right: 11px !important;
        }
    }
}