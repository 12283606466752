/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material';
@import '../../shared/sass/variables';
@import './themes-variables';
@import './typography';
@import './grid';

@import '../../../node_modules/c3/c3.min.css';

@import '../../shared/sass/global';

@import '../../shared/sass/mixins';

@import '../../shared/sass/partials/helpers';
@import '../../shared/sass/partials/icons';
@import '../../shared/sass/partials/buttons';
@import 'partials/toolbar';
@import '../../shared/sass/partials/form';
@import 'partials/layout';
@import '../../shared/sass/partials/rich-editor';
@import '../../shared/sass/partials/menu';
@import 'partials/table';
@import 'partials/tooltip';
@import 'partials/tabs';
@import '../../shared/sass/partials/dialog';
@import './partials/dialog';
@import '../../shared/sass/partials/expansion-panel';

@import '../../shared/sass/components/components';

@import 'partials/style-editor';
@import 'partials/styles-template-edit';
@import 'partials/snack-bar';

@import 'pages/contacts';
@import 'pages/styles-page';
@import '../../shared/sass/pages/take-survey';
@import '../../shared/sass/pages/dashboards';
@import 'pages/survey-editor';
@import 'pages/settings';
@import 'pages/survey-themes';

@import '../../shared/sass/components/group-details';
@import '../shared/_custom-shared-components';
@import '../../shared/sass/partials/cards';

@include mat.all-component-themes($brand-theme);

@include custom-shadows();
@include cb-outline-search-field();

.mat-mdc-checkbox-ripple,
.mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none;
}

.custom-sort {
    .mat-sort-header-content {
        letter-spacing: 1px;
    }
}

.round-10 {
    border-radius: 10px !important;
}
