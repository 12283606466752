.cb-style-editor {
    position: relative;
    display: flex;
    margin: -37px 0px 0;
    max-width: 1440px;
    width: 100%;
    .cb-take-survey-progress {
        margin: 0 0 12px;
    }
}

.cb-template-edit-content {
    box-sizing: border-box;
    max-width: 985px;
    padding: 24px 0 24px 28px;
    flex: 1 0 calc(100% - 160px);
    width: calc(100% - 160px);
    &.font-merriweather-sans {
        .cb-take-survey-main-title {
            font-weight: bold;
            letter-spacing: 0.8px;
        }
    }
    &.font-helvetica-neue {
        .cb-take-survey-main-title {
            font-weight: bold;
            letter-spacing: 0.8px;
        }
    }

    &.font-rubik {
        .cb-take-survey-main-title {
            font-weight: bold;
            letter-spacing: 0.8px;
        }
    }

    &.font-raleway {
        .cb-take-survey-main-title {
            font-weight: bold;
            line-height: 1em;
        }
    }

    &.font-dosis {
        .cb-take-survey-main-title {
            font-weight: bold;
            letter-spacing: 0.8px;
        }
    }
}

.cb-take-survey-footer-text {
    margin-top: 10px;
}
