@use 'sass:map';
@import '../variables';
@import '../global';

.c3 svg {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    @media #{$mobile} {
        font-size: 12px;
    }

    .c3-grid {
        line {
            stroke: #758ea1;
            @media screen {
                opacity: 0.35;
            }
        }
    }

    .c3-legend-item {
        .c3-legend-item-tile {
        }

        .c3-legend-item-event {
            height: 40px !important;
        }
    }

    .domain {
        stroke: #758ea1;
        opacity: 0.35;
        fill: none;
    }

    .c3-chart-lines {
        path {
            stroke: #758ea1;
            opacity: 0.35;
            fill: none;
        }
    }

    /*    line, path {
			stroke: #758ea1;
			opacity: 0.35;
			fill: none;
		}*/
    .tick {
        fill: #758ea1;

        line {
            display: none;
        }
    }

    .c3-chart-texts {
        .c3-text {
            transform: translateY(-5px);
            fill: #000000 !important;
            @media #{$desktop-up} {
                font-size: 12px;
            }
            @media #{$tablet-down} {
                font-size: 10px;
            }
            @media #{$mobile} {
                font-size: 8px;
            }
        }

        .c3-chart-text {
            &.c3-target-nps-chart-data {
                .c3-text {
                    transform: translateY(0);
                }
            }
        }
    }

    .c3-ygrid {
        stroke-dasharray: none;
    }

    .c3-axis-y {
        .tick {
            text {
                //transform: translateY(13px);
            }
        }

        .domain {
            opacity: 0;
        }
    }
}

.c3-tooltip-container {
    background-color: #ffffff;
    box-shadow: 0px 2px 5.6px 0.4px rgba(0, 0, 0, 0.12);
}

.color-indicators-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -20px;
    @media #{$desktop-down} {
        margin: 0 -10px;
    }

    .color-indicator-item {
        width: 48px;
        height: 48px;
        border-radius: 2px;
        background-color: #3071d6;
        margin: 0 20px 20px;
        @media #{$desktop-down} {
            width: 32px;
            height: 32px;
            margin: 0 10px 10px;
        }
    }
}

.horizontal-bar-rate-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$mobile} {
        align-items: flex-start;
        flex-direction: column;
    }

    .custom-legend {
        margin-left: -20px;
        @media #{$mobile} {
            margin: 20px 0;
        }
    }

    .horizontal-bar-rate {
        width: calc(100% - 170px);
        @media #{$mobile} {
            width: 100%;
        }
    }

    @media #{$tablet-down} {
        font-size: 14px;
    }
}

.horizontal-bar-rate {
    .c3 svg {
        .c3-axis-y {
            /*            clip-path: none;
						transform: translate(0, 100px);*/
            .tick {
                text {
                    transform: translateY(0);
                }
            }
        }
    }
}

.cb-h-progress-bar-group {
    display: flex;

    .cb-bar-item {
        text-align: center;
        color: #fff;
        position: relative;
        height: 24px;

        .cb-bar-text {
            z-index: 2;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .type-most-likely {
        background-color: map-get($map: $chartColors, $key: red);
        border-radius: 2px 0 0 2px;
    }

    .type-worst {
        background-color: #e91a33;
    }

    .type-not-chosen {
        background-color: #a6a6a6;
        border-radius: 0 2px 2px 0;
    }
}

.cb-diagram-legend {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media #{$mobile} {
        flex-direction: column;
        align-items: flex-start;
    }

    .cb-legend-item {
        display: inline-flex;
        margin: 0 15px 0 0;
        align-items: center;
        @media #{$mobile} {
            display: flex;
            margin: 0 0 10px;
        }
    }

    .legend-indicator {
        display: inline-block;
        margin-right: 7px;
        width: 14px;
        height: 14px;
        border-radius: 25px;
        background-color: #4aaf50;

        &.type-most-likely {
            background-color: map-get($map: $chartColors, $key: green);
        }

        &.type-worst {
            background-color: map-get($map: $chartColors, $key: red);
        }

        &.type-not-chosen {
            background-color: map-get($map: $chartColors, $key: grey);
        }
    }
}

.donut-chart-container {
    .chart-circles-container {
        position: relative;
        max-width: 100%;
    }

    .c3-donut-inner-first {
        width: calc(100% - 124px);
        top: 50%;
        left: 62px;
        position: absolute !important;
        transform: translateY(-50%);
    }

    .c3-donut-inner-second {
        width: calc(100% - 194px);
        top: 50%;
        left: 97px;
        position: absolute !important;
        transform: translateY(-50%);
    }
}

.multi-circular-diagram-info {
    margin: 20px 0;
    padding: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5.6px 0.4px rgba(0, 0, 0, 0.12);
}

.donut-chart-container,
.chart-container {
    display: flex;
    align-items: center;
    @media #{$mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
}

.donut-chart {
    width: 300px;
    height: 300px;
    max-width: 100%;
    max-height: none;
}

.legend-container {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    @media all and (max-width: 480px) {
        flex-direction: column-reverse;
    }

    .custom-legend {
        width: 50%;
        @media all and (max-width: 480px) {
            width: 100%;
        }
    }
}

.custom-legend {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    max-width: 100%;

    &.row {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: left;

        .legend-item {
            margin: 10px;
        }

        .legend-text {
            padding-top: 10px;
        }
    }

    @media #{$desktop-down} {
        font-size: 14px;
        margin-left: 10px;
    }
    @media #{$tablet-only} {
        font-size: 12px;
        width: 120px;
    }
    @media #{$mobile} {
        margin: 20px 0;
        width: 250px;
    }

    .legend-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;
        @media #{$tablet-down} {
            margin-bottom: 10px;
        }
    }

    .legend-text {
        flex: 1;
        order: 2;
        padding-top: 6px;
        font-size: 14px;
    }

    .badge {
        order: 1;
        display: inline-block;
        font-size: 30px;
        line-height: 1;
        margin-right: 10px;
        border-radius: 2px;
        @media #{$tablet-down} {
            font-size: 12px;
            width: 16px;
            height: 16px;
        }
    }
}

.c3.horizontal-bars-chart {
    svg {
        & > g {
            transform: translate(60px, 0) !important;

            .c3-chart {
                clip-path: none !important;
            }
        }
    }
}

.survey-reports-section {
    angular-tag-cloud{
        font-family:  'Lato', sans-serif;
    }
}
