.cb-expansion-panel {
    background-color: #ffffff;
}
.cb-expansion-panel, .draggable-item {
    &.cb-simple-panel {
        border-radius: 0;
        box-shadow: none !important;
        .mat-expansion-panel-body {
            padding: 0 15px 15px;
        }
    }

    .mat-expansion-indicator {
        display: none;
    }

    .mat-expansion-panel-header {
        padding: 5px 5px 5px 15px;
        .mat-content {
            align-items: center;
        }
        .mat-expansion-panel-header-title {
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
        }
        &.mat-expanded {
            .icon-collapse-wrap {
                .icon-less {
                    display: inline-block !important;
                }
                .icon-more {
                    display: none !important;
                }
            }
        }
        cb-help-icon {
            padding-top: 6px;
        }
    }

    .mat-expansion-panel-body {
        padding: 0 15px;
    }

    .icon-collapse-wrap {
        line-height: 0;

        .icon-more {
            display: inline-block;
        }

        .icon-less {
            display: none;
        }

        .icon-filter-down {
            transform: rotate(-90deg);
        }

        .icon-filter-up {
            transform: rotate(-90deg);
        }
    }

    .matrix-hidden-option-section {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: 10px;
        height: 5px;
        overflow: hidden;
    }

    .matrix-col-row-fields-group {
        font-size: 16px;
        line-height: 1.25;
        &.header-fields-group {
            margin: 10px 0;
            padding: 0 11px;
        }
        .item-type {
            .icon {
                color: inherit;
                margin-right: 4px;
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper {
            padding-bottom: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-underline {
                bottom: 0;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-infix {
                padding: 0.3em 0;
                border-top: 0;
            }
        }

        .matrix-item.shadowed-section.draggable-item {
            padding-top: 16px;
            padding-bottom: 8px;
            margin-bottom: 10px;
        }

        .cell {
            padding: 0 10px;
            &.id-cell {
                width: 60px;
                text-align: center;
            }
            &.type-cell {
                width: 160px;
            }
            &.text-cell {
                flex: 1;
            }
            &.branching-cell {
                width: 60px;
            }
            &.alias-cell {
                width: 160px;
            }
            &.actions-cell {
                width: 70px;
                text-align: right;
                .mat-mdc-icon-button {
                    margin-left: 5px;

                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
            }

            .mat-mdc-form-field {
                margin: 0;
                width: 100%;
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-label {
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-label-wrapper {
                }
            }
            .inpit-with-merge {
                width: calc(100% - 30px);
            }
            .row-merge-codes {
                width: 20px;
                display: inline-block;
            }
            p {
                margin: 0;
            }
        }
    }

    .contact-form-fields-group {
        font-size: 16px;
        line-height: 1.25;
        &.header-fields-group {
            margin: 10px 0;
            padding: 0 11px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper {
            padding-bottom: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-underline {
                bottom: 0;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-infix {
                padding: 0.3em 0;
                border-top: 0;
            }
        }

        .contact-form-field.shadowed-section.draggable-item {
            padding-top: 16px;
            padding-bottom: 8px;
            margin-bottom: 10px;
        }

        .cell {
            padding: 0 10px;
            &.id-cell {
                width: 60px;
                text-align: center;
            }
            &.label-cell {
                flex: 1;
            }
            &.answer-format-cell {
                width: 250px;
            }
            &.actions-cell {
                width: 70px;
                text-align: right;
                .mat-mdc-icon-button {
                    margin-left: 5px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
            }

            .mat-mdc-form-field {
                margin: 0;
                width: 100%;
            }
            p {
                margin: 0;
            }
        }
    }
}

.cb-expansion-panel.cb-page-panel {
    position: relative;
    margin-bottom: 10px;

    .mat-expansion-panel-header {
        &.mat-expanded {
            .page-title {
                display: none;
            }

            .cb-rename-page-section {
                display: block;
            }
        }

        .page-title {
            display: block;
            margin-left: 10px;
        }

        .cb-rename-page-section {
            display: none;
        }
    }

    .mat-expansion-panel-body {        
        padding: 15px;
        .cb-page-edit-toolbar {
            background-color: #fff;
            margin: -25px -15px -15px;
            padding: 15px;
        }

        .cb-page-edit-toolbar + .cb-dashboard-items-container {
            margin-top: 35px;
        }
    }

    .cb-hidden-item-card {
        display: block;
    }
}



.cb-expansion-panel.cb-hidden-page-panel {
    .mat-expansion-panel-body {
        padding-top: 20px;
        .inner-item-card {
            box-sizing: border-box;
        }
        .cb-add-hidden-item {
            background-color: #fff;
            margin: -5px -15px -15px;
            padding: 15px;
        }
    }
}

.cb-expansion-panel.cb-hidden-page-panel {
    .cb-hidden-item-card[ng-reflect-is-adding-item-process="true"] {
        .inline-edit-section {
            margin: -20px;
            width: calc(100% + 40px);
        }
    }
}

.cb-expansion-panel.cb-form-expansion-panel {
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: visible;
    .mat-expansion-panel-header {
        position: relative;
        padding: 0;

        .form-section-title {
            margin: 0;
        }

        .icon-collapse-wrap {
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
    }
}

.cb-expansion-panel.cb-widget-filter-panel {
    border-radius: 0;
    background-color: transparent !important;
    box-shadow: none !important;

    .mat-expansion-panel-header {
        position: relative;
        padding: 0 12px 0 40px;

        &.mat-expanded {
            .mat-expansion-panel-header-description {
                display: none;
            }
            .filter-edit-controls {
                display: block;
                margin: 0 -4px;
                .mat-mdc-icon-button {
                    margin: 0 4px;
                }
            }
        }

        .mat-expansion-panel-header-title {
            position: relative;
            margin: 0 5px 0 0;
            font-size: 14px;
        }
        .mat-content {
            overflow: visible;
        }
        .mat-expansion-panel-header-description {
            justify-content: flex-end;
            margin: 0;
            font-size: 12px;
            color: #212121;
            line-height: 1.33;
        }
        .form-section-title {
            display: flex;
            align-items: center;
            margin: 0;
            font-size: inherit;
        }
        .icon-collapse-wrap {
            position: absolute;
            left: -23px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .mat-expansion-panel-body {
        padding: 0 40px;
        .cb-widget-filter-data {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 1.33;
            color: #212121;
        }
        .mat-mdc-form-field {
            font-size: 16px;
            line-height: 1.5;
        }
        .mat-mdc-form-field.full-width {
            display: block;
        }
    }
}

.cb-expansion-panel.cb-response-details-panel {
    overflow: visible;

    .mat-expansion-panel-header {
        margin: 0 -36px;
        padding: 0;
        .mat-expansion-panel-header-title {
            position: relative;
            padding: 0 36px;
            font-size: 20px;
            font-weight: bold;
            .icon-collapse-wrap {
                position: absolute;
                top: 0;
                left: 12px;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
        overflow: hidden;
        font-size: 16px;
    }
}

.cb-expansion-panel.cb-user-info-panel {
    .mat-expansion-panel-header {
        margin: 0 -36px;
        padding: 0;
        .mat-expansion-panel-header-title {
            position: relative;
            padding: 0 36px;
            font-size: 20px;
            font-weight: bold;
            .icon-collapse-wrap {
                position: absolute;
                top: 0;
                left: 12px;
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
        font-size: 16px;
    }

    .cb-licensing-limit-exceeded {
        padding: 10px;
        border: black 1px dashed;
        background: #fdea79;
    }
}

.cb-multiple-options-image-item {
    .choices-checkbox-group {
        .id-cell {
            width: 30px;
        }
        .checkbox-cell {
            width: 80px;
            text-align: center;
        }
        .image-cell {
            flex: 4;
            img {
                margin-right: 1em;
                padding-left: 20px;
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
        .alias-cell {
            flex: 2;
        }
        .actions-cell {
            width: 30px;
            text-align: right;
        }
    }
}

.cb-permissions-section-wrapper {
    .mat-accordion {
        margin: 15px 0 20px;
        display: block;
    }

    .icon-control {
        &-container {
            position: relative;
        }
        &-element {
            position: absolute;
            right: 0;
        }
    }

    .pseudo {
        color: #6a7991;
    }
}

.cb-folder-panel.cb-expansion-panel {
    .mat-expanded {
        .icon-caret-wrap {
            .icon-up {
                display: inline-block;
            }
            .icon-down {
                display: none;
            }
        }
    }
    .cb-surveys-list-no-items {
        padding-left:15px;
        font-style: italic;
    }

    .cb-del-icon-spacing {
        margin-right: 20px;
    }

    .full-width {
        width: 100%;
    }

    .icon-folder {
        margin-right: 15px;
        color: #cedbe0;
    }

    .btn-folder-settings {
        color: #758ea1;
        font-size: 20px;
        line-height: 20px;
    }

    .icon-folder-wrap {
        color: rgba(157, 181, 199, 0.3);
        font-size: 20px;
        line-height: inherit;
        margin-right: 10px;
    }
    .icon-caret-wrap {
        color: #758ea1;
        font-size: 14px;
        line-height: inherit;
        margin-left: 8px;
        .icon-up {
            display: none;
        }
        .icon-down {
            display: inline-block;
        }
    }
}

.cb-grey {
    .mat-expansion-panel-body {
        background-color: #F1F4F7;
    }
}
