@use 'sass:map';
//Fonts
$font-family-sans-serif: 'Lato', sans-serif;

$mobile: 'only screen and (max-width: 767px)';
$mobile-xs: 'only screen and (max-width: 450px)';
$tablet-down: 'only screen and (max-width: 1023px)';
$tablet-up: 'only screen and (min-width: 768px)';
$tablet-only: 'only screen and (min-width: 768px) and (max-width: 1023px)';
$desktop-up: 'only screen and (min-width: 1024px)';
$desktop-down: 'only screen and (max-width: 1169px)';
$desktop-only: 'only screen and (min-width: 1024px) and (max-width: 1169px)';
$desktop-lg-up: 'only screen and (min-width: 1170px)';
$large-tablet-only: 'only screen and (min-width: 768px) and (max-width: 1279px)';
$large-tablet-end: 'only screen and (min-width: 1280px)';
$large-tablet-down: 'only screen and (max-width: 1279px)';

$body-bg: #fafcfc;

$surveyHeaderBgColor: #2567c2; // used in color-picker.component
$surveyBoardBgColor: #ffffff;
$surveyFooterBgColor: #fafcfc;
$surveyButtonsBgColor: #2567c2; // used in color-picker.component


$brand-primary-palette: (
    50: #fae9e8,
    100: #feccc0,
    200: #feaa98,
    300: #fe8970,
    400: #FF664C,
    500: #FF5336,
    600: #FF3D1C,
    700: #F82F0C,
    800: #d74129,
    900: #bd3621,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);

$brand-secondary-palette: (
    50: #e8eafa,
    100: #c4caf1,
    200: #9ca8e8,
    300: #7286e0,
    400: #335FE2,
    500: #2550D1,
    600: #1B45C5,
    700: #1138AF,
    800: #0033af,
    900: #001f9c,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(255, 255, 255, 1),
        600: rgba(255, 255, 255, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);
$brand-tertiary-palette: (
    50: #fdf9c7,
    100: #fbf5a2,
    200: #faf17d,
    300: #f7ec60,
    400: #F8D671,
    500: #F4C343,
    600: #D1A030,
    700: #AF8021,
    800: #8F6A1E,
    900: #7A5A1A,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);

$system-info-palette: (
    50: #EBF8FC,
    100: #c4caf1,
    200: #9ca8e8,
    300: #7286e0,
    400: #335FE2,
    500: #2550D1,
    600: #1B45C5,
    700: #1138AF,
    800: #0033af,
    900: #001f9c,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);
$system-success-palette: (
    50: #F0FAEE,
    100: #b4e89e,
    200: #93df75,
    300: #77d753,
    400: #7DD856,
    500: #49BF26,
    600: #31A41B,
    700: #1D8913,
    800: #0E6E0C,
    900: #0A5A0A,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);
$system-warning-palette: (
    50: #FFF7F0,
    100: #ffeaa2,
    200: #ffe17d,
    300: #ffd960,
    400: #FFBE71,
    500: #FF9F43,
    600: #D96F1D,
    700: #B75B21,
    800: #8F4A1E,
    900: #7A3E1A,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);
$system-danger-palette: (
    50: #fae9e8,
    100: #feccc0,
    200: #feaa98,
    300: #fe8970,
    400: #F8657F,
    500: #FB4565,
    600: #F32045,
    700: #DE1135,
    800: #c92249,
    900: #b51a47,
    contrast: (
        50: rgba(0, 0, 0, 1),
        100: rgba(29, 31, 35, 1),
        200: rgba(50, 52, 58, 1),
        300: rgba(50, 52, 58, 1),
        400: rgba(239, 241, 244, 1),
        500: rgba(245, 246, 247, 1),
        600: rgba(250, 250, 251, 1),
        700: rgba(255, 255, 255, 1),
        800: rgba(255, 255, 255, 1),
        900: rgba(255, 255, 255, 1),
    )
);

$shadow-light-2dp: 0px 2px 2px 0px rgba(100, 116, 139, 0.12);
$shadow-light-4dp: 0px 4px 8px 0px rgba(100, 116, 139, 0.12);
$shadow-light-8dp: 0px 10px 12px 0px rgba(100, 116, 139, 0.15);
$shadow-light-16dp: 0px 16px 32px 0px rgba(129, 136, 147, 0.23);
$shadow-light-24dp: 0px 24px 48px 0px rgba(129, 136, 147, 0.24);
$shadow-light-32dp: 0px 32px 96px 10px rgba(129, 136, 147, 0.24);

$basic-alpha-8: rgba(101, 109, 123, 0.08);
$basic-alpha-16: rgba(101, 109, 123, 0.16);
$basic-alpha-24: rgba(101, 109, 123, 0.24);
$basic-alpha-32: rgba(101, 109, 123, 0.32);
$basic-alpha-48: rgba(101, 109, 123, 0.48);

$white-alpha-8: rgba(255, 255, 255, 0.08);
$white-alpha-16: rgba(255, 255, 255, 0.16);
$white-alpha-24: rgba(255, 255, 255, 0.24);
$white-alpha-32: rgba(255, 255, 255, 0.32);
$white-alpha-48: rgba(255, 255, 255, 0.48);

$backgrounds-light-100: #FFFFFF;
$backgrounds-light-200: #FAFAFB;
$backgrounds-light-300: #F5F6F7;
$backgrounds-light-400: #EFF1F4;

$backgrounds-dark-100: #32343A;
$backgrounds-dark-200: #1D1F23;
$backgrounds-dark-300: #000000;
//text and icons colors
$text-color-dark: rgba(0, 0, 0, 1);
$text-color-dark-grey: rgba(59, 63, 69, 1);
$text-color-grey: rgba(100, 105, 112, 1);
$text-color-light-grey: rgba(160, 164, 170, 1);
$text-color-light: rgba(255, 255, 255, 1);

$pallete-colors: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;
$pallete-names: primary, accent, warn, warning, success;

/*
export const namedChartColors = {
    mint: '#0A9A78',
    blueChill: '#108BAC',
    tropez : '#2B549A',
    mariner: '#335FE2',
    lavender: '#6841B9',
    greySuit: '#7F7C86',
    jumbo: '#5C5765',
    pearl: '#9A5628',
    cinnabar: '#C94015',
    persimmon: '#F84C2A',
    butterCup: '#F08F00',
    lemon: '#DDC700'
}
export const chartColors = {
    colors: Object.values(namedChartColors),
    red: namedChartColors.persimmon,
    green: namedChartColors.mint,
    gray: namedChartColors.greySuit,
    yellow: namedChartColors.lemon,
    lineNeutral: namedChartColors.blueChill
}
*/

$namedChartColors : (
    mint: #0A9A78,
    blueChill: #108BAC,
    tropez : #2B549A,
    mariner: #335FE2,
    lavender: #6841B9,
    greySuit: #7F7C86,
    jumbo: #5C5765,
    pearl: #9A5628,
    cinnabar: #C94015,
    persimmon: #F84C2A,
    butterCup: #F08F00,
    lemon: #DDC700
);
$chartColors : (
    colors: map.values($namedChartColors),
    red: map.get($namedChartColors, persimmon),
    green: map.get($namedChartColors, mint),
    gray: map.get($namedChartColors, greySuit),
    yellow: map.get($namedChartColors, lemon),
    lineNeutral: map.get($namedChartColors, blueChill)
);
