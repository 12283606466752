@import '../variables';
.cb-max-diff-results {
    width: 488px;
    font-size: 14px;
    max-width: 100%;
}

.cb-max-diff-table {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
    margin-bottom: 2em;
    .tr-container {
        &:not(:empty) {
            &:nth-child(2n) {
                background: #f1f4f7;
            }
        }
    }
    .tr {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 5px 0;
        &:nth-child(2n) {
            background: #f1f4f7;
        }
        &.tr-head {
            font-size: 16px;
            min-height: 40px;
        }
    }
    .td {
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        @media #{$mobile} {
            padding: 0 5px;
        }
        &.control-cell {
            width: 120px;
            max-width: 30%;
            .mat-mdc-radio-button {
                margin-bottom: 0;
                /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
                .mat-radio-label-content {
                    display: none;
                }
            }
            @media #{$mobile} {
                flex: 1;
            }
        }
        &.label-cell {
            flex: 1;
            @media #{$mobile} {
                flex: 2;
            }
        }
    }
}

.cb-max-diff-results {
    &.best-worst-item {
        .best-cell {
            order: 1;
        }
        .item-cell {
            order: 3;
        }
        .worst-cell {
            order: 2;
        }
    }
    &.worst-best-item {
        .best-cell {
            order: 2;
        }
        .item-cell {
            order: 3;
        }
        .worst-cell {
            order: 1;
        }
    }
    &.item-best-worst {
        .best-cell {
            order: 2;
        }
        .item-cell {
            order: 1;
        }
        .worst-cell {
            order: 3;
        }
    }
    &.item-worst-best {
        .best-cell {
            order: 3;
        }
        .item-cell {
            order: 1;
        }
        .worst-cell {
            order: 2;
        }
    }
    &.best-item-worst {
        .best-cell {
            order: 1;
        }
        .item-cell {
            order: 2;
        }
        .worst-cell {
            order: 3;
        }
    }
    &.worst-item-best {
        .best-cell {
            order: 3;
        }
        .item-cell {
            order: 2;
        }
        .worst-cell {
            order: 1;
        }
    }
}

.cb-max-diff-sets-count {
    margin-top: 10px;
    text-align: right;
}
