.cb-login-content {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card {
        max-width: 450px;
        margin: 2em auto;
        text-align: center;
    }

    .cb-full-width-input {
        width: 100%;
        margin-top: 15px;
    }

    .cb-50-width-input {
        width: 50%;
        margin-top: 10px;
    }

    .cb-left {
        float: left;
    }

    .cb-right {
        float: right;
    }
    .error-message{
        color: #f00;
    }
}
