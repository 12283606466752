.cb-survey-theme {
    display: flex;
    margin: -34px -60px -48px;
    @media all and (min-width: 1200px) {
        margin: -34px 0 -48px;
        width: 1320px;
        max-width: calc(100vw - 17px);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .cb-survey-theme-search {
        margin: -15px -15px 20px;
        background: #fff;
        padding: 10px 15px;
        box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
            0 0 2px 0 rgba(0, 8, 20, 0.12);
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-infix{
                padding-right: 40px;
            }
    }
    .cb-survey-theme-aside {
        box-sizing: border-box;
        padding: 15px;
        width: 320px;
        background-color: #f1f4f7;
        box-shadow: 0 1px 2px 0 rgba(0, 8, 20, 0.24),
            0 0 1px 0 rgba(0, 8, 20, 0.12);
        min-height: calc(100vh - 204px);

        /*.cb-tabs-group {
            background: transparent;
            box-shadow: none;
            .mat-tab-header {
                box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
                background-color: #ffffff;
                border: 0;
                .mat-tab-labels {
                    justify-content: center;
                }
            }
            .mat-tab-body-content {
                padding: 24px 16px;
                background: transparent;
            }
        }*/
    }
    .cb-survey-theme-content {
        padding: 24px 28px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(100% - 376px);
    }

    .cb-survey-themes-list {
        .cb-survey-theme-item {
            margin-bottom: 20px;
        }
        .cb-survey-theme-settings {
            position: relative;
            display: flex;
            height: 60px;
            border-radius: 2px;
            box-shadow: 0 1px 2px 0 rgba(0, 8, 20, 0.24);
            background-color: #ffffff;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            cursor: pointer;
            .theme-title {
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                margin: 0;
                width: 100%;
                line-height: 60px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0 20px;
                box-sizing: border-box;
            }
            .theme-button-colors {
                flex: 0 0 50%;
            }
            .theme-font-color {
                flex: 0 0 25%;
            }
            &.cb-survey-selected-theme {
                box-shadow: 0 1px 7px 0 rgba(0, 8, 20, 0.5);
            }
        }
    }
}
