.cb-survey-item-wrap {
    &.inner-card {
        display: flex;
        flex: 1;

        .cb-survey-item {
            display: flex;
            flex: 1;
        }
    }

    .cb-surveys-list-no-items {
        font-style: italic;
    }

    .cb-del-icon-spacing {
        margin-right: 20px;
    }

    .full-width {
        width: 100%;
    }
}

.survey-item {
    cursor: pointer;
    font-size: 14px;

    &:hover {
        background-color: #f2f2f2;
    }

    &.header {
        cursor: default;
        background-color: inherit;
        font-weight: bold;
    }

    .survey-title {
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);

        .link {
            word-break: break-word;

            &,
            &:hover {
                line-height: inherit;
                font: inherit;
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .cb-survey-item-actions .mat-mdc-icon-button[disabled] .icon {
        color: inherit;
    }

    &.card {
        display: flex;
        flex-direction: column;
        padding: 20px 12px 0 40px;
        width: 100%;
        min-height: 164px;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
        0 0 2px 0 rgba(0, 8, 20, 0.12);
        font-size: 14px;
        line-height: 1.14;

        .cb-survey-item-info {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .survey-title {
            position: relative;
            margin-bottom: 15px;
            min-height: 48px;
            font-size: 20px;
            line-height: 1.2;
            align-items: flex-start;
        }

        .cb-survey-item-actions {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            height: 43px;
            align-items: center;
            border-top: 2px solid rgba(117, 142, 161, 0.35);
            margin: 0 -12px 0 -40px;
            padding: 0 12px 3px 40px;
        }

        .cb-dashboard-item-actions {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            height: 43px;
            align-items: center;
            border-top: 2px solid rgba(117, 142, 161, 0.35);
            margin: 0 -12px 0 -40px;
            padding: 0 12px 3px 40px;
        }

        .status-component {
            margin: 0;
        }

        .fav-button-wrap {
            position: absolute;
            left: -26px;
            top: 0;
        }

        .date-created {
            margin-bottom: 10px;
        }

        .responses {
            margin: 0 0 13px;
        }

        .status-component {
            display: inline-block;
            padding: 0;
        }
    }

    &.row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 36px;
        padding: 6px 24px;

        .cb-survey-item-info {
            display: flex;
            flex: 1;
            align-items: center;
        }

        .select-survey-checkbox-wrap {
            margin-right: 10px;
        }

        .fav-button-wrap {
            display: inline-block;
            width: 20px;
            margin-right: 7px;
            text-align: center;
        }

        .survey-title {
            flex: 1;
            font-size: 14px;
        }

        .date-created {
            flex: 0 0 180px;
        }
        @media (max-width: 1120px) {
            .date-created {
                display: none;
            }
        }
        .item-created-by {
            flex: 0 0 180px;
            word-break: break-word;
            text-overflow: ellipsis;
            margin-right: 10px;            
        }
        @media (max-width: 900px) {
            .item-created-by {
                display: none;
            }
        }

        .cb-survey-item-actions {
            display: flex;
            align-items: center;
            flex: 0 0 320px;

            &.template {
                flex: 0 0 94px;
            }

            .options-block {
                flex: 1;
            }
        }
        @media (max-width: 650px) {
            .cb-survey-item-actions {
                flex: 1;
            }
        }
        .cb-dashboard-item-actions {
            display: flex;
            align-items: center;
            flex: 0 0 80px;
            justify-content: flex-end;
        }

        .status-component {
            flex: 0.5;
        }
        @media (max-width: 650px) {
            .status-component {
                display: none;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        mat-table {
            background: transparent;
            width: 100%;
        
            mat-header-row {
                min-height: auto;
        
                mat-header-cell {
                    padding: 0;
                    font-size: inherit;
                    font-weight: inherit;
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
    }

    .fav-button-wrap {
        font-size: 0;
        line-height: inherit;
    }

    .is-favorite {
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: #758ea1;

        .fav-icon-size {
            font-size: 16px;
        }
    }

    /*Survey item status*/
    .status-block {
        @include status-block;

        &.sb-active {
            border: 1px solid #4baf50;
            color: #4baf50;
        }

        &.sb-draft {
            border: 1px solid #2196f3;
            color: #2196f3;
        }

        &.sb-archived {
            border: 1px solid #a6a6a6;
            color: #a6a6a6;
        }

        &.sb-scheduled {
            border: 1px solid #ffb502;
            color: #ffb502;
        }

        &.sb-sent {
            border: 1px solid #4baf50;
            color: #4baf50;
        }
    }

    .options-block {
        $icon-height: 32px;
        $icon-width: 32px;
        float: right;

        i {
            padding: 0;
            margin: 0;
            color: #758ea1;

            &:hover {
                background-color: #fff;
            }
        }

        .mat-mdc-icon-button {
            display: inline-block;
            text-decoration: none;
            width: $icon-width;
            height: $icon-height;
            line-height: $icon-height;

            &:hover {
                background-color: #fff;
            }
        }
    }

    .icon-folder {
        margin-right: 15px;
        color: #cedbe0;
    }
}

.wcag-alert {
    border: solid 1px red;
    display: flex;
    align-items: center;
    padding: 10px;
    color: red;
}
