.cb-system-settings {
    .cb-expansion-panel.cb-page-panel {
        margin-bottom: 3em;
    }

    .title--panel {
        font-size: 24px;
    }

    .subsection {
        &__input {
            font-size: 16px;
            width: 22em;
        }

        &__header {
        }

        &__description {
            font-size: 16px;
            padding-left: 5px;
        }
    }

    &.survey {
        .survey-settings-item {
            margin: 10px 10px 10px 0;
            display: block;
        }

        .upload-types {
            display: flex;

            &-list {
                max-height: 200px;
                overflow-y: auto;
            }

            & > * {
                flex: 1 1 0;
            }
        }
    }
}
