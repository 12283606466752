@import 'variables';

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin align-checkbox-top {
    display: block;
    margin: 0 0 10px;
    white-space: normal;
    word-break: break-all;
    padding: 0 8px;
    flex: 0 0 auto;
    box-sizing: border-box;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
        line-height: 18px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        display: flex;
        align-items: flex-start;
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-label {
            white-space: normal;
            word-break: break-all;
            overflow-wrap: break-word;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
        margin: 0 8px 0 0;
    }
}

@mixin status-block {
    display: inline-flex;
    align-items: center;
    min-width: 50px;
    width: auto;
    padding: 0 7px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    font-weight: 400;
    border: 1px solid #2196f3;
    color: #2196f3;

    svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
    }
}

@mixin section-divider {
    margin: 40px -20px 36px;
    height: 2px;
    background-color: rgba(117, 142, 161, 0.35);

    @media #{$mobile} {
        padding-left: -10px;
        padding-right: -10px;
    }
}

@mixin small-matrix {
    table.cb-matrix-table {

        &,
        &>tbody,
        &>thead {

            &,
            &>tr {
                display: block;

                td,
                th {
                    display: block;
                    padding: 5px;
                    height: auto;
                    text-align: left;
                    vertical-align: top;
                }

                &.header-column-group {

                    th.group-cell-title,
                    td.group-cell-title {
                        min-height: 40px;
                        padding: 5px;
                    }
                }
            }
        }

        &>thead {
            &>tr {
                .matrix-group-cell {
                    border: 0;
                }

                td,
                th {
                    display: none;

                    &.group-cell-title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        &>tbody {

            //border-top: 2px solid rgba(117, 142, 161, 0.35);
            &>tr {
                padding: 0 15px;
                border: 0;

                &:nth-child(2n) {
                    background: none;
                }

                &:first-child>td {
                    border: 0;
                }

                &.header-column-group {
                    background: #f1f4f7;
                }

                td {
                    display: flex;
                    width: 100%;
                    box-sizing: border-box;
                    align-items: center;
                    border: 0;

                    &:empty,
                    &.empty-cell {
                        display: none;
                    }

                    &.matrix-group-cell {
                        border: 0;

                        &.empty-cell {
                            display: none;
                        }

                        &>div {
                            width: 100%;
                            max-width: 100%;
                        }
                    }

                    &.question-cell {
                        width: auto;
                        margin: 0 -15px;
                        padding: 10px 15px;
                        border-top: 2px solid rgba(117, 142, 161, 0.35);
                    }

                    .matrix-slider-label {
                        display: block;
                    }
                }
            }
        }
    }
}

@mixin horizontal-rate-scale {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
}

@mixin vertical-rate-scale {
    display: inline-flex;
    max-width: 100%;
    flex-direction: row;

    .cb-scale-rating-text {
        order: 2;
        flex-direction: column;
        margin-left: 10px;
        width: auto;
    }
}

@mixin vertical-na-scale {
    padding: 0 0 70px 0;

    .cb-rating-scale-radio-group .rate-scale-item:last-child {
        width: auto;
        right: 0;
        bottom: -40px;
        position: absolute;
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            width: 125px !important;
            top: 20px;
            left: -20px;
            height: 45px;
            overflow: hidden;
        }
    }
}

@mixin horizontal-scale-radio-group {
    width: 480px;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .cb-rating-scale-radio-group-inner {
        display: inline-flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
    }

    &.has-divider,
    .has-divider {
        position: relative;

        .rate-scale-item {}

        &:before {
            content: '';
            position: absolute;
            top: 10px;
            left: 3px;
            right: 3px;
            height: 2px;
            background-color: #c8c8c8;

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                display: none;
            }
        }
    }

    .mat-mdc-radio-button {
        position: relative;
        padding-bottom: 20px;
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            padding: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
        }
    }

    .radio-divider {
        height: 26px;
        width: 2px;
        background-color: #c8c8c8;
        display: inline-block;
        margin: 0 10px 18px;
    }
}

@mixin vertical-scale-radio-group {
    min-height: 480px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;

    @media #{$mobile} {
        min-height: 300px;
    }

    .cb-rating-scale-radio-group-inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    &.has-divider,
    .has-divider {
        &:before {
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            right: 9px;
            width: 2px;
            background-color: #c8c8c8;
        }
    }

    .rate-scale-item {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }

    .mat-mdc-radio-button {
        position: relative;
        padding-left: 25px;
        display: block;

        &:not(:first-child) {
            margin-top: 30px;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            padding: 0;
            position: absolute;
            left: -5px;
            height: 100%;
            text-align: center;
            line-height: 20px;
            width: 25px;
        }
    }
}

@mixin custom-shadows() {
    .mat-elevation-z2 {
        box-shadow: $shadow-light-2dp;
    }

    .mat-elevation-z4 {
        box-shadow: $shadow-light-4dp;
    }

    .mat-elevation-z8 {
        box-shadow: $shadow-light-8dp;
    }

    .mat-elevation-z16 {
        box-shadow: $shadow-light-16dp;
    }

    .mat-elevation-z24 {
        box-shadow: $shadow-light-24dp;
    }

    .mat-elevation-z32 {
        box-shadow: $shadow-light-32dp;
    }
}

@mixin typo-diplay {
    font-family: GalanoGrotesque-Bold;
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

@mixin typo-headline-1 {
    /* Headline/h1 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

@mixin typo-headline-2 {
    /* Headline/h2 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

@mixin typo-headline-3 {
    /* Headline/h3 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

@mixin typo-headline-4 {
    /* Headline/h4 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

@mixin typo-headline-5 {
    /* Headline/h5 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

@mixin typo-headline-6 {
    /* Headline/h6 */
    font-family: GalanoGrotesque-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@mixin typo-body-1-regular {
    /* Body text/B1 Regular */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@mixin typo-body-1-medium {
    /* Body text/B1 Medium */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

@mixin typo-body-1-bold {
    /* Body text/B1 Semibold */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
@mixin typo-body-2-regular {
    /* Body text/B2 Regular */
    font-family: "Galano Grotesque", serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}
@mixin typo-body-2-medium {
    /* Body text/B2 Medium */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
@mixin typo-body-2-bold {
    /* Body text/B2 Semibold */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
@mixin typo-parag-1-regular {
    /* Paragraphs/P1 Regular */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
@mixin typo-parag-1-medium {
    /* Paragraphs/P1 Medium */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
@mixin typo-parag-1-bold {
    /* Paragraphs/P1 Semibold */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}
@mixin typo-parag-2-regular {
    /* Paragraphs/P2 Regular */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
@mixin typo-parag-2-medium {
    /* Paragraphs/P2 Medium */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
@mixin typo-parag-2-bold {
    /* Paragraphs/P2 Semibold */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
@mixin typo-caption-1-regular {
    /* Caption/C1 Regular */
    font-family: "Galano Grotesque";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
@mixin typo-caption-1-medium {
    /* Caption/C1 Medium */
    font-family: "Galano Grotesque";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
@mixin typo-caption-1-bold {
    /* Caption/C1 Semibold */
    font-family: "Galano Grotesque";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
@mixin typo-caption-2-regular {
    /* Caption/C2 Regular */
    font-family: "Galano Grotesque";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
@mixin typo-caption-2-medium {
    /* Caption/C2 Medium */
    font-family: "Galano Grotesque";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
@mixin typo-caption-2-bold {
    /* Caption/C2 Semibold */
    font-family: "Galano Grotesque";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}
@mixin typo-overline-regular {
    /* Overline/Overline Regular */
    font-family: "Galano Grotesque";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.3px;
    text-transform: uppercase;
}
@mixin typo-overline-medium {
    /* Overline/Overline Medium */
    font-family: "Galano Grotesque";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.3px;
    text-transform: uppercase;
}
@mixin typo-overline-bold {
    /* Overline/Overline Semibold */
    font-family: "Galano Grotesque";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 145.455% */
    letter-spacing: 0.3px;
    text-transform: uppercase;
}
@mixin typo-data-table-header{
    /* Headline/Data table title */
    font-family: "Galano Grotesque";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

}
@mixin typo-button-giant{
    /* Button/Large */
    font-family: "Galano Grotesque";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
@mixin typo-button-large{
    /* Button/Large */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
@mixin typo-button-medium{
    /* Button/Medium */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
@mixin typo-button-small{
    /* Button/Small */
    font-family: "Galano Grotesque";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
@mixin typo-form-completed-medium{
    /* Form/Completed/Medium */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
@mixin typo-form-completed-small{
    /* Form/Completed/Small */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
@mixin typo-form-completed-xsmall{
    /* Form/Completed/X-Small */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
@mixin typo-form-placeholder-medium{
    /* Placeholder/Medium */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
@mixin typo-form-placeholder-small{
    /* Placeholder/Small */
    font-family: "Galano Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
@mixin typo-form-placeholder-xsmall{
    /* Placeholder/X-Small */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
@mixin typo-form-label-medium{
    /* Form/Label/Medium */
    font-family: "Galano Grotesque";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
@mixin typo-form-label-small{
    /* Form/Label/Small */
    font-family: "Galano Grotesque";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
@mixin survey-list-page-boders{
    border-radius: 10px;
    border: 1.4px solid $backgrounds-light-400;
    box-shadow: $shadow-light-4dp !important;
}
@mixin mat-icon-size($size: 24px) {
    width: $size;
    height: $size;
    font-size: $size;
}
@mixin mat-input-border-radius($radius: 10px) {
    &-end {
        border-radius: 0 $radius $radius 0 !important;
    }

    &-start {
        border-radius: $radius 0 0 $radius !important;
        flex-grow: 1;
    }
}
