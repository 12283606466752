.loader-overlay-container {
    float: left;
    position: relative;
    left: 45%;
    margin-top: 10%;
    box-sizing: border-box;
    &.loader-overlay-block {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        left: 0;
        top: 0;
        margin: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loader-overlay {
        position: absolute;
    }
    .loader-overlay mat-spinner {
        z-index: 1000;
    }
}
