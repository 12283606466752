@use 'sass:map';
@import 'helpers';
@import '../variables';

.cb-card-base {
    background: #fff;
    box-shadow: $shadow-light-4dp;
    border-radius: 10px;
    padding: 16px 24px;
    margin-bottom: 12px;
    
    &:last-child {
        margin-bottom: 0;
    }    
}
