.cb-permissions {
    margin-top: 20px;
    font-size: 16px;
}

.cb-permissions-user-item { // moved here for drag-and-drop preview
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: 24px;
    padding: 8px 12px;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
    0 0 2px 0 rgba(0, 8, 20, 0.12);
    margin-bottom: 4px;
    &.cdk-drag-preview {
        z-index: 999999 !important;
    }
}

.cb-permissions-section-container {
    display: flex;
    flex-direction: row;
    margin: 0 -10px 20px;

    .cb-permissions-actions {
        line-height: 0;
        opacity: 0.3;
        pointer-events: none;
        &.visible {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .cb-permissions-title {
        margin: 0 0 15px;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        color: rgba(0, 0, 0, 0.87);
    }

    .cb-permissions-section {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px;
        margin: 0 10px;
        height: 344px;
        border-radius: 2px;
        background: #f1f4f7;
    }

    .cb-permissions-section-inner {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
    }

    .cb-permissions-section-search {
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        form {
            display: block;
            width: 100%;
        }
        .mat-mdc-form-field {
            display: block;
            width: 100%;
        }
    }

    .cb-permissions-section-header {
        display: flex;
        margin-bottom: 12px;
        .cb-permissions-section-title {
            flex: 1;
            margin: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            color: #212121;
        }
    }

    .cb-permissions-users-container {
        flex: 1;
        overflow-y: auto;
        margin: 0 -10px;
        padding: 0 10px;

        .mat-accordion {
            margin: 0;

            .cb-expansion-panel {
                margin-bottom: 4px;
                .mat-expansion-panel-header {
                    padding: 0 5px 0 35px;
                    position: relative;
                    .icon-collapse-wrap {
                        position: absolute;
                        top: 10px;
                        left: 8px;
                    }
                }

                .mat-expansion-panel-body {
                    padding-left: 35px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .cb-permissions-users-list {
        width: 100%;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        line-height: 24px;

        .user-item-title {
            display: flex;
            flex: 1;
            align-items: flex-start;
            margin: 0;
            font-weight: normal;
            font-size: inherit;
            .icon {
                margin-right: 4px;
                color: inherit;
            }
        }

        .cb-permissions-status {
            display: none;
            margin: 0 10px;
        }

        .status-block {
            @include status-block;
            &.sb-default {
                border: 1px solid #4baf50;
                color: #4baf50;
            }
        }
    }

    .get-more {
        padding: 10px 0 0;
        .get-more-button {
            width: 100%;
        }
    }
}
