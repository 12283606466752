.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block;
}

.d-inline {
    display: inline;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.box-elevation,
%box-elevation {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 2px 5.6px 0.4px rgba(0, 0, 0, 0.12);
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.p-0 {
    padding: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
