.cb-signup-content {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card {
        max-width: 400px;
        margin: 2em auto;
        text-align: center;
    }

    .full-width-input {
        width: 100%;
        margin-top: 10px;
    }
}
