@use 'sass:map';
@import 'helpers';
@import '../variables';
@import '../functions';
$primary-500: map-get($brand-primary-palette, 500);
$accent-500: map-get($brand-secondary-palette, 500);
$warn-500: map-get($system-danger-palette, 500);
$warning-500: map-get($system-warning-palette, 500);
$success-500: map-get($system-success-palette, 500);
$warning-contrast-500: map-get(map-get($system-warning-palette, contrast), 500);
$success-contrast-500: map-get(map-get($system-success-palette, contrast), 500);

//colors
$color_orange: #ff5336;
$color_limed_spruce: #3a4d54;
$white: #fff;

/*Rewrite default stiles*/
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn,
.mat-mdc-raised-button.mat-warn {
    background-color: $color_orange;
    box-shadow: 0 1px 3px 0 rgba(204, 83, 61, 0.24),
        0 0 2px 0 rgba(204, 83, 61, 0.12);
    color: $white;
}

.mat-mdc-outlined-button{
    border-color: $basic-alpha-24!important;
    &.mat-primary{
        border-color: $primary-500!important;
    }
    &.mat-accent{
        border-color: $accent-500!important;
    }
    &.mat-warn{
        border-color: $warn-500!important;
    }
    &.mat-warning{
        color: $warning-500;
        border-color: $warning-500!important;
        mat-icon {
            color: $warning-500;
        }
    }
    &.mat-success{
        color: $success-500;
        border-color: $success-500!important;
    }
    @each $color in $pallete-names {
        @each $shade in $pallete-colors {
            &.mat-#{$color}-#{$shade} {
                color: mat-color-selector($color, $shade);
                border-color: mat-color-selector($color, $shade)!important;
            }
        }
    }

}
.mat-mdc-raised-button,.mat-mdc-unelevated-button,.mat-mdc-fab,.mat-mdc-mini-fab{
    &.mat-warning{
        background-color: $warning-500;
        color: $warning-contrast-500;

    }
    &.mat-success{
        background-color: $success-500;
        color: $success-contrast-500;
    }
}
.mat-mdc-icon-button{
    @each $color in $pallete-names {
        @each $shade in $pallete-colors {
            &.mat-#{$color}-#{$shade} {
                mat-icon{
                    color: mat-color-selector($color, $shade);
                }
            }
        }
    }
}

cb-button {
    &.white-bg{
        button{
            background-color: $backgrounds-light-100;
        }
    }
    &.large-menu{
        button{
            /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            .mat-button-wrapper{
                >div>span{
                    width: 129px;
                    text-align: left;
                }
            }
        }

    }
    &.icon-color{
        &-primary{
            button{
                mat-icon.button-icon {
                    color: $primary-500 !important;
                };
            }
        }
        &-accent{
            button{
                mat-icon.button-icon {
                    color: $accent-500 !important;
                };
            }
        }
        &-warn{
            button{
                mat-icon.button-icon {
                    color: $warn-500 !important;
                };
            }
        }
        &-success{
            button{
                mat-icon.button-icon {
                    color: $success-500 !important;
                };
            }
        }
        &-warning{
            button{
                mat-icon.button-icon {
                    color: $warning-500;
                };
            }
        }
    }

}

.cb-button-default {
    &,
    &.mat-mdc-button {
        @extend %button-default-style;
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-wrapper {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &.cb-button-menu-white {
        height: 36px;
        line-height: 36px;
        background: #fff;
        font-size: 14px;
        font-weight: bold;
        min-width: 136px;
        color: #758ea1;
        box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
            0 0 2px 0 rgba(0, 8, 20, 0.12);
    }
    &.button-add-column-matrix {
        width: 220px;
        .icon {
            margin-right: 8px;
            color: inherit;
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
    opacity: 0 !important;
}

%button-default-style {
    min-width: 117px;
    height: 40px;
    border-radius: 2px;
    font-weight: 400;
    background-color: #f1f4f7;
    color: #576a78;
    font-size: 16px;
    line-height: 40px;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #e4eaef !important;
    }
    &:focus {
        background-color: #f1f4f7 !important;
    }
    &:active {
        background-color: #d7e0e8 !important;
    }

    &[disabled],
    &[disabled][disabled] {
        color: #576a78 !important;
        background-color: #f1f4f7 !important;
        opacity: 0.7;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        display: inline-flex;
        align-items: center;
    }

    .icon {
        display: inline-block;
        margin-right: 8px;
    }
}

%button-link-style {
    @extend %button-default-style;
    box-shadow: none !important;
    background: none !important;
    font-weight: bold;
    font-size: 14px;
    color: #758ea1;
    letter-spacing: 0.4px;
}

%button-basic-style {
    @extend %button-default-style;
    background: #fff !important;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12) !important;
    font-weight: bold;
    font-size: 14px;
    color: #758ea1;
    letter-spacing: 0.4px;
}

%button-cancel-style {
    color: #758ea1 !important;
    cursor: pointer;
    font-weight: bold;
    &.btn-big-size {
        height: 48px;
        padding: 0 24px;
        min-width: 131px;
    }
    &:hover,
    &:focus {
        background: #f1f4f7;
    }
}

%button-submit-style {
    font-weight: bold;
    background-color: $color_orange !important;
    box-shadow: 0 1px 3px 0 rgba(204, 83, 61, 0.24),
        0 0 2px 0 rgba(204, 83, 61, 0.12);
    color: $white !important;

    &:hover {
        background-color: #ed614a !important;
        box-shadow: 0 1px 3px 0 rgba(204, 83, 61, 0.24),
            0 0 2px 0 rgba(204, 83, 61, 0.12);
    }

    &:focus {
        background-color: #e16f60 !important;
        box-shadow: 0 4px 10px 0 rgba(204, 83, 61, 0.24),
            0 0 4px 0 rgba(204, 83, 61, 0.12);
    }

    &[disabled] {
        opacity: 0.65;
        cursor: not-allowed;
        &,
        &:hover,
        &:focus {
            background-color: $color_orange !important;
        }
    }

    &.btn-big-size {
        height: 48px;
        padding: 0 24px;
        min-width: 131px;
    }
}

%styled-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px -36px -24px;
    padding: 0 24px;
    height: 64px;
    border-radius: 2px;
    background-color: #fafcfc;
}

.d-flex .cb-button-link {
    display: flex;
    align-items: center;
}

.cb-button-link {
    &,
    &.mat-mdc-button {
        display: inline-block;
        padding: 0 8px;
        font-weight: 700;
        color: #758ea1;
        height: 32px;
        line-height: 32px;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        border: none;
        background: transparent;
        font-family: inherit;
        letter-spacing: 0.4px;
        font-size: 14px;

        &:hover {
            background-color: #f1f4f7;
        }
        &:focus {
            background-color: #f1f4f7;
        }
        &:active {
            background-color: rgba(117, 142, 161, 0.35);
        }

        &.btn-big-size {
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        display: inline-flex;
        align-items: center;
    }

    .icon {
        display: inline-block;
        margin-right: 8px;
    }
}

.mat-mdc-icon-button.is-menu-button {
    color: #576a78;
    width: auto;
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-ripple {
        opacity: 0;
    }
    &.menu-opened {
        .caret-icon {
            .icon-down {
                display: none;
            }
            .icon-up {
                display: inline-block;
            }
        }
    }
    .caret-icon {
        color: inherit;
        .icon-up {
            display: none;
        }
    }
}

.mat-mdc-icon-button.small-icon-button {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.cb-icon-button {
    &,
    &.mat-mdc-icon-button {
        width: 24px;
        height: 24px;
        line-height: 24px;
        &.active {
            color: #ff5336;
            .icon {
                color: inherit;
            }
        }
    }
    &.wcag-btn {
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 999;
        color: #000;
    }
}
.buttons-container {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
    &.buttons-right {
        justify-content: flex-end;
        button {
            margin: 0 5px;
        }
    }
    &.buttons-center {
        justify-content: center;
        button {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

/*.dialog-content .buttons-container,*/
%dialog-buttons-container,
.tabs-buttons-container {
    @extend %styled-buttons-container;

    &.has-content-after {
        margin-bottom: 0;
    }
}

%dialog-buttons-container,
.tabs-buttons-container,
.buttons-container {
    button {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: #758ea1;

        &.btn-cancel {
            @extend %button-cancel-style;
        }

        &.btn-submit {
            @extend %button-submit-style;
            margin-left: 10px;
        }
    }
}

.btn-cancel {
    &,
    &.mat-mdc-button,
    &.mat-mdc-raised-button {
        @extend %button-cancel-style;
    }
}

.btn-submit {
    &,
    &.mat-mdc-button,
    &.mat-mdc-raised-button {
        @extend %button-submit-style;
        margin-left: 10px;
    }
}

.form-section--social {
    .form-section-content {
        display: block;
        padding: 24px 24px 40px;
        button {
            display: block;
            margin-bottom: 16px;
            width: 100%;
        }
    }

}

