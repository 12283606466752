.mat-toolbar-row.default-height {
    height: auto;
}
.cb-toolbar-breadcrumb-row {
    &,
    &.mat-toolbar-row {
        font-size: 14px;
        color: #576a78;
    }

    .breadcrumb-link {
        font-weight: bold;
        color: inherit;
        text-decoration: underline;
    }

    .cb-toolbar-breadcrumb-item {
        display: inline-flex;
        align-items: center;
    }

    .breadcrumb-delimiter {
        display: inline-block;
        line-height: 0;
        .icon {
            color: inherit;
        }
    }
}

.cb-toolbar-sub-menu {
    display: flex;
    align-items: center;

    .cb-toolbar-menu-item {
        &:not(:first-child) {
            margin-left: 48px;
        }

        .menu-link {
            height: 48px !important;
        }
    }

    .icon {
        color: inherit;
        margin-right: 8px;
    }
}
