.cb-tabs-group {
    background-color: #ffffff;

    .mat-mdc-tab-header {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-mdc-tab-labels {
            padding: 0 10px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-mdc-tab-label-container {
            position: relative;
            height: 56px;
            min-width: auto;
            padding: 0;
            margin: 0 15px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.4px;
            color: #758ea1;
            text-transform: uppercase;
            opacity: 1;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: #758ea1;
                opacity: 0;
            }
            &:focus {
                background: none;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            &:not(.mat-tab-label-active):hover {
                //&::after {
                //    opacity: 1;
                //}
            }
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                    .mdc-tab--active {
                        // color: #ff5336;
                        color: red !important;
                    }
                }
            }

            .icon {
                color: inherit;
                margin-right: 8px;
            }
        }
    }
    &.mat-mdc-tab-group.mat-primary .mat-ink-bar {
        background-color: #ff5336;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-body-content {
        opacity: 1;
        background: #fff;
        height: calc(100% - 48px);
        padding: 24px 36px;
    }
}
