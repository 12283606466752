@import '../variables';
.cb-toolbar-space {
    flex: 1 1 auto;
}

.inline {
    float: left;
}

.survey-info {
    display: flex;
    align-items: center;
    height: 50px;
}

.survey-title,
.survey-description {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 20px;
    .mat-mdc-form-field {
        max-width: 676px;
        display: inline-block;
    }

    .survey-name {
        color: rgba(0, 0, 0, 0.87);
        font-size: 24px;
        &.disabled,
        &.edit-mode {
            width: 100%;
            border: 0;
            font-size: 24px;
        }
    }
    .survey-description_text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        &.disabled,
        &.edit-mode {
            width: 100%;
            border: 0;
        }
    }
}

.survey-editor-toolbar .survey-title {
    .mat-mdc-form-field {
        padding-left: 28px;
    }

    .fav-button-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #758ea1;
        line-height: 0;
        margin-top: -5px;
        z-index: 1;
    }
    
    .is-favorite {
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: #758ea1;
    
        .fav-icon-size {
            font-size: 16px;
        }
    }
}



.disabled {
    background-color: transparent;
    border: 0;
}

.edit-mode {
    background-color: #fff;
    border: 1px solid #ccc;
}

.btn-narrow {
    min-width: 55px;
    padding: 0 5px;
}

.mat-toolbar {
    padding-right: 60px;
    padding-left: 60px;
    &.mat-basic {
        background: #fff;
        box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17),
            0 0 2px 0 rgba(0, 8, 20, 0.1);
    }
}

.edit-navigation {
    &,
    &.mat-toolbar-multiple-rows {
        background: transparent;
        border-bottom: 1px solid rgba(117,142,161,0.35);
        box-shadow: none;
        margin: -40px -60px 34px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1320px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px;
        width: 100%;
        position: relative;
        z-index: 3;
    }

    .cb-toolbar-menu {
        flex: 1;
        display: flex;
        margin: 0;
        .cb-toolbar-menu-item {
            margin-left: 25px;
            &:first-child {
                margin-left: 0;
            }
            cb-icon-with-caret {
                height: 100%;
                .mat-mdc-icon-button {
                    height: 100%;
                }
            }
            button {
                .icon {
                    margin-right: 8px;
                }
            }
        }
    }

    .menu-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        padding: 0;
        color: #758ea1;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        text-decoration: none;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: #758ea1;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        &.active {
            color: #ff5336;
            &::after {
                background: #ff5336;
                opacity: 1;
            }
        }

        .mat-icon,
        .icon {
            margin-right: 8px;
            color: inherit;
        }
    }

    .mat-mdc-icon-button {
        min-width: 24px;
        width: auto;
        height: 32px;
        line-height: 0;
        color: #758ea1;
    }

    .cb-toolbar-menu-item-with-caret {
        .icon-with-caret-button {
            .menu-link {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: #758ea1;
                opacity: 0;
            }
    
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }

        &.active {
            .icon-with-caret-button {
                color: #ff5336;

                .menu-link, .icon {
                    color: #ff5336;
                }

                .caret-icon {
                    color: #ff5336 !important;
                }

                &::after {
                    background: #ff5336;
                    opacity: 1;
                }
            }
        }
    }
}

.survey-edit-nav-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cb-toolbar-actions {
        .cb-toolbar-action-item {
            display: inline-block;
            margin: 0 4px;
        }
    }
}
.created-by {
    font-size: 11px;
    color: #758ea1;
    padding: 0;
    line-height: 1;
}
.edit-survey-info {
    display: flex;
    flex-direction: column;
    justify-items: right;
    margin-right: 10px;
    text-align: right;
}
div.survey-toolbar{
    cb-icon-with-caret{
        mat-icon{
            color: $text-color-grey;
            @include mat-icon-size(20px);
        }
        a{
            color: $text-color-dark;
            @include typo-body-1-regular;
            padding-left: 4px;
        }
    }
}
