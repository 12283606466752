@import 'variables';
@import 'mixins';

body {
    margin: 0;
    padding: 0;
    background: $backgrounds-light-200;
    font-family: $font-family-sans-serif;
}

.mdc-icon-button__ripple , .mat-mdc-button-persistent-ripple {
    display: none !important;
}

// in global stylesheet
/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-input-container.app-input-no-underline .mat-input-underline {
    display: none;
}

.full-width-control {
    width: 100%;
    margin-top: 10px;
}

.full-width {
    width: 100%;
}

.mat-expansion-panel-header {
    height: 48px;
}

.clearfix::after {
    content: '';
    display: table;
    clear: both;
}

.my-flat-button.mat-mdc-raised-button {
    box-shadow: none;
}

.shadowed-section {
    padding: 15px 20px;
    margin-bottom: 24px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);

    &.draggable-item {
        padding-top: 20px;
    }
}

.draggable-item {
    position: relative;
    padding-top: 30px;

    .icon-move {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
    }
}

.cb-container {
    width: 1320px;
    max-width: 100%;
    margin: 0 auto;
}

.cb-container-inner {
    &,
    &.mat-toolbar-row {
        max-width: 1320px;
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }
}

.get-more {
    width: 100%;
    text-align: center;
    padding: 15px 0;
}

/*
.mat-toolbar.mat-basic {
    @media #{$tablet-down} {
        display: none;
    }
}
*/

.main-page-title {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 0;
}

.cb-section-divider {
    @include section-divider;
}

.required:before {
    content: '*';
    color: red;
    margin-right: 80px;
}

.required-error {
    color: red;
}

.question-item-number {
    float: left;
    margin-right: 5px;
    margin-left: -40px;
    text-align: right;
    min-width: 33px;

    @media #{$mobile} {
        min-width: 0;
        margin-left: -20px;
    }
}

.cb-success-alert {
    background: #c3f7d1;
    padding: 10px;
    margin-bottom: 15px;
}

.cb-error-alert {
    background: #ee9c9c;
    padding: 10px;
    margin-bottom: 15px;
}

.cb-warning-alert {
    padding: 10px;
    background-color: #fde440;
    opacity: 0.75;
    border: 1px dashed #aaa;
    margin-bottom: 15px;
}

re-captcha {
    @media all and (max-width: 400px) {
        display: block;
        transform: scale(0.8);
        transform-origin: 0 0;
    }
}

img {
    max-width: 100%;
    height: auto;
}

body .mat-mdc-snack-bar-container {
    &.none {
        border-radius: 2px;
        box-sizing: border-box;
        display: block;
        margin: 24px;
        max-width: 568px;
        min-width: 288px;
        padding: 10px 35px;
        text-align: center;
        min-height: 42px;
        position: relative;
        white-space: pre-wrap;
        &.error {
            .mdc-snackbar__surface {
                background: red !important;
                box-shadow: none;
            }
        }

        &.info {
            .mdc-snackbar__surface {
                background: green !important;
                box-shadow: none;
            }
        }

        &.warning {
            .mdc-snackbar__surface {
                background: orange !important;
                box-shadow: none;
            }
        }
    }
}

// IE 10 specifc styles
.currently-using-ie-10 {
    .cdk-global-overlay-wrapper {
        display: block;
    }

    .cdk-overlay-pane {
        width: 350px;
        height: 250px;
        margin: auto;
        margin-top: 150px;
    }
}

.tox-notifications-container { // hide tinymce license notification for old devices
    display: none;
}

.nps-chart-positioning-fix {
    svg {
        overflow: visible !important;
        transform: translate(10px, 0);
    }

    .legend {
        .custom-legend {
            margin-left: 10px;
        }
    }

    .c3-axis.c3-axis-y {
        overflow: visible;

        .domain {
            display: none;
        }

        .tick {
            z-index: 99999;
            overflow: visible;
        }
    }
}

@media print {
    @page {
        size: auto;
        margin-top: 10mm;
    }

    .cb-app-content {
        padding: 0;
    }
}

.tiny_image_clearfix[style="float: left;"] {
    margin-left: 0;
    margin-right: auto;
    display: block;
    float: none !important;
}

.tiny_image_clearfix[style="float: right;"] {
    margin-left: auto;
    margin-right: 0;
    display: block;
    float: none !important;
}

[hidden] {
    display: none;
}


/* RTL STYLES */
.ar-AR,
.dv-DV,
.ku-KU,
.fa-FA,
.ur-UR,
.he-HE {
    direction: rtl;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    cb-response-summary-item-preview > div,
    .mat-mdc-dialog-title,
    .mat-form-field-label,
    .mat-select-value,
    .cb-surveys-table th,
    .mat-mdc-form-field-error,
    .login-title,
    .mat-input-element,
    .mat-option {
        text-align: right !important;
    }

    .cb-save-survey__link {
        display: inline;
    }

    .status-block .icon {
        margin-left: 5px;
    }

    .cb-surveys-table tbody .cell.actions-cell {
        text-align: left;
    }

    .cb-survey-main .buttons-container {
        flex-direction: row-reverse;

        .btn-submit {
            margin: 0 !important;
        }
    }
}

.cdk-drag-placeholder {
    opacity: 0;
}
/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.text-uppercase {
    text-transform: uppercase;
}

.cb-survey-manage-invitation {
    .form-section-wrapper {
        .form-section-row {
            .form-section--qr-code,
            .form-section--embed {
                button {
                    position: absolute;
                    right: 20px;
                    bottom: 24px;
                    width: 40px;
                    height: 40px;
                    background-color: #F1F4F7;
                    svg {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

    }

}

.mat-mdc-table mat-row, .mat-mdc-table mat-mdc-header-row, .mat-table mat-footer-row {
    border-bottom-width: 0;
}

.si-responses-bar,
.survey-mi-table {
    table {
        border-spacing: 0;
        border-collapse: collapse;
        width: 100%;
        td {
            width: 16.66666666% !important;
            .bar100-legend-square.bar100-lead-transparent {
                display: none;
            }
        }
    }

}

.edit-navigation {
    &.edit-invitation {
        &.mat-toolbar {
            background-color: #FAFCFC;
            border-bottom: none;
            box-shadow: none;
            padding-bottom: 48px;
            position: relative;
            z-index: 3;
            .cb-toolbar-breadcrumb-row,
            .survey-edit-nav-row {
                padding: 0;
            }
            .survey-edit-nav-row {
                border-bottom: 1px solid rgba(117, 142, 161, 0.35);
            }
            .cb-toolbar-breadcrumb-item {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.inner-page-content {
    position: relative;
    z-index: 3;
}

.url-switch-field {
    align-items: center;
    display: flex;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    min-height: 76px;
    overflow: hidden;
    padding: 0 24px 19px;

    .url-switch-labels {
        align-items: center;
        display: flex;
        margin-right: 48px;

        span {
            background-color: #F1F4F7;
            display: inline-block;
            border-radius: 2px;
            color: #758EA1;
            font-size: 14px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 20px;
            margin-right: -2px;
            transition: all 0.1s ease-in-out;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                background-color: #C5D0D8;
                color: #212121;
                z-index: 1;
            }
        }

    }

    .url-switch-content{
        .survey-title {
            .cb-url {
                color: #212121;
                font-weight: normal;
                .cb-action-item {
                    position: absolute;
                    right: 0;
                    bottom: 6px;
                    &:first-of-type {
                        right: 28px;
                    }
                }
            }
            &.survey-title-autogenerated {
                .cb-url {
                    .cb-action-item {
                        bottom: -12px;
                    }
                }
            }
        }
    }
    /* IE 11 hack */
    &:after {
        content:'';
        min-height:inherit;
        font-size:0;
    }
}

cb-survey-toolbar {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field:not(.mat-form-field-invalid) .mat-form-field-underline {
        background-color: transparent;
    }
}
