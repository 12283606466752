@import '../variables';
@import '../mixins';

$formFieldBaseColor: #576a78;
$formFieldBgColor: #758ea1;
$formFieldBorderColor: #758ea1;
$formButtonColorDefault: #758ea1;
$formFieldPreviewBgColor: #2567c2;
$formFieldPreviewBorderColor: #2567c2;

.full-width-input {
    width: 100%;
    margin-top: 10px;
}

.field-full-width {
    &,
    &.mat-mdc-form-field {
        width: 100%;
    }
}

.field-95-width {
    &,
    &.mat-mdc-form-field {
        width: 95%;
    }
}

.cb-change-type-section {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.25;

    cb-item-type {
        .icon {
            color: #212121;
            margin-right: 8px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.cb-section-divider {
    &.form-section-divider {
        margin: 20px 0;
    }
}

.form-section {
    & + .form-section,
    &.border-top {
        padding-top: 20px;
        border-top: 2px solid rgba(117, 142, 161, 0.35);
        margin-top: 20px;
    }

    .cb-column-width-control {
    }

    .column-width-input, .contact-form-width-input {
        width: 50px;
        margin: 0 5px !important;
    }

    .choices-item {
        .form-fields-group {
            margin: 0 -6px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .mat-mdc-form-field,
            .cb-form-field {
                margin: 0 6px;
            }
        }
    }

    .mat-mdc-form-field {
        margin-bottom: -10px;
    }
}

.choices-item {
    &.choices-item-header {
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 16px;
    }
}

.multiple-options-item {
    &.choices-item-header {
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 16px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-underline {
            bottom: 0;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-infix {
            padding: 0.3em 0;
            border-top: 0;
        }
    }

    &.shadowed-section.draggable-item {
        padding-top: 16px;
        padding-bottom: 8px;
        margin-bottom: 10px;
    }

    .choices-checkbox-group {
        display: flex;
        align-items: center;

        .cell {
            padding: 0 10px;
            margin: 0;
            box-sizing: border-box;
        }

        .id-cell {
            width: 30px;
        }

        .checkbox-cell {
            width: 80px;
            text-align: center;
        }

        .text-cell,
        .image-cell {
            flex: 4;
        }

        .merge-cell {
            width: 36px;
            text-align: center;
        }
        .html-cell{
            width: 70px;
        }
        .alias-cell {
            flex: 2;
        }

        .points-cell {
            width: 120px;
        }

        .actions-cell {
            width: 30px;
            text-align: right;
        }
    }
}

.form-fields-group {
    margin: 0 -10px;
    display: flex;
    align-items: baseline;

    .mat-mdc-form-field,
    .cb-form-field {
        margin: 0 10px;

        &.width-auto {
        }

        .mat-mdc-form-field {
            margin: 0;
        }
    }

    .input-inside-text {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-wrapper {
            padding-bottom: 0;
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-underline {
                bottom: 0;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-infix {
                padding: 0.3em 0;
                border-top: 0;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
            .mat-input-suffix {
                bottom: 2px;
            }
        }
    }

    &.three-items-per-row {
        flex-wrap: wrap;

        .control-field {
            margin: 0 10px;
            width: 30%;

            &.two-controls {
                width: 60%;
            }

            .mat-mdc-form-field {
                margin: 0;
            }
        }
    }

    &.four-items-per-row {
        flex-wrap: wrap;

        .control-field {
            margin: 0 10px;
            width: 25%;

            .mat-mdc-form-field {
                margin: 0;
            }
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-input-suffix {
    position: absolute;
    right: 0;
    bottom: 22px;
}

.mat-datepicker-toggle {
    .mat-mdc-icon-button {
        width: 24px;
        height: 24px;
        line-height: 0;

        .mat-icon {
            width: 24px;
            height: 24px;
            color: #758ea1;
        }
    }
}

.form-group-position {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.position-control-group {
    margin: 0 20px 0 0;

    .group-title {
        margin: 0 0 5px;
        font-size: 16px;
        font-weight: 400;
    }

    .mat-mdc-icon-button {
        background: none;
        width: 24px;
        height: 24px;
        line-height: 0;
        border-radius: 0;
        margin-right: 8px;

        &.selected {
            background: #cfd8de;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-ripple {
            border-radius: 0;
        }
    }
}

.form-section-title {
    margin: 0 0 15px;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}

.form-section-title-wrapper {
    display: flex;
    justify-content: space-between;
}

.form-section-subheader {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
}

.form-promo-section {
    margin: 30px 0;
    padding: 20px;
    border: 1px solid rgba(117, 142, 161, 0.35);

    .form-promo-section-title {
        margin-top: -34px;
        min-height: 24px;
        line-height: 24px;
        font-size: 18px;

        & > span {
            display: inline-block;
            background: #fff;
            padding: 0 10px;
        }
    }
}

.form-section-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px 0;
    border-top: 2px solid rgba(117, 142, 161, 0.35);
    border-bottom: 2px solid rgba(117, 142, 161, 0.35);

    .form-section {
        border: none;
        margin: 0;
        padding-top: 0;

        &--embed {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            textarea.mat-mdc-input-element {
                height: 130px;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-infix {
                padding-top: 0;
            }
        }
    }

    @media #{$tablet-down} {
        .form-section {
            &--social {
                cb-survey-social-media-widgets {
                    display: flex;
                    flex-direction: column;

                    button {
                        margin-bottom: 4px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @media #{$mobile} {
        flex-direction: column;
        .form-section {
            & + .form-section {
                padding-top: 20px;
                border-top: 2px solid rgba(117, 142, 161, 0.35);
                margin-top: 20px;
            }
        }

        .form-section {
            &--embed {
                mat-mdc-form-field {
                    width: 100% !important;

                    textarea {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.mat-mdc-form-field {
    &[floatLabel='never']
    &:not([appearance='outline']) {
        margin: -10px 0;
        line-height: 24px;
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-subscript-wrapper {
            font-size: 11px;
            line-height: 11px;
            margin-top: 2px;
        }
    }

    &:not(.mat-mdc-form-field-invalid) {
        color: #212121;
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-label {
            color: $formFieldBaseColor;
        }

        .mat-mdc-select {
            /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-mdc-select-trigger {
                transform: translateY(3px);
            }
            /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-mdc-select-value {
                font-weight: 600;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-underline {
            background-color: $formFieldBgColor;
        }
    }

    textarea {
        font-family: $font-family-sans-serif;
        font-size: 16px;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field .mat-mdc-form-field-label {
    color: $formFieldBaseColor;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-value .mat-mdc-select-placeholder {
    color: transparent;
}

/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-mdc-form-field-type-mat-mdc-select .mat-input-placeholder-wrapper {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-label {
        color: $formFieldBaseColor;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-underline {
    background-color: $formFieldBgColor;
}

.mat-mdc-select {
    .mat-mdc-optgroup-label {
        font-weight: 600;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-select-content {
    .mat-mdc-option {
        /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
        &,
        &.mat-mdc-selected:not(.mat-option-disabled) {
            color: $formFieldBaseColor;
        }
    }
}
.mat-mdc-select-panel.date-range-select{
    max-height: 300px;
}
.mat-mdc-select-panel-wrap .mat-mdc-select-panel .mat-mdc-option {
    height: auto;
    white-space: normal;
    line-height: 1.4;
    padding: 10px 15px;
}

/*Slider settings*/
.mat-mdc-slider {
    &.mat-accent {
        /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
        .mat-slider-thumb,
        .mat-slider-thumb-label,
        .mat-slider-track-fill {
            background-color: $formButtonColorDefault;
            border-color: $formButtonColorDefault;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    .mat-slider-track-background {
        background-color: $formButtonColorDefault;
        opacity: 0.35;
    }
}

.mat-mdc-radio-button {
    &.radio-button-block{
        margin: 10px;
        display: block;
    }
    &.mat-accent {
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-checked {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle {
                border-color: $formButtonColorDefault;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-inner-circle {
                background-color: $formButtonColorDefault;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-disabled {
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
                color: inherit;
                opacity: 0.5;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-outer-circle {
                border-color: $formButtonColorDefault;
                opacity: 0.5;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-inner-circle {
            background-color: $formButtonColorDefault;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-ripple {
            display: none;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-outer-circle {
        border-color: $formButtonColorDefault;
    }
}

.mat-mdc-checkbox {
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    &.mat-checkbox-disabled {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-checked {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-frame,
            .mat-checkbox-background {
                background-color: $formButtonColorDefault;
                opacity: 0.5;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &:not(.mat-checkbox-checked) {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-frame {
                border-color: $formButtonColorDefault;
                opacity: 0.5;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-label-content {
            opacity: 0.35;
            color: inherit;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
        p {
            margin: 0;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-background {
            background-color: $formButtonColorDefault;
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        align-items: center;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
        border-color: $formButtonColorDefault;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-ripple {
        opacity: 0;
        display: none;
    }
}

/*Textarea settings*/
textarea.mat-mdc-input-element {
    height: 32px;
    line-height: 32px;
}

.mat-mdc-input-element {
    // Default user-select: none prevents from focusing input correctly in IE
    -ms-user-select: auto !important;
}

.mat-mdc-form-field.textarea-field {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    textarea.mat-input-element,
    .mat-mdc-form-field-label-wrapper {
        height: 32px;
        line-height: 32px;
    }

    &.import-data-textarea-field {
        width: 600px;
        padding: 0 10px 0;
        box-shadow: none;
        margin: 0;
        background-color: rgba(241, 244, 247, 0.5);
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        textarea.mat-input-element,
        .mat-mdc-form-field-label-wrapper {
            height: 100px;
        }
    }
}

.item-card-preview {
    &.question-item-card {
        padding-top: 65px;
    }
}

.cb-question-list-wrapper {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    min-height: 40px;
}

.item-card-preview {
    &,
    &.inner-item-card {
        .mat-mdc-checkbox {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-disabled {
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                &.mat-checkbox-checked {
                    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    .mat-checkbox-frame,
                    .mat-checkbox-background {
                        background-color: $formFieldPreviewBorderColor;
                    }
                }
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                &:not(.mat-checkbox-checked) .mat-checkbox-frame {
                    border-color: $formFieldPreviewBorderColor;
                }
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked.mat-accent,
            &.mat-checkbox-indeterminate.mat-accent {
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-background {
                    background-color: $formFieldPreviewBgColor;
                }
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-frame {
                border-color: $formFieldPreviewBorderColor;
            }
        }

        .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
            background-color: $formFieldPreviewBgColor;
        }

        .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
        .mat-radio-button.mat-accent.mat-radio-disabled .mat-radio-outer-circle,
        .mat-radio-outer-circle {
            border-color: $formFieldPreviewBorderColor;
        }

        /*Slider settings*/
        /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
        .mat-slider-track-background {
            background-color: rgba(117, 142, 161, 0.35);
        }

        .mat-mdc-slider.mat-accent .mat-slider-thumb,
        .mat-slider.mat-accent .mat-slider-thumb-label,
        .mat-slider.mat-accent .mat-slider-track-fill {
            background-color: $formFieldPreviewBgColor;
            border-color: $formFieldPreviewBorderColor;
        }
    }
}

.cb-take-survey-section {
    .mat-mdc-checkbox {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-disabled {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked {
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-frame,
                .mat-checkbox-background {
                    background-color: $surveyButtonsBgColor;
                }
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &:not(.mat-checkbox-checked) .mat-checkbox-frame {
                border-color: $surveyButtonsBgColor;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-checked.mat-accent,
        &.mat-checkbox-indeterminate.mat-accent {
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-background {
                background-color: $surveyButtonsBgColor;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-frame {
            border-color: $surveyButtonsBgColor;
        }
    }

    .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
        background-color: $surveyButtonsBgColor;
    }

    .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
    .mat-radio-button.mat-accent.mat-radio-disabled .mat-radio-outer-circle,
    .mat-radio-outer-circle {
        border-color: $surveyButtonsBgColor;
    }

    /*Slider settings*/
    /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    .mat-slider-track-background {
        background-color: rgba(117, 142, 161, 0.35);
    }

    .mat-mdc-slider.mat-accent .mat-slider-thumb,
    .mat-slider.mat-accent .mat-slider-thumb-label,
    .mat-slider.mat-accent .mat-slider-track-fill {
        background-color: $surveyButtonsBgColor;
        border-color: $surveyButtonsBgColor;
    }
}

.slide-toggle-bar {
    & + .slide-toggle-bar {
        margin-top: 5px;
    }

    .toggle-component {
        display: inline-flex;
        align-items: center;
        padding: 6px 0;
    }

    .toggle-component-block {
        padding: 6px 0;
        display: flex;
        align-items: center;
    }

    .mat-mdc-form-field {
        margin: -18px 10px;
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-content {
    margin-right: 10px;
    font-size: 16px;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-bar {
    background-color: rgba(117, 142, 161, 0.35);
}

.mat-mdc-slide-toggle.mat-primary.mat-checked {
    &:not(.mat-disabled) {
        /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-slide-toggle-thumb {
            background-color: #758ea1;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-slide-toggle-bar {
            background-color: rgba(117, 142, 161, 0.6);
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-ripple {
    opacity: 0;
}

.mat-mdc-radio-button {
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
        white-space: normal;
        align-items: flex-start;
    }
}

.mat-mdc-radio-button {
    &.cb-button-default {
        min-width: 154px;
        margin: 0 8px 16px;
        color: #212121 !important;
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-checked {
            &,
            &:hover,
            &:focus {
                background-color: rgba(87, 106, 120, 0.45) !important;
            }

            .icon {
                color: #4d4d4d;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-container {
            display: none;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            padding: 0;
            display: flex;
            align-items: center;

            .icon {
                margin-right: 8px;
            }
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label-content {
        p {
            margin: 0;
        }
    }

    &.cb-button-default.cb-icon-button {
        min-width: 0;
        margin: 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-checked {
            &,
            &:hover,
            &:focus {
                background-color: rgba(117, 142, 161, 0.35) !important;
            }

            .icon {
                color: #4d4d4d;
            }
        }
    }
}

.font-size-radio-group {
    display: flex;
    align-items: center;

    .mat-mdc-radio-button {
        font-weight: bold;

        &.large-font-size {
            font-size: 20px;
        }

        &.default-font-size {
            font-size: 16px;
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
                line-height: 16px;
                margin-top: 4px;
            }
        }

        &.small-font-size {
            font-size: 12px;
            /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
                line-height: 12px;
                margin-top: 8px;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            font-weight: bold;
        }
    }
}

.form-filetypes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .form-fields-group {
        width: 30%;
        min-width: 150px;
    }
}

.file-types-preview-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    max-width: 600px;

    .file-types-preview-item {
        width: 30%;
        min-width: 90px;
    }
}

cb-file-upload-item-preview {
    input,
    .file-types-preview-list,
    h4 {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
}

.cb-contact-export-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cb-responses-export-content {
    .export-form-group {
        .form-label {
            display: block;
            margin-bottom: 15px;
            font-weight: 700;
        }

        .export-radio-group {
            @include clearfix;
            display: block;
            margin: 0 -8px;

            .mat-mdc-radio-button {
                @include align-checkbox-top;
                float: left;
                width: 260px;

                &:nth-child(2n + 1) {
                    clear: left;
                }
            }
        }

        .export-csv-group {
            display: flex;
            flex: 1;
            margin: 0 -10px;

            .controls-list {
                margin: 0 10px;
                width: 260px;
            }

            .mat-mdc-checkbox {
                @include align-checkbox-top;
                padding: 0;
            }
        }
    }

    .buttons-container {
        margin-top: 40px;
    }
}

.cb-upload-file-container {
    .cb-upload-file-buttons {
        margin: 0 -10px;

        button {
            margin: 0 10px;
        }
    }

    .cb-upload-file-name {
        &:not(:empty) {
            display: block;
            margin-top: 10px;
        }
    }
}

.mat-mdc-radio-group {
    &.full-width-radio {
        .mat-mdc-radio-button {
            display: block;
            margin-bottom: 10px;
            line-height: 20px;
        }
    }
}

.textarea-wrapper {
    max-width: 100%;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-infix {
        width: auto;
        .question-textarea{
            max-width: 100%;
        }
    }


}

span.multiline-characters-info {
    display: block;

    &.over-limit {
        color: red;
    }
}

.active-directory {
    &-inputs {
        display: flex;
        justify-content: space-between;

        .cb-add-groups-section-inner & {
            width: 100%;
        }

        .options-block {
            button {
                margin-bottom: 7px;
            }
            .search-group {
                margin-bottom: 7px;
            }
        }
    }

    &-change {
        margin-right: 20px;
    }

    &-search {
        margin-top: 4px;
    }
}

.merge-select-wrapper {
    .mdc-line-ripple {
        display: none;
    }
}

.and-filters {
    width: 100%;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
}

cb-multilines-item-preview {
    editor,
    .tox-tinymce {
        height: 100% !important;
    }
}

.inner-item-card {
    position: relative;
    font-size: 14px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
    margin-bottom: 24px;

    .card-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-height: 24px;

        .card-title {
            flex: 1;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.87);

            p {
                margin: 0;
            }
        }
    }

    .card-controls {
        .mat-mdc-icon-button {
            //width: 24px;
            //height: 24px;
            //line-height: 0;
            //margin-left: 5px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .question-number {
        position: absolute;
        top: 20px;
        left: 35px;
    }

    &.question-item-card {
        .card-header {
            padding-right: 80px;
        }

        .positioned-card-controls {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        p {
            margin-top: 0;
        }
    }
}
.text-preview{
    display: inline-block;
    flex: 4;
}

.form-section--qr-code {
    .qrcode {
        padding-top: 8px;
        text-align: center;
    }
}

.form-section--whatsapp{
    .form-section-content {
        padding: 65px 95px 53px;
    }
}

.form-section--embed {
    .form-section-content {
        padding: 24px 24px 80px;
        .mat-mdc-form-field {
            background-color: #F1F4F7;
            margin-bottom: 0;
            padding: 0 16px 10px;
            width: calc(100% - 32px) !important;
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-wrapper {
                padding-bottom: 0;
                textarea {
                    line-height: 24px;
                    resize: none;
                }
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-mdc-form-field-underline {
                    height: 0;
                }
            }
        }
    }

}
.no-label {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-infix {
        border: 0 !important;
        padding: 10px 0 !important;
    }
    *{
        margin: 0 !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-label-wrapper {
        display: none;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-appearance-outline,
    .mat-mdc-select-arrow-wrapper {
        transform: none !important;
    }
}

.no-hint {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0px !important;
    }
}
.no-top{
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-outline {
        top: 0 !important;
    }

}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}


// Input padding
.mat-mdc-text-field-wrapper:not(:has(mat-select)) {
    padding-left: 1px !important; /* Adjust padding inside the input field */
}

.mdc-text-field__input:not(:has(mat-select)) {
    padding-left: 1px !important; /* Adjust text padding */
}

.mdc-floating-label:not(:has(mat-select)) {
    padding-left: 1px !important; /* Adjust placeholder padding */
}



.mat-mdc-form-field {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    // mat-mdc-text-field-wrapper mdc-text-field ng-tns-c3446705053-11 mdc-text-field--outlined mdc-text-field--no-label
    // .mat-mdc-form-field-outline {
    .mdc-text-field--outlined {
        border-radius: 10px !important;
        flex-grow: 1;

        //&-end {
        //    border-radius: 0 10px 10px 0 !important;
        //}
        //
        //&-start {
        //    border-radius: 10px 0 0 10px !important;
        //    flex-grow: 1;
        //}
    }
    &.dialog-form{
        border-radius: 0 14px 14px 0 !important;
        &-end {
            border-radius: 0 14px 14px 0 !important;
        }

        &-start {
            border-radius: 14px 0 0 14px !important;
            flex-grow: 1;
        }
        /* FIXED - TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-infix{
            padding: 16px 0 !important;
        }

        //mat-mdc-form-field dialog-form no-label no-top ng-tns-c69-139 mat-primary mat-mdc-form-field-type-mat-input mat-mdc-form-field-appearance-outline mat-mdc-form-field-can-float ng-pristine ng-invalid ng-star-inserted mat-mdc-form-field-invalid ng-touched

        /*FIXED - TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {
            padding-left: 20px !important;
            padding-right: 12px !important;
        }
        input:valid {
            @include typo-form-completed-medium;
        }
        input::placeholder {
            @include typo-form-placeholder-medium;
        }
    }
    &.dialog-form-input{
        height: 56px !important;
        &-end {
            border-radius: 0 12px 12px 0 !important;
        }

        &-start {
            border-radius: 12px 0 0 12px !important;
            flex-grow: 1;
        }
        /* FIXED: TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-infix{
            padding: 12px 0 !important;
        }
        /* FIXED: TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {
            padding-left: 16px !important;
            padding-right: 12px !important;
        }
        input:valid {
            @include typo-form-completed-medium;
        }
        input::placeholder {
            @include typo-form-placeholder-medium;
        }
    }
}
/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-autocomplete-panel.auto-complete-selection {
    /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    mat-option{
        span{
            @include typo-form-label-medium;
        }
    }
}
