@import '../variables';
.icon {
    display: inline-block;
    line-height: 0;
    color: $text-color-grey;
}

/*
.icon {
    svg{
        width: 24px;
        height: 24px;
    }
}
*/

.icon-move {
    display: inline-block;
    width: 22px;
    height: 24px;
    transform: rotate(90deg);
}

.cb-icon-caret-container {
    .caret-icon {
        margin-left: 5px;
        color: $text-color-grey !important;
    }
}

cb-icon-with-caret {
    line-height: 0;
    display: inline-block;
    vertical-align: middle;
}

mat-icon {
    &.default{
        color: $basic-alpha-48;
    }
    &.favorite{
        color: map-get($system-warning-palette, 400);
    }
}
