.cb-styles-page-content {
    .cb-styles-page-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .options-block {
            float: right;
            display: flex;
            align-items: center;
            button {
                padding: 0;
                margin: 0;
                cursor: pointer;
                width: 40px;
                font-size: 16px;
                color: #758ea1;
                line-height: 16px;
            }
            .toggle-view-buttons {
                margin-left: 15px;
                .toggle-icon {
                    padding: 0;
                    cursor: pointer;
                    color: #758ea1;
                    height: 32px;
                    width: 44px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 0;

                    &.selectedIcon {
                        border-radius: 2px;
                        background-color: rgba(117, 142, 161, 0.35);
                        color: #212121;
                    }

                    &.unselectedIcon {
                    }
                    .icon {
                        color: inherit;
                    }
                }
            }
            .search-group {
                margin-right: 10px;
            }
        }

        .title-group {
            flex: 1;
        }

        .main-page-title {
            display: inline-block;
            margin: 0;
            font-size: 24px;
            line-height: 1.35;
        }

        .cb-styles-page-header-buttons {
            display: inline-block;
            margin-left: 15px;
            button {
                display: inline-block;
                margin: 0;
                & + button {
                    margin-right: 5px;
                }
            }
        }
    }
}

.cb-style-templates-blocks {
    margin: 0 -12px;
    display: flex;
    flex-flow: row wrap;
    .block-width {
        width: 33.33333333%;
        @media #{$tablet-down} {
            max-width: calc(50% - 24px);
        }
        @media #{$mobile} {
            width: 100%;
            max-width: calc(100% - 24px);
        }
    }
}
.cb-style-templates-list {
    margin-top: 16px;
    margin-bottom: 15px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
}

.cb-style-template-item-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    align-items: center;
    background: #fff;
    padding: 0 15px;
    box-sizing: border-box;
    .style-template-item-title {
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.87);
    }
    &:nth-child(2n) {
        background: #f1f4f7;
    }
    &:hover {
        background-color: #f2f2f2;
    }
}

.cb-style-template-item-block {
    position: relative;
    box-sizing: border-box;
    display: flex;
    min-height: 248px;
    margin: 12px 12px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);

    .cb-styles-template-top-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        box-sizing: border-box;
        background-color: #2567c2;
    }

    .cb-styles-template-inner {
        position: relative;
        z-index: 2;
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .cb-styles-template-title {
        margin-bottom: 2px;
        min-height: 31px;
        line-height: 1.33;
        font-size: 24px;
        color: rgb(0, 0, 0); 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cb-styles-templates-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 16px 24px;
        min-height: 168px;
    }

    .cb-styles-template-question {
        flex: 1;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 1px 2px 0 rgba(0, 8, 20, 0.24),
            0 0 1px 0 rgba(0, 8, 20, 0.12);
    }
    .cb-styles-template-controls {
        display: flex;
        align-items: center;
        height: 46px;
        padding: 0 12px;
        border-top: 2px solid #f1f4f7;
        border-radius: 2px;
        background-color: #fafcfc;
        .buttons-container {
            margin-left: auto;
        }
        button {
            margin: 0 6px;
        }
    }

    .cb-styles-template-question {
        display: flex;
        flex-direction: column;
        padding: 12px 12px 0;
        .question-container {
            flex: 1;
        }
        .question-title {
            margin-bottom: 3px;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.87);
        }
        .question-description {
            margin-bottom: 7px;
            font-size: 16px;
            line-height: 1.25;
            color: #212121;
        }
        .question-answer {
            font-size: 14px;
            line-height: 1.14;
            color: rgba(0, 0, 0, 0.87);
        }
        .cb-styles-question-footer {
            height: 40px;
            display: flex;
            align-items: center;
            margin: 0 -12px;
            padding: 0 12px;
            justify-content: flex-end;
            border-radius: 2px;
            background-color: #fafcfc;
            .question-button-preview {
                width: 55px;
                height: 20px;
                border-radius: 2px;
                background-color: #2567c2;
            }
        }
    }
}
.cb-styles-page-filters{
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-mdc-checkbox,
    .mat-mdc-button,
    .mat-checkbox-label,
    .mat-checkbox-layout{
        width: 100%;
        text-align: left;
    }
}