@import '../variables';
@import '../partials/dashboard';
@import '../partials/charts';

.cb-toolbar-space {
    // This fills the remaining space, by using flexbox.
    // Every toolbar row uses a flexbox row layout.
    flex: 1 1 auto;
}

.dashboard-list {
    li {
        cursor: pointer;
    }
}

.dashboard-toolbar {

    .dashboard-info {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .dashboard-title {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 20px;
        font-size: 24px;

        .mat-mdc-form-field {
            max-width: 676px;
            display: inline-block;
        }

        .icon-wrap {
            display:none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #758ea1;
            line-height: 0;
            margin-top: -4px;
        }

        .dashboard-name {
            color: rgba(0, 0, 0, 0.87);

            &.disabled,
            &.edit-mode {
                width: 100%;
                border: 0;
                font-size: 24px;
            }
        }
    }

    .edit-navigation {
        margin: -28px 0 34px;
        padding-top: 20px;
        width: auto;

        .cb-toolbar-menu {
            flex: 1;
            display: flex;

            .cb-toolbar-menu-item {
                margin-left: 25px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .menu-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 64px;
            padding: 0;
            color: #758ea1;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            text-decoration: none;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: #758ea1;
                opacity: 0;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }

            &.active {
                color: #ff5336;

                &::after {
                    background: #ff5336;
                    opacity: 1;
                }
            }

            .mat-icon,
            .icon {
                margin-right: 8px;
                color: inherit;
            }
        }

        .mat-mdc-icon-button {
            min-width: 24px;
            width: 32px;
            height: 32px;
            line-height: 0;
            color: #758ea1;
        }
    }

    .dashboard-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cb-toolbar-actions {
            .cb-toolbar-action-item {
                display: inline-block;
                margin: 0 4px;
            }
        }
    }
}

.cb-widget-chart-placeholder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.cb-dashboard-preview-iframe {
    border: none;
}

.cb-dashboard-controls {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.cb-dashboard-preview {
    &.cb-app-content {
        padding: 0;
    }
}

.dashboard-overview {
    .cb-take-survey-section.cb-style-template-survey {
        min-height: 0;
        display: flex;
        flex-direction: column;
    }
    .cb-take-survey-section {
        .mat-mdc-button.cdk-focused,
        .mat-mdc-icon-button.cdk-focused {
            box-shadow: none;
        }
    }
}
