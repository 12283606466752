@import '../variables';

.cb-slider-controls {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;

    &.row-reverse {
        flex-direction: row-reverse;
    }

    .mat-mdc-form-field {
        width: 40px;
        margin-left: 10px;
        text-align: center;
    }
}

.cb-slider-preview-container {
    position: relative;
    width: 490px;
    display: flex;
    flex-direction: column;
    min-width: 128px;
    max-width: 100%;

    &.padding-for-value {
        max-width: calc(100% - 50px);
    }

    .slider-label-list {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        font-size: 14px;
        color: #758ea1;
        z-index: 5;
        overflow: hidden;
        padding-bottom: 20px;

        &.slider-label-above {
            order: 0;
        }

        &.slider-label-under {
            order: 1;
        }

        &.min-max-only {
            .slider-label-item {
                opacity: 0;

                &:first-child,
                &:last-child {
                    opacity: 1;
                }
            }
        }

        .slider-label-item {
            cursor: pointer;
            position: relative;
            flex-grow: 2; // don't use short syntax because of IE11
            flex-shrink: 1; //
            flex-basis: 0;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
                text-align: right;
                flex-grow: 1;
                align-items: flex-start;
            }

            &:first-child {
                text-align: left;
                flex-grow: 1;
                align-items: flex-end;
            }

            &.active {
                color: #212121;
            }

            &-value {
                &.image-label-mobile {
                    .image-alias {
                        margin-left: 10px;
                    }
                }

                &.image-label-above {
                    flex-direction: column-reverse;
                    display: flex;
                }

                &.image-label-under {
                    flex-direction: column;
                    display: flex;
                }
            }
        }
    }

    .mat-mdc-slider {
        width: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    .mat-slider-horizontal .mat-slider-wrapper {
        left: 3px;
        right: 4px;
    }
}

.cb-custom-slider {
    display: inline-flex;
    position: relative;

    .slider-custom-ticks-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .slider-custom-tick {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 2px #2567c2;
            background-color: #2567c2;
            border: 2px solid #ffffff;

            &.active {
                box-shadow: inset 0 0 0 2px #2567c2, inset 0 0 0 2px #2567c2;
                background-color: #2567c2;
                border-color: #2567c2;
            }
        }
    }

}

.vertical-slider-container {
    flex-direction: row;
    margin: 20px 0;

    .mat-mdc-slider {
        width: 50px;
    }

    .slider-label-list {
        flex-direction: column;
        order: 2 !important;
        align-items: flex-start;
        text-align: left;

        .slider-label-item {
            align-items: center;
            display: flex;
            &:first-child{
                align-items: flex-start;
            }
            &:last-child{
                align-items: flex-end;
            }
        }
    }

    .slider-custom-ticks-list {
        flex-direction: column-reverse;
        margin: 4px 0;
    }

    .vertical-slider-view {
        margin: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    .mat-slider-vertical {
        min-height: 300px;
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
        .mat-slider-wrapper {
            top: 4px;
            bottom: 5px;
            left: 24px;
        }
    }

    .slider-label-item-value.image {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            max-height: calc(100% - 20px);
        }
    }
}
