mat-form-field {
    display: block;
}

.inner-item-card {
    img {
        max-width: 768px;
    }
}

.cb-add-hidden-item {
    margin-bottom: 10px;
    .cb-button-default{
        margin-right: 10px;
    }
    .add-hidden-item-button {
        .icon-hidden-item {
            margin-right: 10px;
        }
    }
}

.new-page-controls {
    margin-bottom: 10px;
}


button.cb-survey-editor-language-button {
    margin-left: auto;
    top: -30px;
    height: 18px;
    display: block;
}

.cb-rename-page-section {
    flex: 1;
    margin-left: 10px;
    .cb-rename-page-form {
        display: flex;
        height: 40px;
        overflow: hidden;
        align-items: center;
        margin-top: 8px;
        .cb-page-name-input {
            width: 100%;
            max-width: 667px;
            flex: 1;
            margin: 0;
        }
    }
}

.editing-section-overlay {
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    /*pointer-events: none;*/
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 1px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(87, 106, 120, 0.45);
        box-shadow: 0 0 0 9000px rgba(87, 106, 120, 0.45);
    }
}

.mat-expansion-panel-content.mat-expanded .editable-section,
.editable-section {
    position: relative;
    min-height: 150px;
    z-index: 1000;
    background: #fff;
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .cdk-overlay-pane .merge-code-select-panel {
        min-width: 260px !important;
    }
}

cb-item-type {
    width: 100%;
    height: 100%;
}

cb-help-icon {
    vertical-align: middle;
    display: inline-block;
    
    .icon {
        margin-left: 5px;
    } 
}

.input-with-icon {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        margin-top: auto;
    }
}