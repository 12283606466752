.cb-group-questions-form {
    .cb-logic-group-container {
        position: relative;
        padding-top: 20px;
        .groups-header-row {
            position: absolute;
            top: -27px;
            left: 0;
            right: 0;
            padding: 0 25px;
        }
    }

    .picker {
        width: 180px;
    }

    .cb-button-link {
        display: inline-block;
        margin-top: 15px;
    }

    .main-field-container {
        max-width: 40%;
        .mat-mdc-form-field {
            width: 100%;
        }
    }

    .group-fields-container {
        margin: 0 -6px;
        .control-field {
            width: calc(20% - 16px);
            &:nth-child(3),
            &:nth-child(4) {
                width: calc(30% - 16px);
            }
            margin: 0 6px;
        }
    }

    .logical-expression {
        display: block;
        margin: 5px 0;
        color: #576a78;
        font-size: 12px;
        text-transform: uppercase;
    }

    .groups-header-row {
        margin-bottom: 10px;
        font-weight: bold;
        display: flex;
    }
}
