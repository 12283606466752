span.other-option-input {
    margin-left: 18px;
}
.item-card-preview,
.cb-take-survey-sub-section {
    .item-text {
        font-weight: bold;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .checkboxes-group,
    .mat-radio-button-group {
         //display: flex; fix bug in IE11
        flex-flow: wrap;
        align-items: flex-start;

        &.vertical-view {
            flex-direction: column;
        }

        &.with-columns {
            display: grid;
            display: -ms-grid;

            .table-matrix-group {
                /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                div.mat-button-container {
                    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                    &.mat-button-flex {
                        display: flex;
                        margin-bottom: 10px;
                        margin-top: 5px;
                        justify-items: start;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
                    &.mat-button-inline-block {
                        display: inline-block;
                        padding: 5px;
                        box-sizing: border-box;
                    }

                }
                &.display-horizontally {
                    display: flex;
                    justify-content: space-around;
                }
                &.display-vertically {
                    .checkbox-container {
                        margin: 0;
                        padding: 5px 0;

                        .checkbox-label {
                            white-space: nowrap;
                        }
                    }
                }
            }

        }

        &.horizontal-view {
            display: flex;
            flex-direction: row;
            @media #{$mobile} {
                flex-direction: column;
            }

            .choice-item-container,
            .mat-mdc-radio-button {
                @media #{$mobile} {
                    width: 100% !important;
                }
            }
        }

        .cb-other-answer {
            padding: 4px 8px 0 0;
            display: block;
            box-sizing: border-box;

            .mat-mdc-radio-button,
            .mat-mdc-checkbox {
                display: inline-block;
                margin-bottom: 10px;
                margin-right: 10px;
                padding: 0;
                @media #{$mobile} {
                    margin-right: 0;
                    padding: 0 0 10px 0;
                }

                + span {
                    margin-bottom: 10px;
                    display: inline-block;
                }
            }

            .mat-mdc-form-field {
                margin: -3px 0 0 0;
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-wrapper {
                    padding-bottom: 0;
                    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                    .mat-form-field-underline {
                        bottom: 0;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                    .mat-form-field-infix {
                        padding: 0.3em 0;
                        border-top: 0;
                    }
                    /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
                    .mat-input-suffix {
                        bottom: 2px;
                    }
                }
            }
        }

        .mat-mdc-radio-button,
        .mat-mdc-checkbox {
            display: block;
            margin: 0 0 10px;
            white-space: normal;
            padding: 0 8px 0 0;
            flex: 0 0 auto;
            box-sizing: border-box;
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-label {
                line-height: 18px;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-layout {
                display: flex;
                align-items: flex-start;
                /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-label {
                    white-space: normal;
                    overflow-wrap: break-word;
                }
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-inner-container {
                margin: 0 8px 0 0;
            }
        }
    }

    .table-matrix-group {
        .mat-mdc-radio-button,
        .mat-mdc-checkbox {
            margin: 0;
            padding: 0;
        }
    }

    .required {
        position: relative;
        min-height: 15px;

        &:before {
            content: '*';
            color: red;
            position: absolute;
            left: -10px;
            top: 0;
        }

        &.question-item-number:before {
            position: static;
            margin-right: 4px;
        }
    }

    .caption {
        padding-right: 10px;
    }

    .dragAndDrop {
        width: 200px;
        height: 1em;
        background-color: #ccc;
        padding: 10px;
        margin-bottom: 0.5em;
    }

    .selectableDragnDrop {
        vertical-align: top;
    }

    .selectedOptions {
        vertical-align: top;
        width: 200px;
        min-height: 100px;
        background-color: #ccc;
        display: inline-block;
        margin: 5px;
    }

    .option {
        width: 90%;
        height: 2em;
        background-color: gray;
        margin: 0.5em;
    }

    .full-width {
        width: 100%;
    }

    .inline {
        display: inline-block;
    }

    .signature-pad-container signature-pad canvas {
        border: 1px dashed #ccc;
        margin: 10px 0;
        max-width: 100%;
    }

    .inline-block {
        display: inline-block;
    }

    .invalid {
        text-align: center;
        color: red;
        font-size: 16px;
        font-weight: normal;
        &.has-width-limitation {
            width: 180px;
        }
    }

    .max-diff-answer {
        margin-right: 100px;
    }
}
