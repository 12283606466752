@use '@angular/material' as mat;

.cb-typo-headline {
        margin: 0 0 15px;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
}

.cb-typo-title {
        margin: 0 0 15px;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
}

.cb-typo-subheader {
        margin: 0 0 15px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #212121;
}

.cb-typo-body-text {
        font-size: 14px;
        line-height: 1.14;
        color: rgba(0, 0, 0, 0.87);
}

.cb-typo-caption {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #576a78;
}

.typo-table {
        table-layout: fixed;
        width: 100%;

        td {
                padding: 5px 10px;
                vertical-align: top;
                border: 1px solid #ccc;
        }
}

.typo-icons-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        .icon {
                margin: 5px;
        }
}

.example-typo-colors-list {
        .example-color-box {
                width: 64px;
                height: 64px;
                display: inline-block;
                margin: 0 15px 15px 0;
        }
}

/*Common background colors*/
.cb-typo-bgColor-black {
        background-color: #212121;
}

.cb-typo-bgColor-slate {
        background-color: #576a78;
}

.cb-typo-bgColor-light-slate {
        background-color: #758ea1;
}

.cb-typo-bgColor-light-gray {
        background-color: #f1f4f7;
}

.cb-typo-bgColor-orange {
        background-color: #ff5336;
}

.warning-message {
        display: block;
        font-size: 13px;
        font-weight: bold;
        color: red;
}

$cb-display: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-Bold, Roboto, sans-serif',
                $font-weight: 400,
                $font-size: 66px,
                $line-height: 46px);

$cb-headline-1: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-SemiBold, Roboto, sans-serif',
                $font-weight: 400,
                $font-size: 42px,
                $line-height: 46px);

$cb-headline-2: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-SemiBold, Roboto, sans-serif',
                $font-weight: 400,
                $font-size: 34px,
                $line-height: 40px);

$cb-headline-3: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-SemiBold, Roboto, sans-serif',
                $font-weight: 400,
                $font-size: 30px,
                $line-height: 40px);

$cb-headline-4: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-SemiBold, Roboto, sans-serif',
                $font-weight: 400,
                $font-size: 24px,
                $line-height: 32px);

$cb-body-2-regular: mat.m2-define-typography-level($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $font-size: 14px,
                $font-weight: 400,
                $line-height: 20px);

$cb-body-2-bold: mat.m2-define-typography-level($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $font-size: 14px,
                $font-weight: 600,
                $line-height: 20px);

$cb-caption-1-regular: mat.m2-define-typography-level($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $font-size: 13px,
                $font-weight: 400,
                $line-height: 18px);

$cb-button: mat.m2-define-typography-level($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $font-size: 14px,
                $font-weight: 500,
                $line-height: 20px);

$cb-form-input-small: mat.m2-define-typography-level($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $font-size: 14px,
                $font-weight: 400,
                $line-height: 1.2500em);

$checkbox-typography-config: mat.m2-define-typography-config($font-family: 'Galano Grotesque, Roboto, sans-serif',
                $headline-1: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-Bold, Roboto, sans-serif',
                        $font-weight: 400,
                        $font-size: 132px,
                        $line-height: 46px),
                $headline-2: $cb-display,
                $headline-3: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-Bold, Roboto, sans-serif',
                        $font-weight: 400,
                        $font-size: 55px,
                        $line-height: 44px),
                $headline-4: mat.m2-define-typography-level($font-family: 'GalanoGrotesque-Bold, Roboto, sans-serif',
                        $font-weight: 400,
                        $font-size: 44px,
                        $line-height: 42px),
                $headline-5: $cb-headline-1,
                $headline-6: $cb-headline-2,
                $subtitle-1: $cb-headline-3,
                //$body-1: $cb-headline-4,
                //$body-2: $cb-body-2-regular,
                $body-1: $cb-form-input-small,
                $body-2: $cb-form-input-small,
                $subtitle-2: $cb-body-2-bold,
                $caption: $cb-caption-1-regular,
                $button: $cb-button,
                /* TODO(mdc-migration): No longer supported. Use `body-1` instead. $input: $cb-form-input-small, */
        );
