.search-group {
    position: relative;
    height: 50px;

    button {
        color: #758ea1;
    }

    .search-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .clear-button {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }

    .mat-mdc-form-field {
        width: 100%;
        /*position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        .mat-form-field-wrapper {
            padding-bottom: 1.25em;
        }
        .mat-form-field-infix {
            padding: 0.4375em 0;
            border-top: 0.84375em solid transparent;
        }*/
    }

    ::placeholder {
        color: #758ea1;
    }
}
