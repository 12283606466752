@import '../mixins';
@import '../variables';
@import 'form';
@import 'buttons';

//colors
$color_orange: #ff5336;
$color_limed_spruce: #3a4d54;
$white: #fff;

.cdk-overlay-container {
    z-index: 1030;
}

.mat-mdc-dialog-surface {
    max-height: 90vh;
    overflow: auto;
    padding: 24px 36px;
}

.cb-section-divider {
    @include section-divider;
}

.full-width-input {
    width: 100%;
}

.half-width-input {
    width: 50%;
}

.dialog-content {
    .mat-mdc-dialog-title {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 0;
    }

    .buttons-container {
        @extend %dialog-buttons-container;
        position: relative;
        z-index: 1024;
        margin: 20px -36px -24px;
        padding: 0 36px;

        &.large-button-container {
            height: 128px;
            button {
                min-width: 131px;
                height: 48px;
                line-height: 48px;
                @media #{$mobile} {
                    width: 100%;
                }
            }
        }
        &.justify-content-between {
            justify-content: space-between;
        }
    }
}

.inline-edit-section {
    margin: 20px 0;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    .inline-edit-content {
        padding: 24px 36px;

        .editable-section,
        .editing-section-overlay {
            z-index: 1100 !important;
        }
        .mat-expansion-panel-body {
            background-color: #fff;
        }
    }
}

.cb-widget-dialog-content {
    margin: 0;
    padding: 0;

    .form-section {
        border: 0;
        margin: 0;
        padding: 0;
    }

    .cb-section-divider {
        margin: 23px 0 27px;
    }

    .cb-widget-dialog-title-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .mat-mdc-dialog-title {
            flex: 1;
            margin: 0;
        }
    }

    .form-fields-group {
        margin: 0 -8px;
        display: block;
        .mat-mdc-form-field {
            width: calc(25% - 16px);
            &.widget-name-input {
                width: 264px;
            }
            &.filter-input {
                width: calc(17% - 16px);
            }
        }
    }

    .form-checkbox-container {
        margin: 15px 0;
    }
    .form-buttons-container {
        margin: 8px 0;
    }

    .chart-type-list {
        margin: 0 -8px;
        display: flex;
        flex-flow: row wrap;
        .mat-mdc-radio-button {
            &.cb-button-default {
                min-width: 154px;
                margin: 0 8px 16px;
            }
        }
    }

    .widget-size-radio-group {
        margin: 0 -8px;
        display: flex;
        flex-flow: row wrap;
        width: 494px;

        .mat-mdc-radio-button {
            &.cb-button-default {
                min-width: 0;
                margin: 0 8px 16px;
                &.small {
                    flex-basis: calc(33.33333% - 16px);
                    max-width: calc(33.33333% - 16px);
                }
                &.medium {
                    flex-basis: calc(66.6666% - 16px);
                    max-width: calc(66.6666% - 16px);
                }
                &.large {
                    width: calc(100% - 14px);
                }
            }
        }
    }
}

.cb-branching-dialog-content {
    .mat-mdc-dialog-title {
        margin-bottom: 10px;
    }
}

.cb-new-question-dialog-content {
    .full-width {
        width: 100%;
    }

    .invalid {
        color: red;
    }
}
/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container{
    &:has(cb-main-dialog){
        border-radius: 18px !important;
        background: $backgrounds-light-100 !important;
        padding: 0px !important;
        cb-main-dialog{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cb-spinner{
                width: 100%;
            }
            &.centered{
                .title{
                    div:has(h4){
                        justify-content: center!important;
                    }
                }
            }
            form{
                width: 100%;
                &.dialog-form-block{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                }
                >div{
                    display: flex;
                    flex-direction: column;
                    >span{
                        @include typo-body-2-regular;
                        color: $text-color-grey;
                    }
                    &.side{
                        align-items: center;
                        align-self: stretch;
                        flex-direction: row;
                        gap: 8px;
                    }
                }
            }
            div:has(mat-form-field){
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;
                align-self: stretch;
                label{
                    @include typo-form-label-medium;
                    color: $text-color-grey;
                }
                mat-form-field{
                    width: 100%;
                    input:valid{
                        @include typo-form-completed-medium;
                        font-weight: 600!important;
                        color: $text-color-dark;
                    }
                }
            }
        }
    }

}
