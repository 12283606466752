@import '../../../shared/sass/partials/buttons';
@import '../../../shared/sass/mixins';
@import '../../../shared/sass/variables';

.mat-mdc-option.mdc-list-item {font-size: 14px;}
table.cb-table {
    background-color: #ffffff;
    box-shadow: 0 2px 5.6px 0.4px rgba(0, 0, 0, 0.12);
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    td,
    th {
        padding: 5px 10px;
        &:first-child {
            padding-left: 20px;
        }
        &:last-child {
            padding-right: 20px;
        }
    }
}
table.cb-table-stripped {
    tbody {
        tr {
            &:nth-child(2n-1) {
                background: #f1f4f7;
            }
        }
    }
}

.cb-flex-table-container {
    .mat-mdc-table {
        .mat-mdc-header-row,
        .mat-mdc-row:not(.expanded-table-row) {
            // padding: 0 5px 0 15px;
            &:nth-child(2n) {
                background: #f1f4f7;
            }
        }
        .expanded-table-row {
            // padding: 0 5px 0 15px;
            &:nth-child(4n) {
                background: #f1f4f7;
            }
        }

        .mat-mdc-header-cell,
        .mat-mdc-cell {
            padding-left: 10px;
            &.cdk-column-select {
                flex: none;
                padding-left: 0;
                min-width: 20px;
                width: 20px;
                text-align: left;
            }
            &.cdk-column-actions {
                text-align: center;
                flex: none;
                width: 65px;
                .mat-mdc-icon-button {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                }
            }
        }
        .mat-mdc-header-cell {
            color: #212121;
            font-size: 16px;
        }
        .mat-mdc-cell {
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            font-weight: 400;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-paginator,
    .mat-mdc-paginator-page-size .mat-select-trigger {
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .mat-mdc-paginator {
        .mat-mdc-paginator-page-size {
            margin-right: 0;
        }

        .mat-mdc-paginator-range-label {
            margin: 0 15px;
        }

        .mat-mdc-icon-button {
            width: 24px;
            height: 24px;
            line-height: 24px;
            &[disabled] {
                /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
                .mat-paginator-decrement,
                .mat-paginator-increment {
                    border-color: rgba(117, 142, 161, 0.35);
                }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
            .mat-paginator-decrement,
            .mat-paginator-increment {
                border-color: #758ea1;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
            .mat-paginator-increment {
                margin-left: 10px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
            .mat-paginator-decrement {
                margin-left: 5px;
            }
        }
    }
}

.responses-table {
    margin-bottom: 20px;
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-id {
                flex: 3;
            }
            &.cdk-column-respondent {
                flex: 9;
            }
            &.cdk-column-started {
                flex: 7;
            }
            &.cdk-column-lastEdit {
                flex: 7;
            }
            &.cdk-column-status {
                flex: 5;
            }
        }
    }
}

.survey-mi--table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-id {
                flex: 3;
            }
            &.cdk-column-name {
                flex: 7;
            }
            &.cdk-column-createdBy {
                flex: 7;
            }
            &.cdk-column-lastSent {
                flex: 6;
            }
            &.cdk-column-scheduled {
                flex: 6;
            }
            &.cdk-column-status {
                flex: 6;
            }
        }
    }
}

.si-recipients-table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-email {
                flex: 5;
            }
            &.cdk-column-phoneSms {
                flex: 5;
            }
            &.cdk-column-name {
                flex: 4;
            }
            &.cdk-column-invitationPanelType {
                flex: 3;
            }
        }
    }
}

.si-responses-table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-email {
                flex: 3;
            }
            &.cdk-column-phoneSms {
                flex: 3;
            }

            &.cdk-column-contactId {
                flex: 2;
            }
            &.cdk-column-lastSent {
                flex: 3;
            }
            &.cdk-column-status {
                flex: 2;
            }
            &.cdk-column-messages {
                flex: 1;
            }
        }
    }
}

.si-schedule-table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-type {
                flex: 2;
            }

            &.cdk-column-scheduled {
                flex: 3;
            }
            &.cdk-column-sending {
                flex: 3;
            }
            &.cdk-column-sent {
                flex: 3;
            }
            &.cdk-column-results {
                flex: 4;
            }
        }
    }
}

.report-deliveries-table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-id {
                flex: 3;
            }
            &.cdk-column-created {
                flex: 3;
            }
            &.cdk-column-scheduled {
                flex: 7;
            }
            &.cdk-column-sent {
                flex: 7;
            }
            &.cdk-column-status {
                flex: 3;
            }
            &.cdk-column-actions {
                flex: 3;
            }
        }
    }
}

.cb-survey-fixed-bottom-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1024;
    box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17), 0 0 2px 0 rgba(0, 8, 20, 0.1);

    .actions-content {
        display: flex;
        align-items: center;
        height: 72px;
        padding: 0 60px;
        justify-content: flex-end;
    }

    .mat-mdc-raised-button,
    .mat-mdc-button {
        //@extend %button-link-style;
        @extend %button-default-style;
        height: 36px;
        line-height: 36px;

        &.btn-cancel {
            @extend %button-cancel-style;
        }

        &.btn-submit {
            @extend %button-submit-style;
            margin-left: 10px;
        }
    }
}
.cb-downloalds-table {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cb-downloads-file-name-cell {
                flex: 3;
            }
        }
    }
}
// Table styles revamp
table[mat-table] {
    width: 100%;
    @include survey-list-page-boders;
    &.no-top-border-radius {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }
    overflow:hidden !important;
    th[mat-header-cell]:first-of-type{
        padding: 6px 16px 6px 24px;
    }
    th[mat-header-cell]:last-of-type{
        padding: 6px 24px 6px 12px;
    }
    th[mat-header-cell]{
        border-bottom: 2px solid $basic-alpha-8;
        height: 36px;
        padding: 6px 12px;
        @include typo-data-table-header;
        color: $text-color-grey;
    }
    td[mat-cell]{
        border-bottom: 1px solid $basic-alpha-8;
        height: 36px;
        &:has(span.sub-title){
            height: 56px;
        }
        padding: 5px 12px;
        &:first-of-type{
            padding: 0px 0px 0px 24px;
        }
        &:last-of-type{
            padding: 0px 24px 0px 12px;
        }
        button[mat-stroked-button].stroked-icon{
            padding: 8px;
        }
    }
    tr[mat-footer-row] {
        td[mat-footer-cell]{
            border-bottom: 0;
            padding: 0px;
            >:first-child{
                height: 48px;
            }
        }
    }
}
