@import '../variables';
@import '../global';

mat-sidenav {
    width: 20%;
}

.main-container {
    max-width: 100%;
    margin: 0 auto;
}

.cb-svg-definitions {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
}

.mat-toolbar {
    padding-right: 60px;
    padding-left: 60px;
    &.mat-basic {
        background: #fff;
        box-shadow: 0 2px 8px 0 rgba(0, 8, 20, 0.17),
            0 0 2px 0 rgba(0, 8, 20, 0.1);
            padding-left: 60px;
            padding-right: 60px;
            .mat-toolbar-content {
                align-items: center;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                max-width: 1320px;
                width: 100%;
            }
    }
}

.mat-mdc-menu-item {
    &.active {
        color: #ff5336;
    }
}

.main-navigation {
    position: relative;
    z-index: 5;
    .mat-mdc-button {
        color: #758ea1;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.4px;
        &.active {
            color: #ff5336;
        }
        &.menu-profile-control {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 400;
            .caret-icon {
                color: #758ea1;
            }
        }
    }

    .cb-toolbar-menu {
        .mat-mdc-button {
            text-transform: uppercase;
        }
    }

    .menu-profile-control {
        position: relative;
        padding: 0 28px 0 10px;
        text-align: left;

        &.menu-opened {
            .caret-icon {
                .icon-up {
                    display: block;
                }
                .icon-down {
                    display: none;
                }
            }
        }

        .profile-icon {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }

        .caret-icon {
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 0;
            .icon-up {
                display: none;
            }
        }
    }
}

.toolbar-logo {
    margin-top: 6px;
    color: #ff5336;
    font-weight: bold;
    text-decoration: none;
    img{
        border:0;
        width: 156px;
        min-width: 156px;
    }
}

.cb-toolbar-menu {
    margin-left: 60px;
    .cb-toolbar-menu-item {
        display: inline-block;
        margin: 0 10px;
        .mat-mdc-button {
            padding: 0;
            min-width: auto;
        }
    }
}

.cb-toolbar-user {
    float: right;
    text-transform: none;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        text-transform: none;
    }

}

.cb-toolbar-space {
    // This fills the remaining space, by using flexbox.
    // Every toolbar row uses a flexbox row layout.
    flex: 1 1 auto;
}

.print-report {
    .cb-app-content {
        padding: 0;
    }
}

.cb-app-content {
    padding: 38px 60px 48px;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    /*    @media #{$mobile} {
			padding: 20px;
		}*/
}

.icon-with-caret-button {
    .text-field {
        margin-left: 5px;
    }
}

.owl-overlay-container {
    position: fixed;
    z-index: 99990 !important;
}
.no-items-message {
    text-align: center;
}
