@import '../variables';

.cb-rank-order-container {
    display: flex;
    margin: 0 -10px;
    min-width: 700px;
    flex-grow: 2;
    max-width: 700px;
    &-single-col {
        &.cb-rank-order-container {
            min-width: 350px;
            max-width: 350px;
            .cb-rank-order-options {
                width: 100%;
            }
        }
    }
    //flex-grow: 1;
    @media #{$tablet-down} {
        min-width: 520px;
        max-width: 520px;
    }
    @media #{$mobile} {
        flex-direction: column;
        margin: 0;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .cb-rank-order-options {
        width: calc(50% - 46px);
        margin: 0 10px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        @media #{$mobile} {
            min-height: 60px;
            margin: 0 0 20px;
            width: 100%;
            box-sizing: border-box;
        }
    }
}

.cb-rank-order-input-options {
    @media #{$tablet-up} {
        display: table;
        table-layout: auto;
    }
    @media #{$mobile} {
        width: 100%;
    }
    .cb-rank-order-input {
        @media #{$tablet-up} {
            display: table-row;
        }

        @media #{$mobile} {
            margin-bottom: 15px;
        }
    }
    .cb-rank-order-label,
    .cb-rank-order-control {
        @media #{$tablet-up} {
            display: table-cell;
        }
    }
    .cb-rank-order-label {
        padding-right: 10px;
        img {
            width: 120px;
        }
    }
    .cb-rank-order-control {
        input[type='number'] {
            -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
        }
        .mat-mdc-form-field {
            width: 140px;
            @media #{$mobile} {
                width: 100%;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
        .mat-input-container.mat-focused {
            /*TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
            .mat-input-placeholder-wrapper {
                display: none;
                background-color: yellow;
                color: #82000e;
            }
        }
    }
}

.cb-rank-order-options {
    padding: 12px;
    border-radius: 2px;
    background-color: #f1f4f7;
}
.cb-rank-order-item {
    position: relative;
    min-height: 16px;
    display: flex;
    align-items: center;
    margin: 0 0 4px;
    padding: 12px 40px 12px 12px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
    0 0 2px 0 rgba(0, 8, 20, 0.12);

    img.image-label {
        width: 50px;
    }

    &.rank-order-dnd {
        img.image-label {
            width: 120px;
        }
    }

    .img-preview {
        display: inline-block;
        width: 88px;
        height: 88px;
    }

    cb-svg-icon.icon-move {
        position: absolute;
        right: 12px;
        left: auto;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        color: #758ea1;
        transform: rotate(0deg);
    }
}
