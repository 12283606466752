.matrix-content {
    max-width: 100%;
    overflow: auto;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);
    width: 100%;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label,
    .mat-checkbox-layout {
        white-space: normal;
        align-items: flex-start;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label{
        justify-content: center;
    }
    .column {
        display: inline-block;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
    }

    .row {
        display: inline-block;
        min-width: 100px;
    }

    .otherRow {
        width: 100px;
    }

    .subheading {
        background-color: gray;
    }
}

table.cb-matrix-table {
    font-size: 14px;
    line-height: 1.14;

    thead {
        background-color: #cfd7de;
    }

    &,
    & table {
        width: 100%;
        //table-layout: fixed;
        border-spacing: 0;

        &:focus {
            outline: none!important;
        }

        th,
        td {
            text-align: center;

            &.text-center {
                max-width: 100%;
                text-align: center;
            }

            &.text-left {
                text-align: left;
                max-width: 100%;
            }

            &.text-right {
                max-width: 100%;
                text-align: right;
            }
        }

        /*   &.with-NA-option {
             .header {
                 &:last-of-type {
                   @media #{$desktop-up} {
                         width: 130px;
                         box-sizing: border-box;
                         min-width: 130px;
                         max-width: 130px;
                     }
                 }
             }
         }  commented for 1161977101042521 N/A option corrupts the aligment in Matrix */
    }

    th,
    td {
        padding: 10px 12px;
    }

    .question-cell {
        width: 272px;
        min-width: 272px;
        max-width: 272px;
        box-sizing: border-box;
        text-align: left;
        word-break: normal;

        &:not(:empty) {
            padding: 5px 12px;
        }

        @media #{$tablet-only} {
            width: 40%;
        }
    }

    & {
        .matrix-group-cell {
            &.header-with-options {
                .header-with-options-table {
                    display: table;
                    width: 100%;

                    .header-with-options-table-row {
                        //display: table-row;

                        .header-with-options-table-row-header {
                            display: table-cell;
                            height: 24px;

                            &.points-header {
                                padding: 0 12px 10px 12px;
                            }
                        }

                    }
                }
            }
        }
    }

    &.matrix-borders-vertical,
    &.matrix-borders-both {
        .matrix-group-cell {
            border-left: 2px solid rgba(117, 142, 161, 0.35);

            &:first-child {
                border-left: none;
            }
        }
    }

    &.matrix-borders-horizontal,
    &.matrix-borders-both {
        .header-column-group:first-child {
            .matrix-group-cell {
                border-top: none;
            }
        }

        .header-column-group {
            & > td {
                border-top: 2px solid rgb(198, 208, 217);
            }
            & > th {
                border-top: 2px solid rgb(198, 208, 217);

            }
            & > .table-header-horizontal {
                padding: 5px 12px;
            }
        }

        .matrix-group-cell {
            border-top: 2px solid rgba(117, 142, 161, 0.35);
        }
    }

    th {
        height: 24px;
        vertical-align: middle;
    }

    & > tbody {
        & > tr {
            &:nth-child(2n) {
                background: #f1f4f7;
            }

            &.header-column-group {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.25;
                color: #212121;

            }
            th.td-padding {
                padding: 5px 12px;
                @media #{$mobile} {
                    padding: 5px;
                }
                &.table-header-horizontal {
                    font-weight: normal;
                }
            }
        }
    }

    tbody {
        td {
            height: 20px;
            padding: 5px 12px;
            vertical-align: middle;

            & > div {
                max-width: 100%;
            }
        }

        @media #{$desktop-up} {
            .cb-question-item-content {
                margin: 0;
            }
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-inner-container {
                margin: 0 auto !important;
            }
        }

        cb-questions-header {
            display: none;
            @media #{$mobile} {
                display: block;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-checkbox-label,
        .mat-radio-label-content {
            display: none;

            .cb-survey-print & {
                display: inline-block;
            }

            @media #{$tablet-down} {
                display: inline-block;
            }
        }

        .slider-answer-container {
            width: 100%;
        }

        .matrix-slider-label {
            display: none;
            @media #{$tablet-down} {
                display: block;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
        .mat-slider-horizontal {
            width: 100%;
            min-width: 0;
            @media #{$mobile} {
                max-width: 100%;
            }
        }
    }

    table.table-matrix-group {
        & > thead {
            display: none;
        }

        .cell-field-name {
            display: none;
        }
    }

    .matrix-rating-scale-labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #758ea1;
        padding: 0;
        margin-bottom: -5px;
        @media #{$tablet-down} {
            display: none;
        }
    }

    .matrix-rating-scale-point-labels {
        td {
            padding-top: 0;
        }
        @media #{$tablet-down} {
            display: none;
        }
    }

    .cb-slider-controls {
        display: flex;
    }

    .cb-slider-preview-container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 5px;
        min-width: 200px;
    }

    .vertical-slider-container {
        min-width: auto;
    }

    .cb-custom-slider .slider-custom-ticks-list {
        margin: 0;
    }

    .form-single-line-text-preview {
        text-align: left;
        display: flex;
        flex-direction: column;
        //align-items: flex-start;
        .invalid {
            width: 180px;
            font-size: 12px;
        }

        .mat-mdc-form-field {
            max-width: 100%;
        }
    }

    .dropdown-list-control-preview {
        width: 100%;
        max-width: 100%;
        min-width: 0;
        @media #{$mobile, $tablet-down} {
            width: 180px;
        }
    }
}

@media #{$tablet-only} {
    table.cb-matrix-table {
        & > thead {
            & > tr {
                &:not(.header-column-group) {
                    display: none;
                }
            }
        }

        tbody {
            td, th {
                vertical-align: top;
            }
        }
    }
    table.table-matrix-group {
        &,
        & > tbody {
            &,
            & > tr {
                display: block;

                td {
                    display: block;
                    padding: 5px;
                    height: auto;
                    text-align: left;
                    vertical-align: top;
                }
            }
        }

        & > tbody {
            padding: 5px 0;
        }
    }
}

@media #{$tablet-down} {
    table .cb-matrix-table {
        .header-column-group {
            .group-cell-title {
                min-height: 40px;
                padding: 5px;
            }
        }

        &.table-multiple-columns {
        }
    }

    table.table-matrix-group {
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
            display: inline-block !important;
            padding: 2px 0 1px 10px;
            text-align: left;
        }

        form {
            max-width: 100%;
        }

        td {
            display: flex;
            align-items: flex-start;
        }

        .cell-field-name {
            display: inline-block !important;
            margin-right: 5px;
            font-weight: 700;
            width: 50%;
        }
    }
}

@media #{$mobile} {
    table.cb-matrix-table {
        &,
        & > tbody,
        & > thead {
            &,
            & > tr {
                display: block;

                td,
                th {
                    display: block;
                    padding: 5px;
                    height: auto;
                    text-align: left;
                    vertical-align: top;
                }

                &.header-column-group {
                    th.group-cell-title,
                    td.group-cell-title {
                        min-height: 40px;
                        padding: 5px;
                        p.matrix-rating-scale-name {
                            text-align: center;
                        }
                    }
                }
            }
        }

        & > thead {
            display: none;

            & > tr {
                .matrix-group-cell {
                    border: 0;
                }

                td,
                th {
                    display: none;

                    &.group-cell-title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        & > tbody {
            //border-top: 2px solid rgba(117, 142, 161, 0.35);
            & > tr {
                padding: 0 15px;
                border: 0;

                &:nth-child(2n) {
                    background: none;
                }

                &:first-child > td {
                    border: 0;
                }

                &.header-column-group {
                    background: #f1f4f7;
                }

                td {
                    display: flex;
                    width: 100%;
                    box-sizing: border-box;
                    align-items: center;
                    border: 0;

                    &:empty,
                    &.empty-cell {
                        display: none;
                    }

                    &.matrix-group-cell {
                        border: 0;

                        &.empty-cell {
                            display: none;
                        }

                        & > div {
                            width: 100%;
                            max-width: 100%;
                        }
                    }

                    &.question-cell {
                        width: auto;
                        margin: 0 -15px;
                        padding: 10px 15px;
                    }

                    .matrix-slider-label {
                        display: block;
                    }
                }
            }
        }
    }
}

table.table-matrix-columns {
    margin-left: -1px;

    td.matrix-group-cell {
        &:first-child {
            border-left: 0;
        }
    }

    @media #{$desktop-down} {
        .matrix-group-cell {
            border: 0;
        }
    }
}

table {
    td.p-0 {
        padding: 0 !important;
    }
}

table.cb-matrix-table.table-multiple-columns {
    @media #{$desktop-down} {
        //    .header-column-group {
        //        .matrix-group-cell + .matrix-group-cell {
        //            border-left: 0;
        //        }
        //    }
    }
    @media #{$desktop-only} {
        .table-matrix-columns {
            th {
                height: auto;
            }

            &,
            & > tbody,
            & > thead {
                &,
                & > tr {
                    display: block;

                    & > td,
                    & > th {
                        display: block;
                        height: auto;
                        min-height: 14px;
                        text-align: left;
                    }
                }
            }

            table.table-matrix-group {
                & > thead {
                    display: table-header-group;
                }
            }
        }
    }

    @media #{$desktop-down} {
        .table-matrix-columns {
            th {
                height: auto;
            }

            &,
            & > tbody,
            & > thead {
                &,
                & > tr {
                    display: block;

                    & > td,
                    & > th {
                        display: block;
                        height: auto;
                        min-height: 14px;
                        text-align: left;
                    }
                }
            }
        }
    }

    @media #{$tablet-down} {
        table.table-matrix-group {
            & > thead {
                display: block !important;

                tr {
                    display: none !important;

                    &.header-column-group {
                        display: block !important;
                    }
                }
            }

            th,
            td {
                display: flex;
                align-items: flex-start;
                text-align: left;
                padding: 5px 10px;

                .mat-mdc-checkbox {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.table-matrix-group {
    &--rating-scale {
        .table-align-header-col {
            @media #{$desktop-up} {
                display: inline-block;
            }

            .header {
                width: auto;
                padding: 0;
            }
        }
    }

    &--slider {
        padding: 0 5px;
        .table-align-header-col {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            span {
                padding: 0;
                flex-grow: 2; // don't use short syntax because of IE11
                flex-shrink: 1; //
                flex-basis: 0; //
                display: block;

                &:last-child {
                    text-align: right;
                    flex-grow: 1;
                }

                &:first-child {
                    text-align: left;
                    flex-grow: 1;
                }
            }
        }
    }
    .table-data-cell {
        padding: 5px 10px;
    }
}

.table-matrix-group.table-header {

    .table-align-header-col {
        display: table-row;
        width: 100%;

        .header {
            display: table-cell;
            vertical-align: middle;
            padding: 10px 12px;

            &.header-slider {
                padding: 0;
            }
        }
        &--slider {
            display: flex;
            width: auto;
        }

    }
}

/* .cb-checkboxes-container {
    .table-matrix-group {
        &.with-NA-option {
            td {
                &:last-of-type {
                    @media #{$desktop-up} {
                        width: 130px;
                        box-sizing: border-box;
                        min-width: 130px;
                        max-width: 130px;
                    }
                }
            }
        }
    }
}  commented for 1161977101042521 N/A option corrupts the aligment in Matrix */

.matrix-row-text- {
    &left {
        text-align: left;
    }

    &right {
        text-align: right;
    }

    &center {
        text-align: center;
    }
}
