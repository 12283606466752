@import '../variables';
@import '../mixins';
.cb-dashboard-content {
    padding: 0;

    .cb-dashboard-main-controls {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .cb-dashboard-button {
            padding-left: 36px;
            padding-right: 36px;
            margin-right: 20px;
        }
    }

    .cb-dashboard-page-controls {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .cb-dashboard-button {
            padding-left: 36px;
            padding-right: 36px;
            margin-right: 20px;
        }
    }

    .cb-dashboard-items-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .cb-dashboard-main-title {
            flex: 1;
            font-size: 24px;
            line-height: 1.33;
            color: rgba(0, 0, 0, 0.87);
        }

        .cb-dashboard-pagination {
            padding-top: 8px;
        }
    }

    .cb-dashboard-items-footer {
        display: flex;
        justify-content: flex-end;
    }
}

.cb-dashboard-filters {
    padding: 14px 16px;
    margin-bottom: 10px;
    background-color: #f1f4f7;

    .cb-dashboard-filters-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .cb-dashboard-filters-title {
            flex: 1;
            margin: 0;
            font-weight: normal;
            font-size: 16px;
        }
    }

    .form-fields-group {
        margin: -8px -10px;
        display: block;

        .mat-mdc-form-field {
            width: calc(25% - 16px);
            display: inline-block;

            &.widget-name-input,
            &.widget-date-range {
                width: 264px;
            }

            &.filter-input {
                width: calc(17% - 16px);
            }
        }
    }
}

.cb-dashboard-items-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 1098px;
    margin-left: auto;
    margin-right: auto;
} 


.cb-dashboard-item-dropzone {
    min-height: 200px;
    border: #758ea1 dashed 3px;
}

.expand-dashboard-item-dropzones {
    .cb-dashboard-items-container  {
        min-height: 20px;
    }

}

.dashboard-overview.restrict-page-size {
    width: 210mm;
}

@media print {
    .cb-dashboard-items-container {
        display: block;
        position: relative;
    }
    cb-dashboard-item {
        max-width: 100vw;
        border: none;
        display: block;
        position: relative;

        &.dashboard-item-page-break {
            page-break-inside: avoid;
            page-break-before: auto;
        }

        .cb-dashboard-item-content {
            svg {
                max-width: 100%;
            }
        }

        .table-single-column {
            page-break-before: auto;
            page-break-inside: avoid;
            position: relative;
        }
    }
}

cb-dashboard-item.cb-dashboard-item {
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 12px;
}

.cb-dashboard-item {
    box-sizing: border-box;

    &.small {
        @media screen {
            width: 100%;
        }
        @media #{$tablet-up} {
            flex-basis: calc(33.3333% - 14px);
            max-width: calc(33.3333% - 14px);          
        }

        .donut-chart-container,
        .chart-container {
            flex-direction: column;

            .custom-legend {
                width: 100%;
                margin: 0;
            }

            .donut-chart,
            .chart {
                width: 224px;
                max-height: 224px;
                margin: 0 auto;
            }
        }
    }

    &.medium {
        @media screen {
            width: 100%;
        }
        @media #{$tablet-up} {
            flex-basis: calc(66.6666% - 14px);
            max-width: calc(66.6666% - 14px);
        }

        .chart-container {
            .chart {
                width: 464px;
                margin: 0 auto;
            }
        }
    }

    &.large {
        @media screen {
            width: calc(100% - 14px);
            max-width: 100%;
        }

        .chart-container {
            .chart {
                width: 764px;
                margin: 0 auto;
            }
        }
    }

    .custom-legend {
        .legend-item {
            margin-bottom: 8px;
        }
    }
}


$dashboardWidgetPaddingX: 16px;
$dashboardWidgetPaddingY: 12px;

.cb-dashboard-item {
    padding: $dashboardWidgetPaddingY $dashboardWidgetPaddingX;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24), 0 0 2px 0 rgba(0, 8, 20, 0.12);

    .cb-dashboard-item-preview-toolbar {
        border-radius: 2px;
        background-color: #f1f4f7;
        height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        margin: (-$dashboardWidgetPaddingY) (-$dashboardWidgetPaddingX) 15px;
        padding: 0 $dashboardWidgetPaddingX;

        .dashboard-item-date-info {
            flex: 1;
            font-size: 12px;
            line-height: 1.33;
            color: #576a78;
        }

        .cb-button-preview-filter.cb-button-default {
            position: absolute;
            top: 100%;
            right: 10px;
        }
    }

    .cb-dashboard-item-edit-toolbar {
        position: relative;
        display: flex;
        align-items: center;
        margin: (-$dashboardWidgetPaddingY) (-$dashboardWidgetPaddingX) 8px;
        padding: 0 12px;
        height: 56px;
        border-radius: 2px;
        background-color: #f8fafc;

        .icon-move {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0) rotate(90deg);
        }

        .widget-date-info {
            flex: 1;
            font-size: 12px;
            line-height: 1.33;
            color: #576a78;
        }

        .dashboard-item-edit-controls {
            margin-left: auto;
        }

        .cb-icon-button {
            margin: 0 5px;
        }
    }

    .matrix-content {
        table.cb-matrix-table {
            .matrix-group-cell {
                border-left: 2px solid rgba(117, 142, 161, 0.35);

                &:first-child {
                    border-left: none;
                }
            }

            & > tbody {
                & > tr {
                    &:first-child {
                        & > td {
                            border-top: 2px solid rgba(117, 142, 161, 0.35);
                        }
                    }
                }
            }
        }
    }

    .cb-dashboard-item-header {
        .cb-dashboard-item-title {
            margin-bottom: 4px;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.33;
            color: rgba(0, 0, 0, 0.87);
        }

        .cb-dashboard-item-survey-title {
            margin-bottom: 6px;
            line-height: 1;
            font-size: 16px;
            color: #576a78;
        }

        .cb-widget-filter {
            margin: 0 (-$dashboardWidgetPaddingX);
            min-height: 40px;
            background-color: #f1f4f7;
            color: rgba(0, 0, 0, 0.87);
            line-height: 1.14;
            font-size: 14px;

            .icon-filter {
                color: rgba(77, 77, 77, 0.4);
                margin: -2px -3px 0 0;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .filter-edit-controls {
                display: none;
            }

            .add-filter-button-container {
                height: 40px;
                display: flex;
                align-items: center;
                color: #758ea1;

                .cb-button-add-filter {
                    width: 100%;
                    justify-content: flex-start;
                }
            }
        }
    }

    .cb-dashboard-item-content {
        padding: 10px 0;
    }
}

.mat-mdc-menu-panel {
    &.cb-dashboard-item-applied-filters-menu {
        .mat-mdc-menu-content {
            padding: 10px;
        }

        .cb-dashboard-item-filters-menu-container {
            padding: 0;
        }

        .dashboard-item-menu-title {
            color: #576a78;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 10px;
            font-weight: bold;
        }

        .applied-filters-list {
            font-size: 14px;

            .applied-filter-item {
                margin-bottom: 5px;
            }
        }

        .filters-buttons-container {
            text-align: right;
            margin-top: 10px;

            .btn-edit-filter {
                min-width: 0;
                width: auto;
                margin: 0;
            }
        }
    }
}
cb-summary-table-chart,cb-net-promoter-score-statistics-table-chart{
    th {
        text-align: left;
    }
    td {
        text-align: left;
    }
}
cb-rating-scale-statistics-table-chart{
    div{
        overflow-x: auto;
    }
}
div.cb-dashboard-item{
    >div:has(table):not(.survey-reports-diagram-container){
        overflow-x: auto;
        padding: 10px;
    }
}
.matrix-content{
    border-radius: 10px;
}
.report-table-chart, .contact-form-summary-table, .rank-order-table, .cb-matrix-table, .max-diff-table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: $shadow-light-4dp !important;
    thead {
        background-color: $backgrounds-light-400;
        border-bottom: 2px solid $basic-alpha-8;
    }
    th{
        height: 36px;
        padding: 6px 24px 6px 12px; 
        @include typo-data-table-header;
        color: $text-color-grey;
        text-align: left;
    }
    td {
        padding: 8px 24px 8px 12px;
        height: 36px;
        text-align: left;
        @include typo-body-2-regular;     
    }
    tbody {
        tr{
            border-bottom: 1px solid $basic-alpha-8;
            &:last-child{
                border: none ;
            }
        }
        
    }
    @media #{$tablet-up} {
        th {
            padding: 6px 12px;
        }
        td {
            padding: 8px 12px;
        }
    }

}
.cb-matrix-table{
    th{
        text-transform: unset!important;
    }
}

.nps-table {
    .highlighted-cell {
        color: #4aaf50;
    }
}

.max-diff-table {
    .cb-bar-item {
        text-align: center;
        color: #fff;
        position: relative;
        height: 100%;
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            >span {
                @include typo-caption-2-medium;
            }
        }
    }

    .td-rank-score {
        text-align: center;
    }

    th,
    td {
        padding-top: 8px !important;
        padding-bottom: 8px !important;

        &.td-progress {
            width: 60%;
            padding: 5px 5px;
        }

        .progress-container {
            display: flex;
            width: 100%;
        }
    }
}

.cross-tab-table {
    .subheader-data-row td {
        text-align: left;
    }

    .cross-tab-item {
        .count {
            display: block;
        }

        .percent {
            display: block;
        }
    }
}


.dashboard-settings-appearance {
    margin-top: 5px;
}

.dashboard-settings-response-title {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 1.33;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
}

.matrix-expanded {
    margin-left: 20px;
}

.matrix-expanded--column-text {
    background: rgb(225, 225, 225);
    padding-left: 10px;
    margin-left: -10px;

    p {
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.matrix-expanded-row-caption {
    background: rgb(200, 200, 200);
    margin-left: -20px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
}

.matrix-expanded--column {
    margin-left: 20px;
}

.matrix-expanded--value {
    margin: 10px 20px;
}

.matrix-expanded--column {
    page-break-inside: avoid;
    page-break-before: auto;
}

.cb-button-preview-filter.cb-button-default {
    overflow: visible;
    min-width: 0;
    width: 32px;
    height: 24px;
    padding: 0;

    &.toolbar-filters,
    &.toolbar-filters:hover {
        background: transparent !important;
        margin-top: 7px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .icon {
        color: #758ea1;
        margin: 0;
    }

    .applied-filters-count {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 16px;
        line-height: 16px;
        background: #751ca4;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        z-index: 2;
    }
}
.dialog-content>cb-dashboard-filters-editor {
    min-height: 234px;
    display: block;
    .loader-overlay-container {
        z-index: 4444;
    }
}
cb-dashboard-settings {
    .other-option-setting {
        display: block;
        width: 180px;
    }
    .mat-mdc-form-field.margin-none {
        margin: 0;
    }
}

cb-dashboard-filters-editor {
    form {
        max-width: 1084px;
        margin-left: auto;
        margin-right: auto;
    }
}

.cb-app-content.dashboard-overview .cb-take-survey-section,
.cb-survey-reports-overview-header {
    max-width: 1098px;
    margin-left: auto;
    margin-right: auto;    
}

.echarts-reports{
    width: 100%;
    &.small {
        height: 456px;
    }
    &.medium {
        height: 260px;
    }
}
