
/* merriweather-sans-300 - latin */
@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }
  /* merriweather-sans-regular - latin */
  @font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }
  /* merriweather-sans-700 - latin */
  @font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }
  /* merriweather-sans-300italic - latin */
  @font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-300italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }
  /* merriweather-sans-italic - latin */
  @font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }
  /* merriweather-sans-700italic - latin */
  @font-face {
    font-family: 'Merriweather Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/merriweather/merriweather-sans-v14-latin-700italic.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
  }

/* dosis-300 - latin */
@font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 300;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-300.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-regular - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-regular.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-500 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 500;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-500.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-600 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-600.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-700 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-700.svg#Dosis') format('svg'); /* Legacy iOS */
  }
  /* dosis-800 - latin */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 800;
    src: url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/dosis/dosis-v19-latin-800.svg#Dosis') format('svg'); /* Legacy iOS */
  }


  /* rubik-300 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-regular - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-500 - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-700 - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-900 - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-300italic - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-italic - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-500italic - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-700italic - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }
  /* rubik-900italic - latin */
  @font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    src: url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/rubik/rubik-v12-latin-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
  }

/* raleway-300 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-regular - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-500 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-600 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-700 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-300italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 300;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-500italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 500;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-600italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 600;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-700italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 700;
    src: url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/raleway/raleway-v19-latin-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
  }

  /* lato-100 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-100italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-300 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-300italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-900italic - latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../shared/assets/fonts/lato/lato-v17-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
  }


  @font-face {
     font-family: 'Material Icons';
     font-style: normal;
     font-weight: 400;
     src: local('Material Icons'),
          local('MaterialIcons-Regular'),
          url('../../shared/assets/fonts/material-icons/MaterialIcons-Regular.woff2') format('woff2'),
          url('../../shared/assets/fonts/material-icons/MaterialIcons-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Galano Grotesque';
    src: url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-regular-webfont.woff2') format('woff2'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-regular-webfont.woff') format('woff'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Galano Grotesque';
    src: url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-medium-webfont.woff2') format('woff2'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-medium-webfont.woff') format('woff'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-medium-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Galano Grotesque';
    src: url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.woff2') format('woff2'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.woff') format('woff'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'GalanoGrotesque-Bold';
    src: url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-bold-webfont.woff2') format('woff2'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-bold-webfont.woff') format('woff'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'GalanoGrotesque-SemiBold';
    src: url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.woff2') format('woff2'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.woff') format('woff'),
         url('../../shared/assets/fonts/galano-grotesque/galanogrotesque-semibold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
