.cb-user-control-toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .cb-add-user-btns {
        flex: 1;
        .cb-button-menu-white {
            margin: 0 24px 0 0;
        }
    }
    .cb-user-controls {
        margin: 0;
        display: flex;
        align-items: center;
        .cb-icon-button {
            margin: 0 0 0 8px;
        }
    }
}

.dialog-content {
    .cb-contact-content,
    &.cb-group-content {
        .search-section {
            .search-form-field {
                margin-bottom: 15px;
                margin-top: -10px;
                .search-input {
                    margin-top: 0;
                }
            }
        }
        .cb-section-divider {
            margin: 30px -10px 25px;
        }
        .mat-expansion-panel-body {
            p {
                margin: 0 0 10px;
            }
            .sub-section-main-text {
                margin-bottom: 18px;
            }
        }
    }
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card {
    max-width: 600px;
    max-height: 600px;
    margin: 2em auto;
    text-align: center;
}

.cb-table-container {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-id {
                flex: 3;
            }

            &.cdk-column-email {
                flex: 3;
            }

            &.cdk-column-groups {
                flex: 3;
            }

            &.cdk-column-actions {
                flex: 1;
            }
        }
    }

    .cb-add-user-btns {
        padding: 20px 10px;
        button {
            margin: 0 10px;
        }
    }
}

.strong-role {
    color: #014c8c;
    font-weight: bolder;
}

.mat-mdc-table {
    overflow: auto;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-ripple {
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        width: 20px;
        z-index: 1;
        pointer-events: none;
    }
}

.cb-user-checkbox-columns {
    display: flex;
    align-items: flex-start;
    .cb-user-checkbox-column {
        width: 288px;
        .control {
            display: block;
            margin-bottom: 8px;
        }
    }
}

.cb-add-groups-section-container {
    display: flex;
    margin: 0 -10px 20px;

    .cb-add-groups-section {
        display: flex;
        width: 50%;
        padding: 10px;
        margin: 0 10px;
        height: 344px;
        border-radius: 2px;
        background: #f1f4f7;
    }

    .cb-add-groups-section-inner {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .cb-add-groups-section-search {
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        form {
            display: block;
            width: 100%;
        }
        .mat-mdc-form-field {
            display: block;
            width: 100%;
        }
    }

    .cb-add-groups-section-header {
        display: flex;
        margin-bottom: 12px;
        .cb-add-groups-section-title {
            flex: 1;
            margin: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            color: #212121;
        }
    }
    .cb-groups-container {
        flex: 1;
        overflow-y: auto;
        margin-top: 15px;
    }
    .cb-groups-list {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        line-height: 24px;

        .cb-groups-list-item {
            display: flex;
            align-items: flex-start;
            min-height: 24px;
            padding: 8px 12px;
            border-radius: 2px;
            background-color: #ffffff;
            box-shadow: 0 1px 3px 0 rgba(0, 8, 20, 0.24),
                0 0 2px 0 rgba(0, 8, 20, 0.12);
            margin-bottom: 4px;
        }

        .user-item-title {
            display: flex;
            flex: 1;
            align-items: flex-start;
            margin: 0;
            font-weight: normal;
            font-size: inherit;
            .icon {
                margin-right: 4px;
                color: inherit;
            }
        }
    }

    .cb-groups-actions {
        line-height: 0;
        opacity: 0.3;
        pointer-events: none;
        &.visible {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.cb-users-table.cb-table-container {
    .cb-users-table__cell-sub {
        display: block;
        font-weight: 400;
        font-size: 12px;
    }
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-select {
                width: 30px;
            }
            &.cdk-column-userName {
                flex: 3;
            }
            &.cdk-column-id {
                flex: 3;
            }
            &.cdk-column-email {
                flex: 3;
            }
            &.cdk-column-status {
                flex: 1;
            }
            &.cdk-column-groups {
                flex: 3;
            }

            &.cdk-column-roles {
                flex: 3;
            }

            &.cdk-column-actions {
                flex: none;
                width: 140px;
            }
        }
    }
}

/*Contacts -> Groups*/

.cb-groups-table.cb-table-container {
    .mat-mdc-table {
        .mat-mdc-header-cell,
        .mat-mdc-cell {
            &.cdk-column-name {
                flex: 4;
            }

            &.cdk-column-member_count {
                flex: 2;
            }

            &.cdk-column-description {
                flex: 6;
            }

            &.cdk-column-controlElement {
                flex: none;
                width: 140px;
            }
        }
    }
    .cb-groups-table-description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: block;
    }
}

.cb-user-checkboxes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .cb-user-checkboxes__control {
        width: 33%;
        display: block;
        margin: 4px 0;
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        mat-checkbox{
            max-width: 100%;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-inner-container {
            margin-left: 0;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-layout {
            width: 100%;
            /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-label {
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 10px;
            }
        }
    }
}

.cb-permission-policy-section-container {
    .cb-permissions-policy-list {
        .cb-permissions-policy-item {
            margin-bottom: 8px;
        }
    }

    .buttons-container {
        background: transparent;
    }
}

.cb-contact-list {
    &-table {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-ripple {
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            z-index: 1;
            pointer-events: none;
        }
    }
}

.cb-group-list {
    &-table {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-ripple {
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            z-index: 1;
            pointer-events: none;
        }
    }
}

.contact-section-container {
    margin-bottom: 20px;
    .group-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .group-list {
        width: 33%;
        display: inline-block;
    }
    .full-width-input {
        width: 100%;
        margin-top: 10px;
    }
    //.contact-section-container {
    //  min-height: 200px;
    //}

    .toggle-label {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .toggle-item {
        margin-bottom: 10px;
    }
    input[disabled][matInput] {
        cursor: not-allowed;
    }
}

.cb-contact-password-form .full-width-password {
    display: block;
    width: 100%;
}

.dialog-content.cb-contact-import-content {
    height: 100%;
    font-size: 14px;
    .progress-steps-container {
        height: calc(100% - 200px);
        overflow: auto;
        margin: 0 -36px;
    }
}

.progress-steps-container {
    position: relative;

    .progress-step-content {
        padding: 0 36px;
        &,
        &.hidden {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;
            opacity: 0;
            z-index: 0;
            pointer-events: none;
        }

        &.active {
            position: relative;
            z-index: 3;
            opacity: 1;
            pointer-events: auto;
        }
    }

    .import-dialog-update-users-group {
        display: flex;
        justify-content: space-between;
        .form-control {
            min-width: 200px;
            margin-right: 20px;
        }
    }
}

.import-valid-data-list {
    .import-valid-data-item {
        height: 40px;
        padding: 10px 20px;
        box-sizing: border-box;
        &:nth-child(2n) {
            background: #f1f4f7;
        }
    }
}
