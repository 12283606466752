@use 'sass:map';
@import '../../../shared/sass/variables';
$white-part: 50;
$medium-part: 500;
$info-color: map.get($system-info-palette, $medium-part);
$warning-color: map.get($system-warning-palette, $medium-part);
$success-color: map.get($system-success-palette, $medium-part);
$error-color: map.get($system-danger-palette, $medium-part);
$info-color-light: map.get($system-info-palette, $white-part);
$warning-color-light: map.get($system-warning-palette, $white-part);
$success-color-light: map.get($system-success-palette, $white-part);
$error-color-light: map.get($system-danger-palette, $white-part);
body .mat-mdc-snack-bar-container {
    &.outline,&.fill{
        width: 327px;
        padding: 16px;
        gap: 15px;
        border-radius: 8px;
        margin: 24px;
    }
}
mat-snack-bar-container.error {
    &.outline {
        .mdc-snackbar__surface {
            border: 1.4px solid $error-color;
            background: $error-color-light;
        }
    }
    &.fill {
        background: $error-color;
    }
    box-shadow: 0px 6px 12px 0px rgba($error-color, 0.10);
}

mat-snack-bar-container.info {
    &.outline {
        .mdc-snackbar__surface {
            border: 1.4px solid $info-color;
            background: $info-color-light;
        }
    }
    &.fill {
        background: $info-color;
    }
    box-shadow: 0px 6px 12px 0px rgba($info-color, 0.10);
}

mat-snack-bar-container.warning {
    &.outline {
        .mdc-snackbar__surface {
            border: 1.4px solid $warning-color;
            background: $warning-color-light;
        }
    }
    &.fill {
        background: $warning-color;
    }
    box-shadow: 0px 6px 12px 0px rgba($warning-color, 0.10);
}
mat-snack-bar-container.success {
    &.outline {
        .mdc-snackbar__surface {
            border: 1.4px solid $success-color;
            background: $success-color-light;
        }
    }
    &.fill {
        background: $success-color;
    }
    box-shadow: 0px 6px 12px 0px rgba($success-color, 0.10);
}
